import React, { useEffect, useRef, useState } from 'react'
import AddProjects from './AddProjects/AddProjects'
import CreateUserForm from './CreateUserForm'
import UserAdministration from './UserAdministration/UserAdministration'
import ProviderAdministration from './ProviderAdministration/ProviderAdministration'
import UserReport from './UserReport/UserReport'
import AuditLogs from './AuditLogs/AuditLogs'
import EmailForm from './Email/EmailForm'
import EmailsTable from './Email/EmailsTable'
import { Assets } from '../../Assets'
import { useAuth } from '../../Hoc/AuthContext'
import { userRoles } from '../../app.constants'
import DraftsTable from './Email/DraftsTable'
import { useAppContext } from '../../Hoc/AppContext'
import UserProfile from './UserProfile'


let tabs = [
    { id: 'createUser', name: "Creación de Usuarios", img: require("../../Assets/tab1.png") },
    { id: 'users', name: "Administración de usuarios", img: require("../../Assets/tab2.png") },
    { id: 'providers', name: "Administración de Proveedores", img: require("../../Assets/tab3.png") },
    { id: 'projects', name: "Añadir  Proyectos", img: require("../../Assets/tab1.png") },
    { id: 'email', name: "Envío de Correos", img: require("../../Assets/tab4.png") },
    { id: 'audit', name: "Auditoría de Uso", img: require("../../Assets/tab5.png") },
    { id: 'reports', name: "Reportes", img: require("../../Assets/tab6.png") },
];

const subMenuOptions = [
    // { id: 'received', name: 'Recibidos', img: Assets.EmailInboxIcon },
    { id: 'send', name: 'Envío de correos', img: Assets.EmailSendIcon },
    { id: 'templates', name: 'Plantillas', img: Assets.EmailDraftsIcon },
    { id: 'sent', name: 'Enviados', img: Assets.EmailSentIcon }
];

const Dashboard = () => {
    const { user, isAuthenticated } = useAuth();

    if (isAuthenticated && user && user.role === userRoles.projectCreator) {
        tabs = tabs.filter(tab => !['createUser', 'users', 'providers', 'audit'].includes(tab.id))
    }
    // const [activeTab, setActiveTab] = useState(tabs[0]);
    const { activeTab, setActiveTab } = useAppContext();

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [subMenuPosition, setSubMenuPosition] = useState(0);
    const emailButtonRef = useRef(null);

    useEffect(() => {
        if (!Object.keys(activeTab).length) {
            setActiveTab(tabs[0]);
        }
    }, []);

    useEffect(() => {
        if (emailButtonRef.current) {
            const rect = emailButtonRef.current.getBoundingClientRect();
            setSubMenuPosition(rect.top - rect.height);
        }
    }, [isSubMenuOpen]);

    const handleMenuClick = (tab) => {

        if (tab.id === 'email') {
            setIsSubMenuOpen(!isSubMenuOpen);
            // setActiveTab(tab);
        } else {
            setIsSubMenuOpen(false);
            setActiveTab(tab);
        }
    };

    const handleSubMenuClick = (tab) => {
        setActiveTab(tab);
        setIsSubMenuOpen(false);
    };

    const renderComponent = () => {
        switch (activeTab.id) {
            case 'createUser':
                return <CreateUserForm />;
            case 'users':
                return <UserAdministration />;
            case 'providers':
                return <ProviderAdministration />;
            case 'projects':
                return <AddProjects />;
            case 'audit':
                return <AuditLogs />;
            case 'reports':
                return <UserReport />;
            case 'received':
                return <EmailsTable />;
            case 'send':
                return <EmailForm />;
            case 'templates':
                return <DraftsTable />;
            case 'sent':
                return <EmailsTable />;
            case 'profile':
                return <UserProfile />;
            default:
                return null;
        }
    };

    return (
        <div className="flex h-full min-h-screen bg-gray-100">

            <nav className="relative w-80 bg-[#71A850] border-t border-solid border-green-600  text-white">
                {tabs.map((tab, i) => (
                    <button key={tab.id}
                        ref={tab.id === 'email' ? emailButtonRef : null}
                        className={`${activeTab.id === tab.id ? "bg-[#025624]" : "bg-[#71A850]"} w-full flex justify-start flex-shrink-0 items-center gap-4 cursor-pointer px-4 py-4 border-b border-solid border-green-600`}
                        onClick={() => handleMenuClick(tab)}
                    >
                        <img src={tab.img} alt="" className="w-[43.4px]" />
                        <p className="text-white text-left font-semibold  text-lg">{tab.name}</p>
                    </button>)
                )}

                {isSubMenuOpen && (
                    <div className="absolute left-80 w-80 shadow-lg z-10 border-t border-solid border-green-600 bg-[#71A850]"
                        style={{ top: subMenuPosition - 5 }}>
                        {subMenuOptions.map((tab, i) => (
                            <button key={tab.id}
                                className={`${(tab.id === 'email' && isSubMenuOpen) || activeTab.id === tab.id
                                    ? "bg-[#025624]" : "bg-[#71A850]"} flex w-full justify-start flex-shrink-0 items-center gap-4 cursor-pointer px-4 py-4 border-b border-solid border-green-600`}
                                onClick={() => handleSubMenuClick(tab)}
                            >
                                <img src={tab.img} alt="" className="w-[43.4px]" />
                                <p className="text-white font-semibold  text-lg">{tab.name}</p>
                            </button>
                        ))}
                    </div>
                )}
            </nav>

            <div className="flex-1 bg-[url('./Assets/proyectos-fondo.jpg')] bg-cover bg-no-repeat flex flex-col gap-4 items-center">
                <div className="flex items-center mt-5 sm:mt-36 gap-4 lg:gap-10">
                    <img src={require("../../Assets/triangleDark.png")} className="h-10 w-10 lg:h-16 lg:w-16" alt="" />
                    <div className="text-2xl font-bold font-roboto leading-[30px] text-[#025624] lg:text-[36px] lg:leading-[47px]">
                        {activeTab.name}
                    </div>
                </div>

                {renderComponent()}
            </div>
        </div>
    )
}

export default Dashboard