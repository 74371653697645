import React, { useState } from "react";
import styles from "./Register.module.css";
import RegIcn from "../../Images/RegIcn.png";
import HideIcn from "../../Images/hideIcn.png";
import BtnIcn from "../../Images/headBtnIcn1.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiRoutes, generalError, toastTypes, userRoles } from "../../app.constants";
import axiosInstance from "../../services/axios";
import { useAuth } from "../../Hoc/AuthContext";
import { showToast } from "../Shared/CustomToast";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    loginEmail: "",
    loginPassword: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(true); // State to track email validation
  const [emailNotRegistered, setEmailNotRegistered] = useState(false); // State for unregistered email
  const [isLoginChecked, setIsLoginChecked] = useState(false); // State for login checkbox
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/system';
  const { login } = useAuth();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate email format
    if (name === "loginEmail") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsEmailValid(emailPattern.test(value));
      setEmailNotRegistered(false); // Reset error if user starts typing again
    }
  };

  const handleLoginCheckboxChange = () => {
    setIsLoginChecked((prev) => !prev);
  };

  // Handle login
  const handleLogin = async () => {
    const { loginEmail, loginPassword } = formData;

    // if (!isLoginChecked) {
    //   alert("Please agree to the terms and conditions for login.");
    //   return;
    // }
    if (!isEmailValid) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const { data } = await axiosInstance.post(
        apiRoutes.auth.login,
        {
          email: loginEmail,
          password: loginPassword,
        }
      );
      login(data);
      handleNavigation(data.user.role, data.user.completedStep);

    } catch (error) {
      const { status, data } = error.response;
      setEmailNotRegistered(false);
      setLoginError('');
      if (status === 404) {
        setEmailNotRegistered(true); // Display error message
      } else if (status === 403 || status === 401) { // Status check
        setLoginError(data ? data : 'No se puede iniciar sesión. Por favor contacte a nuestro equipo de soporte.');
      } else {
        showToast(toastTypes.error, generalError);
      }
    }
  };

  const handleNavigation = (role, completedStep) => {
    if ([userRoles.admin, userRoles.projectCreator, userRoles.auditor].includes(role)) {
      navigate('/admin');
    } else if (role === userRoles.provider) {
      // Get last completed step
      switch (completedStep) {
        case 1:
          navigate('/system/register-doc');
          break;
        case 2:
          navigate('/system/sup-register');
          break;
        case 3:
          navigate('/system/contactinfo');
          break;
        default:
          navigate(from);
          break;
      }
    } else {
      navigate(from);
    }
  }

  return (
    <div>
      <div className={styles.registerMain}>
        <div className={styles.registerContainer}>
          <div className={styles.registerContainer2}>
            {/* Login Section */}
            <div className={styles.registerLeftMain}>
              <div className={styles.registerMainHeading}>
                <img src={RegIcn} alt="" className={styles.regIcn} />
                Ingreso de Sesión
              </div>
              <div className={styles.registerInputMain}>
                Correo electrónico:*
                <input
                  type="text"
                  name="loginEmail"
                  className={`${styles.inputRegister} ${!isEmailValid || emailNotRegistered ? styles.invalidInput : ""
                    }`}
                  value={formData.loginEmail}
                  onChange={handleChange}
                />
                {!isEmailValid && (
                  <span className={styles.errorMessage}>
                    Favor de ingresar un correo electrónico válido.
                  </span>
                )}
                {loginError && (
                  <span className={styles.errorMessage}>
                    {loginError}
                  </span>
                )}
                {emailNotRegistered && (
                  <span className={styles.errorMessage}>
                    El usuario no ha sido registrado. Favor de registrarlo primero aquí:{" "}
                    <Link
                      to="/system/register-main"
                      className={styles.registerLink}
                    >
                      REGISTRO
                    </Link>
                  </span>
                )}
              </div>
              <div className={styles.registerInputMain}>
                Contraseña:*
                <div className={styles.showPasswordInput}>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="loginPassword"
                    className={styles.passwordinput}
                    value={formData.loginPassword}
                    onChange={handleChange}
                  />
                  <img
                    src={HideIcn}
                    alt={showPassword ? "Hide password" : "Show password"}
                    className={styles.hideIcn}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <Link to="/forgotpassword">
                <div className={styles.forgoText}>¿Olvidé Contraseña?</div>
              </Link>
              <div className={styles.registerCheckbox}>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={isLoginChecked}
                    onChange={handleLoginCheckboxChange}
                  />
                  <span className="checkmark"></span>
                </label>
                Recuérdame
              </div>
              <div className={styles.RegBtnIcnMain}>
                <button
                  onClick={handleLogin}
                  className={styles.RegBtnIcn}
                // disabled={!isLoginChecked}
                >
                  <img src={BtnIcn} alt="" className={styles.navBtnIcn} />
                  ENTRAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
