import React, { useEffect } from 'react'
import { useState } from "react"
import { userRoles, apiRoutes, toastTypes, userRolesMap } from '../../app.constants';
import axiosInstance from '../../services/axios';
import { showToast } from '../Shared/CustomToast';
import { useAuth } from '../../Hoc/AuthContext';
import { Assets } from '../../Assets';
import FileUpIcn from "../../Images/fileupIcn.png";
import styles from "../RegisterDoc/RegisterDoc.module.css";


const UserProfile = () => {
    const { user, setUser } = useAuth();

    const [formData, setFormData] = useState({
        role: '',
        fullname: '',
        surname: '',
        email: '',
    });

    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            role: user.role,
            fullname: user.fullname,
            surname: user.surname,
            email: user.email
        }));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataNew = new FormData();

        Object.entries(formData).forEach(([key, val]) => {
            formDataNew.append(key, val);
        })
        if (fileData) {
            formDataNew.append("profileImage", fileData);
        }

        try {
            const { data } = await axiosInstance.patch(`${apiRoutes.updateUser}`, formDataNew);
            setUser(data?.user);
            showToast(toastTypes.success, `Usuario actualizado exitosamente.`)
        } catch (error) {
            console.log(error);
            showToast(toastTypes.error, 'No se pudo actualizar el usuario.');
        }
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileData(file);
            setFileName(file.name);
        } else {
            setFileName("No se ha elegido ningún archivo");
        }
    };

    return (
        <form className="flex flex-col gap-3 min-w-[40%]" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-3">
                <label for="tipo-de-usuario" className="text-[#025624] font-semibold text-sm">Tipo de usuario:*</label>
                <div className="bg-[#e5e7e6] p-3 font-bold text-sm flex " >
                    <span className='text-center flex-1'>{userRolesMap[formData.role]}</span>
                    <img src={Assets.LockedIcon} className='w-4' />
                </div>
            </div>

            <div className="flex flex-col gap-3">
                <label for="fullname" className="text-[#025624] font-semibold text-sm">Nombre(s):*</label>
                <input required type="text" value={formData.fullname} onChange={handleChange} name="fullname" id="fullname" className="bg-[#e5e7e6] p-3 font-bold text-sm" />
            </div>

            <div className="flex flex-col gap-3">
                <label for="surname" className="text-[#025624] font-semibold text-sm">Apellidos:*</label>
                <input required type="text" value={formData.surname} onChange={handleChange} name="surname" id="surname" className="bg-[#e5e7e6] p-3 font-bold text-sm" />
            </div>

            <div className="flex flex-col gap-3">
                <label for="electronico" className="text-[#025624] font-semibold text-sm">Correo electrónico:*</label>
                <input required type="email" value={formData.email} onChange={handleChange} name="email" id="electronico" className="bg-[#e5e7e6] p-3 font-bold text-sm" />
            </div>

            <div className="flex flex-col gap-3">
                <label for="signature" className="text-[#025624] font-semibold text-sm">Fotographia:</label>
                <div className="flex w-full">
                    <input
                        type="file"
                        id="signature"
                        name='signature'
                        accept=".jpeg,.jpg,.png"
                        onChange={(e) => handleFileChange(e)}
                        style={{ display: "none" }}
                    />
                    <span className={`p-2 flex-1 font-roboto text-sm text-left text-[#4a4f54] h-9 bg-[#e5e7e6]`}>{fileName}</span>
                    <label htmlFor="signature" className={styles.customFileUpload}>
                        <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                        ANEXAR
                    </label>
                </div>
            </div>

            {/* <div className="flex flex-col gap-3">
                <label for="password" className="text-[#025624] font-semibold text-sm">Contraseña:*</label>
                <div className="bg-[#e5e7e6] p-3 font-bold text-sm flex items-center justify-between">
                    <input required className="w-full bg-[#e5e7e6]" type={showPassword ? "text" : "password"} value={formData.password} onChange={handleChange} name="password" id="password" />
                    <span className="cursor-pointer" onClick={() => setShowPassword((open) => !open)}>
                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                </div>
            </div>

            <div className="flex flex-col gap-3">
                <label for="confirmar-contrasena" className="text-[#025624] font-semibold text-sm">Confirmar Contraseña:*</label>
                <div className="bg-[#e5e7e6] p-3 font-bold text-sm flex items-center justify-between">
                    <input required className="w-full bg-[#e5e7e6]" type={showConfirmPassword ? "text" : "password"} value={formData.confirmPassword} onChange={handleChange} name="confirmPassword" id="confirmar-contrasena" />
                    <span className="cursor-pointer" onClick={() => setShowConfirmPassword((open) => !open)}>
                        {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                </div>
            </div> */}

            <button
                type="submit"
                className="mb-16 flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-green-700"
            >
                <img src={require("../../Assets/buttonIcon.png")} alt="" />
                <span className="font-russo text-lg">GUARDAR</span>
            </button>

        </form>
    )
}

export default UserProfile