import React from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { Link } from "react-router-dom";
import { Assets } from "../../Assets";
import { formatDateTo3CharMonth, months } from "../../app.constants";
// https://www.npmjs.com/package/@leenguyen/react-flip-clock-countdown

export default function Card({ project }) {

  // const dateFormator = (dateStr) => {
  //   const date = new Date(dateStr);
  //   const day = date.getDate();
  //   return `${months[date.getMonth()].substring(0, 3)}.${day < 10 ? '0' + day : day}.${date.getFullYear()}`
  // }
  return (
    <div className="shadow-md rounded-[24px] bg-[#D9D9D9D1] max-w-[400px]">
      <figure className="relative flex flex-col items-center">
        <div className="relative w-full">
          <img src={`/${project?.img}`} className="w-full h-[200px] rounded-t-[24px] object-cover" alt="" />
          <div style={{
            position: 'absolute',
            inset: 0,
            backgroundColor: 'black',
            opacity: 0.3,
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px'
          }}></div>
        </div>
        <div className="absolute top-[65%] w-fit rounded-md bg-slate-300 px-2 py-1 opacity-90">
          <FlipClockCountdown
            to={project?.closingDate}
            labels={["DIAS", "HORAS", "MINUTOS", "SEGUNDOS"]}
            labelStyle={{
              fontSize: 10,
              fontWeight: 500,
              textTransform: "uppercase",
            }}
            digitBlockStyle={{
              width: 24,
              height: 36,
              fontSize: 20,
              color: "black",
              backgroundColor: "rgba(203, 213, 225, 0.95)",
            }}
            dividerStyle={{
              color: "red",
              height: 0,
            }}
            separatorStyle={{ color: "transparent", size: "0px" }}
            duration={1}
          >
            {/* component rendered when timer finishes */}
            <div className="flex items-center justify-center px-4 py-1">
              <h3 className="text-center text-xl">Licitación terminada</h3>
            </div>
          </FlipClockCountdown>
        </div>
      </figure>

      {/* rounded bottom might need configuration */}

      <div className="py-3 px-2 flex flex-col items-center rounded-b-[24px] text-[#4A4F54]">
        <div className="flex gap-2">
          <img
            className="w-10"
            src={project?.icon}
            alt="" />
          <span className="text-2xl text-wrap font-bold">{project.name}</span>
        </div>
        <p className="text-lg italic">-{project.text}-</p>
        <div className="flex mt-1 gap-2 items-center">
          <img
            className="w-6"
            src={Assets.CalnderStartIcon}
            alt="" />
          <span className="text-sm italic">
            Fecha de apertura:
            <b> {formatDateTo3CharMonth(project?.openingDate)}</b>
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <img
            className="w-6"
            src={Assets.CalnderClockIcon}
            alt="" />
          <span className="text-sm italic">
            Fecha de cierre:
            <b> {formatDateTo3CharMonth(project?.closingDate)}</b>
          </span>
        </div>
        <Link
          // to="/cnscoop"
          to={`/system/licitation?id=${project?._id}`}
          className={`mb-2 mt-2 flex items-center justify-center gap-2 rounded-[12px] border-4 border-solid border-white   px-3 py-2 text-white bg-green-800 hover:bg-green-700`}
        >
          <img src={require("../../Assets/buttonIcon.png")} alt="" />
          <span className="font-russo text-lg">VER MAS</span>
        </Link>
      </div>
    </div>
  );
}
