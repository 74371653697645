import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'; // Import useLocation
import styles from "./Header.module.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Icon1 from "../../Images/location-marker.png";
import Icon2 from "../../Images/email.png";
import Icon3 from "../../Images/telephone.png";
import Icon4 from "../../Images/world.png";
import Facebook from "../../Images/Facebook.png";
import LinkedIn from "../../Images/Linkedin.png";
import Instagram from "../../Images/Instagram.png";
import Youtube from "../../Images/youtube.png";
import Twitter from "../../Images/twitter.png";
import Logo from "../../Images/CSNlogo.png";
import Logo2 from "../../Images/logo2.png";
import BtnIcn from "../../Images/headBtnIcn1.png";
import BtnIcn2 from "../../Images/headBtnIcn2.png";
import "./Header.css";
import { useAuth } from '../../Hoc/AuthContext';
import { userRoles } from '../../app.constants';
import { Assets } from '../../Assets';
import { useAppContext } from '../../Hoc/AppContext';
import { CgProfile } from "react-icons/cg";


const UserProfileDropdown = ({ user, logout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useState(null)[0];
  const { setActiveTab } = useAppContext();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* User info button */}
      <button
        onClick={toggleDropdown}
        className="flex items-center px-4 py-2 w-full bg-none text-green-800 font-medium transition-colors hover:text-white focus:outline-none"
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <div className="h-10 w-10 flex items-center justify-center rounded-full overflow-hidden border-2 border-white bg-gray-300">
              {user.profileImage ?
                <img
                  src={`/${user.profileImage}`}
                  alt={'img'}
                  className="h-full w-full object-cover"
                />
                : <CgProfile className='h-10 w-10 ' />}
            </div>

            <span className="ml-3">Hola {user.fullname}</span>
          </div>
          <svg
            className={`w-5 h-5 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </div>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute w-full bg-white border-t-2 shadow-lg z-10">
          <ul className="divide-y divide-gray-100">
            <li>
              {user && [userRoles.admin, userRoles.auditor, userRoles.projectCreator].includes(user.role)
                ?
                <button
                  onClick={() => {
                    navigate('/admin');
                    setActiveTab({ id: 'profile', name: "Perfil del Usuario", img: require("../../Assets/tab5.png") })
                    toggleDropdown();
                  }}
                  className="flex w-full gap-1 items-center px-2 py-3 bg-green-800 text-white hover:bg-green-700 transition-colors"
                >
                  <img src={Assets.UserProfileIcon} alt='' className='w-8' />
                  <span>Perfil del Usuario</span>
                </button>
                :
                <Link
                  to='/system/profile'
                  className="flex  gap-1 items-center px-2 py-3 bg-green-800 text-white hover:bg-green-700 transition-colors"
                >
                  <img src={Assets.UserProfileIcon} alt='' className='w-8' />
                  <span>Perfil del Usuario</span>
                </Link>
              }
            </li>
            <li>
              <Link
                onClick={logout}
                className="flex gap-1 items-center px-2 py-3 bg-green-800 text-white hover:bg-green-700 transition-colors"
              >
                <img src={Assets.LogoutIcon} alt='' className='w-8 ' />
                <span>Cerrar Sesión</span>
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

const Header = () => {
  const location = useLocation(); // Get current location
  const { isAuthenticated, logout, user } = useAuth();

  return (
    <div className={styles.headerMain}>
      <div className={styles.header1Main}>
        <div className={styles.header1Container}>
          <div className={styles.detailMain}>
            <img src={Icon2} alt="" className={styles.icon} />
            <div className={styles.detailText}>licitaciones@csn.coop</div>
          </div>
          <div className={styles.detailMain}>
            <img src={Icon3} alt="" className={styles.icon} />
            <div className={styles.detailText}>818-305-6900</div>
          </div>
          <div className={styles.detailMain}>
            <img src={Icon4} alt="" className={styles.icon} />
            <div className={styles.detailText}>https://www.csn.coop/</div>
          </div>
          <div className={styles.detailMain}>
            <img src={Icon1} alt="" className={styles.icon} />
            <div className={styles.detailText}>
              Av. República Mexicana No.401 Col. Las Puentes 2 Sec. San Nicolás
              de los Garza N.L.
            </div>
          </div>
        </div>
        <div className={styles.header1Container2}>
          <a href='https://www.facebook.com/CSNCooperativaFinanciera' rel="noreferrer" target="_blank"><img src={Facebook} alt="" className={styles.logo} /></a>
          <a href='https://www.linkedin.com/company/csn-cooperativa-financiera/' rel="noreferrer" target="_blank"><img src={LinkedIn} alt="" className={styles.logo} /></a>
          <a href='https://www.instagram.com/csncooperativa/?hl=es-la' rel="noreferrer" target="_blank"><img src={Instagram} alt="" className={styles.logo} /></a>
          <a href='https://www.youtube.com/channel/UCUYIj1YXv6RZP0vNYeLkHQQ?view_as=subscriber' rel="noreferrer" target="_blank"><img src={Youtube} alt="" className={styles.logo} /></a>
          <a href='https://x.com/csncooperativa' rel="noreferrer" target="_blank"><img src={Twitter} alt="" className={styles.logo} /></a>
        </div>
      </div>
      <div>
        {["lg"].map((expand) => (
          <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
            <Container fluid>
              <Navbar.Brand href="#">
                <img src={Logo} alt="" className={styles.logoImg} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <img src={Logo2} alt="" className={styles.logoImg} />

                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-center flex-grow-1 pe-3 align-self-center">
                    <Link to="/system/" className={`nav-link ${location.pathname === '/system/' ? 'active-link' : ''}`}>
                      Inicio
                    </Link>

                    <Link to="/system/proyectos" className={`nav-link ${location.pathname === '/system/proyectos' ? 'active-link2' : ''}`}>
                      Proyectos
                    </Link>

                    <Link to="/system/proveedores" className={`nav-link ${location.pathname === '/system/proveedores' ? 'active-link3' : ''}`}>
                      Proveedores
                    </Link>

                    <Link to="/system/contact" className={`nav-link ${location.pathname === '/system/contact' ? 'active-link4' : ''}`}>
                      Contacto
                    </Link>
                  </Nav>
                  <div className={styles.NavButtonsMain}>
                    {!isAuthenticated && <Link to="/system/register-main" className={`${styles.navBtn2} ${location.pathname === '/system/register-main' ? 'active-link5' : ''}`}>
                      <img src={BtnIcn2} alt="" className={styles.navBtnIcn2} />
                      REGISTRO
                    </Link>}
                    {!isAuthenticated
                      ? <Link to="/system/login" className={`${styles.navBtn} ${location.pathname === '/system/login' ? 'active-link5' : ''}`}>
                        <img src={BtnIcn} alt="ACCESO Icon" className={styles.navBtnIcn} />
                        ACCESO
                      </Link>
                      : user && user.role === userRoles.provider &&
                      <button onClick={logout} className={`${styles.navBtn}`}>
                        CERRAR SESIÓN
                      </button>}

                    {isAuthenticated && user && [userRoles.admin, userRoles.auditor, userRoles.projectCreator].includes(user?.role) &&
                      <Link to="/admin" className={`${styles.navBtn2}`}>
                        <img src={Assets.PanelIcon} alt="panel icon" className={`${styles.navBtnIcn2} w-9`} />
                        PANEL
                      </Link>}

                    {isAuthenticated && user && user.role !== userRoles.provider &&
                      <UserProfileDropdown
                        user={user}
                        logout={logout}
                      />}

                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </div>
  );
};

export default Header;
