import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import Home from "./Components/Home/Home";
import Terms from "./Components/Terms/Terms";
import Register from "./Components/Register/Register";
import RegisterDoc from "./Components/RegisterDoc/RegisterDoc";
import SupRegister from "./Components/SupRegister/SupRegister";
import InternasContra from "./Components/InternasContra/InternasContra";
import Contact from "./Components/Contact/Contact";
import ContactInfo from "./Components/ContactInfo/ContactInfo";
import ProjectRegister from "./Components/ProjectRegister/ProjectRegister";
import RegisterGou from "./Components/RegisterGou/RegisterGou";
import MatrizComparative from "./Components/MatrizComparative/MatrizComparative";
import Licitation from "./Components/Licitation/Licitation";
import Proyectos from "./Components/Proyectos/Proyectos";
import Providers from "./Components/Provider/Providers";
import FinancePage from "./Components/FinancePage";
import UserReport from "./Components/AdminDashboard/UserReport/UserReport";
import LevelReport from "./Components/Admin/LevelReport/LevelReport";
import ScoreReport from "./Components/Admin/ScoreReport/ScoreReport";
import Construction from "./Components/Construction/Construction";
import Layout from "./layout/Layout";
import RegisterMain from "./Components/RegisterMain/RegisterMain";
import ForgotPass from "./Components/ForgotPass/ForgotPass";
import { AuthProvider } from "./Hoc/AuthContext";
import PrivateRoute from "./Hoc/PrivateRoute";
import Dashboard from "./Components/AdminDashboard/Dashboard";
import { CustomToastContainer } from "./Components/Shared/CustomToast";
import { userRoles } from "./app.constants";
import { AppContextProvider } from "./Hoc/AppContext";
import UpdatePassword from "./Components/ForgotPass/UpdatePassword";
import UserProfilePage from "./Components/UserProfile/UserProfilePage";

const App = () => {
  return (
    <div>
      <AuthProvider>
        <AppContextProvider>
          <CustomToastContainer />
          <Router>
            <Routes>
              <Route path="/" element={<Construction />} />
              <Route path="/system/" element={<Layout><Home /></Layout>} />
              <Route path="/system/contact" element={<Layout><Contact /></Layout>} />
              <Route path="/system/proyectos" element={<Layout><Proyectos /></Layout>} />
              <Route path="/system/terms" element={<Layout><Terms /></Layout>} />
              <Route path="/system/matriz-comparative" element={
                <PrivateRoute role={[userRoles.voter, userRoles.contralor, userRoles.admin, userRoles.acquisitions, userRoles.auditor, userRoles.projectCreator]}>
                  <Layout><MatrizComparative /></Layout>
                </PrivateRoute>
              } />
              <Route path="/system/licitation" element={<Layout><Licitation /></Layout>} />

              {/* MATRIZ DE PONDERACIONES in MatrizComparative needs overflow set to scroll */}
              <Route path="/system/login" element={<Layout><Register /></Layout>} />
              <Route path="/forgotpassword" element={<Layout><ForgotPass /></Layout>} />
              <Route path="/reset-password/:token" element={<Layout><UpdatePassword /></Layout>} />
              <Route path="/system/register-main" element={<Layout><RegisterMain /></Layout>} />
              <Route path="/system/profile" element={
                <PrivateRoute role={[userRoles.voter, userRoles.contralor, userRoles.admin, userRoles.acquisitions, userRoles.auditor, userRoles.projectCreator]}>
                  <Layout><UserProfilePage /></Layout>
                </PrivateRoute>
              } />

              <Route path="/system/register-doc" element={
                <PrivateRoute role={[userRoles.provider]}>
                  <Layout><RegisterDoc /></Layout>
                </PrivateRoute>
              } />
              <Route path="/system/sup-register" element={
                <PrivateRoute role={[userRoles.provider]}>
                  <Layout><SupRegister /></Layout>
                </PrivateRoute>
              } />
              <Route path="/system/contactinfo" element={
                <PrivateRoute role={[userRoles.provider]}>
                  <Layout><ContactInfo /></Layout>
                </PrivateRoute>
              } />
              <Route
                path="/system/register-gourmet"
                element={
                  <PrivateRoute role={[userRoles.provider]}>
                    <Layout><RegisterGou /></Layout>
                  </PrivateRoute>
                } />
              <Route path="/system/pro-register" element={<Layout><ProjectRegister /></Layout>} />
              {/* <Route path="/system/login" element={<Layout><Login /></Layout>} /> */}

              {/* ************************************ */}

              <Route path="/system/proveedores" element={<Layout><Providers /></Layout>} />
              <Route
                path="/system/validation"
                element={
                  <PrivateRoute role={[userRoles.contralor, userRoles.admin, userRoles.acquisitions]}>
                    <Layout><InternasContra /></Layout>
                  </PrivateRoute>
                } />
              <Route path="/system/financepage" element={<Layout><FinancePage /></Layout>} />

              {/* admin routes */}
              <Route
                path="/admin"
                element={
                  <PrivateRoute role={[userRoles.admin, userRoles.projectCreator, userRoles.auditor]}>
                    <Layout><Dashboard /></Layout>
                  </PrivateRoute>
                } />
              {/* <Route path="/admin/createuser" element={<Layout><CreateUser /></Layout>} /> */}
              {/* <Route path="/admin/addproject" element={<Layout><AddProjects /></Layout>} /> */}
              <Route path="/admin/user-report" element={<Layout><UserReport /></Layout>} />
              <Route path="/admin/level-report" element={<Layout><LevelReport /></Layout>} />
              <Route path="/admin/score-report" element={<Layout><ScoreReport /></Layout>} />

            </Routes>
          </Router>
        </AppContextProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
