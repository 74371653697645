import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../services/axios';
import { apiRoutes, auditLogFilters, formatDateTo3CharMonth, generalError, getFormatedTime, severity, toastTypes, userRolesMap } from '../../../app.constants';
import { showToast } from '../../Shared/CustomToast';
import Spinner from '../../Shared/Spinner';
import { Assets } from '../../../Assets';
import { Checkbox, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { unparse } from "papaparse";


const AuditLogs = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [totalLogs, setTotalLogs] = useState(0);
    const [reportFilter, setReportFilter] = useState('');

    useEffect(() => {
        fetchLogs();
    }, []);

    const fetchLogs = async (params) => {
        try {
            const { data } = await axiosInstance.get(apiRoutes.logs.getAll, { params: { ...params, limit: 50 } });
            const { logs, totalPages, currentPage, totalLogs } = data;
            const parsedLogs = logs.map(log => {
                log.date = formatDateTo3CharMonth(log.createdAt);
                log.time = getFormatedTime(log.createdAt);
                log.isSelected = false;
                return log;
            });
            setLogs(parsedLogs);
            setTotalPages(totalPages);
            setTotalLogs(totalLogs);
        } catch (error) {
            showToast(toastTypes.error, generalError);
            console.error('Error fetching logs:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleDeleteLogs = async (e) => {
        e.preventDefault();
        const selectedLogsIds = logs.filter(log => log.isSelected).map(log => log._id);
        try {
            await axiosInstance.post(apiRoutes.logs.delete, { selectedLogsIds });
            setLogs(logs.filter(log => !selectedLogsIds.includes(log._id)));
            showToast(toastTypes.success, 'Registros seleccionados eliminados correctamente');
        } catch (error) {
            showToast(toastTypes.error, generalError);
            console.error('Error deleting logs:', error);
        }
    };

    const handleSelect = (e, logId) => {
        let updatedLogs = logs.map((cLog, index) => cLog?._id === logId ? { ...cLog, isSelected: !cLog.isSelected } : cLog);
        setLogs(updatedLogs);
    }

    const onPageChange = (e, newPage) => {
        setPage(newPage)
        fetchLogs({ page: newPage });
    }

    const onFilterChange = (e) => {
        setReportFilter(e.target.value);
        setPage(1);
        fetchLogs({ page: 1, reportFilter: e.target.value });
    }

    const handleDownloadReport = (e) => {
        e.preventDefault();
        const data = logs.map(log => {
            return {
                Tipo: userRolesMap[log.role],
                Fecha: log.date,
                Hora: log.time,
                Dirección_IP: log.ipAddress,
                Correo_Electrónico: log.email,
                Registro: log.action,
                Severidad: log.severity
            }
        })
        const filename = "auditoría-de-uso.csv";
        const csv = unparse(data);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const columns = [
        { id: 'checkbox', label: '' },
        { id: 'num', label: '#' },
        {
            id: 'role',
            label: 'Usuario',
            align: 'center',
            format: (value) => userRolesMap[value]
        },
        {
            id: 'date',
            label: 'Fecha',
            align: 'center',
        },
        {
            id: 'time',
            label: 'Hora',
            align: 'center',
        },
        {
            id: 'ipAddress',
            label: 'Dirección IP',
            align: 'center',
        },
        {
            id: 'email',
            label: 'Correo Electrónico',
            align: 'center',
        },
        {
            id: 'action',
            label: 'Registro',
            align: 'center',
        },
        {
            id: 'severity',
            label: 'Severidad',
            align: 'center',
            format: (value) => {
                return <div className='flex w-full justify-center'>
                    {value === severity.high
                        ? <img className="w-8" src={Assets.HighSeverityIcon} />
                        : value === severity.medium
                            ? <img className="w-8" src={Assets.MediumSeverityIcon} />
                            : <img className="w-8" src={Assets.LowSeverityIcon} />
                    }
                </div>
            }
        },
    ];

    return (loading ? <Spinner /> :
        <>
            <div className='flex justify-end w-full max-w-[90%] px-[5%]'>
                <div className='mb-2 mt-5 flex gap-4 items-center justify-center'>
                    <button
                        type="submit"
                        disabled={!logs.some(log => log.isSelected)}
                        onClick={(e) => handleDeleteLogs(e)}
                        className="flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-[#70a950]"
                    >
                        <img className='w-6' src={Assets.LogEliminateIcon} alt="" />
                        <span className="font-russo ">ELIMINAR</span>
                    </button>
                    <div>
                        <label htmlFor="filters">Reporte:</label>
                        <select id='filters' className='ml-3 px-2 py-2 bg-gray-200' value={reportFilter} onChange={onFilterChange}>
                            <option value="">Todos los registros</option>
                            {Object.entries(auditLogFilters).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </select>
                    </div>

                    <button
                        type="submit"
                        onClick={(e) => handleDownloadReport(e)}
                        className="flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-[#70a950]"
                    >
                        <img src={Assets.DownloadIcon} alt="" />
                        <span className="font-russo ">DESCARGAR</span>
                    </button>
                </div>
            </div>

            <div className="mx-auto w-full max-w-[90%]">
                <Paper sx={{ width: '100%', overflow: 'auto' }}>
                    <TableContainer sx={{ height: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead sx={{ background: '#d1d5db' }}>
                                <TableRow >
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{ background: '#e5e7eb' }}
                                            className='border-r'
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logs.map((log, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={log._id}>
                                            {columns.map((column) => {
                                                const value = log[column.id];
                                                return <TableCell key={column.id} align={column.align} className='border-r'>
                                                    {column.id === 'checkbox'
                                                        ? <Checkbox
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: '#025624',
                                                                },
                                                                p: 0
                                                            }}
                                                            size='small'
                                                            checked={log.isSelected}
                                                            onChange={(e) => handleSelect(e, log._id)}
                                                        />
                                                        : column.id === 'num'
                                                            ? index + 1
                                                            : column.id === 'role' || column.id === 'severity'
                                                                ? column.format(value)
                                                                : value
                                                    }
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* Pagination controls */}
                    <div className="flex items-center justify-between px-6 py-3 bg-gray-50 border-t">
                        <div className="flex items-center">
                            <span className="text-sm text-gray-700">
                                Mostrando {((page - 1) * 50) + 1} - {Math.min(page * 50, totalLogs)} de {totalLogs} resultados
                            </span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={onPageChange}
                                defaultPage={1}
                                siblingCount={1}
                                boundaryCount={1}
                            />
                        </div>
                    </div>
                </Paper>
            </div>
        </>
    )
}

export default AuditLogs