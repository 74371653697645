import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/axios';
import { apiRoutes, userRoles } from '../../../app.constants';
import { useAuth } from '../../../Hoc/AuthContext';
import AnswerForm from './AnswerForm';
import chatIcon from '../../../Assets/chat-icon.svg';

const QASection = ({ projectId, isProjectActive }) => {
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const { isAuthenticated, logout, user } = useAuth();

    useEffect(() => {
        fetchQuestions();
    }, [projectId]);

    // Fetch questions
    const fetchQuestions = async () => {
        const response = await axiosInstance.get(apiRoutes.project.questions.replace('{projectId}', projectId));
        const { questions } = response?.data;
        const newQuestions = questions.map(q => {
            q.formOpen = false;
            return q;
        })
        setQuestions(newQuestions);
    };


    const handlePostQuestion = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(
                apiRoutes.project.questions.replace('{projectId}', projectId),
                { content: newQuestion }
            )
            const data = response?.data;
            await fetchQuestions();
            setNewQuestion('');
        } catch (error) {
            console.log('Failed to post question', error);
        } finally {
            setLoading(false);
        }
    };

    const onAnswerAdded = async () => {
        await fetchQuestions();
    }

    const onReplyClick = (questionId) => {
        setQuestions((prev) => [...prev.map(q => q._id === questionId ? { ...q, formOpen: !q.formOpen } : q)])
    }

    return (
        <>
            {(isAuthenticated && user?.role === userRoles.provider) && isProjectActive &&
                <div className='flex items-center space-x-5 mt-2 mb-4'>
                    <input
                        type="text"
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                        placeholder="hacer una pregunta..."
                        className="flex-grow p-2 border rounded"
                    />
                    <button
                        onClick={handlePostQuestion}
                        disabled={loading}
                        className="p-2 flex items-center justify-center gap-2 font-russo bg-[#585857] rounded-[9px] text-[16px] text-white"
                    >
                        <img
                            src={chatIcon}
                            className="h-[20px] w-[20px]"
                            alt=""
                        />
                        ENVIAR
                    </button>
                </div>
            }
            <ol className="mx-auto list-decimal pl-5 text-start">
                {questions?.map((q) => (
                    <li key={q._id} className='mt-3'>
                        <span className='font-bold'>{q.content}</span>
                        <ul>
                            {!q?.answers?.length
                                ? <li>
                                    <small>No respondido</small>
                                </li>
                                : q.answers.map((a) => (
                                    <li key={a._id}>{a.content}</li>
                                ))
                            }
                        </ul>

                        {isAuthenticated && user?.role !== userRoles.provider && isProjectActive &&
                            <button
                                onClick={() => onReplyClick(q._id)}
                                disabled={loading}
                                className={`px-3 py-1 mt-2 ${q.formOpen ? 'bg-[#E0E0E0] text-[rgba(74,79,84,1)]' : 'bg-[#166534] text-white'} font-russo text-[14px] rounded-[50px]`}
                            >
                                {q.formOpen ? 'Cancelar' : 'Responder'}
                            </button>
                        }
                        {q.formOpen && <AnswerForm questionId={q._id} projectId={projectId} onAnswerAdded={onAnswerAdded} />}
                    </li>

                ))}
            </ol>
        </>
    );
};

export default QASection;
