import React, { useState } from "react";
import styles from "./ContactInfo.module.css";
import RegIcn from "../../Images/RegIcn.png";
import ContactInfoStep from "../../Images/contactinfo.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import { useNavigate } from "react-router-dom";
import { apiRoutes, signupError, toastTypes } from "../../app.constants";
import axiosInstance from "../../services/axios";
import { useAuth } from "../../Hoc/AuthContext";
import { showToast } from "../Shared/CustomToast";
import InfoDialog from "../Shared/InfoDialog";

const ContactInfo = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  // Define state for each input field
  const [formDataNew, setFormData] = useState({
    street: "",
    extNum: "",
    intNum: "",
    colony: "",
    municipality: "",
    state: "",
    country: "",
    postalCode: "",
    fiscalStreet: "",
    fiscalExtNum: "",
    fiscalIntNum: "",
    fiscalColony: "",
    fiscalMunicipality: "",
    fiscalState: "",
    fiscalCountry: "",
    fiscalPostalCode: "",
    intlCode: "",
    phone: "",
    website: "",
  });

  // State for checkbox
  const [isSameAddress, setIsSameAddress] = useState(false);

  // State for error messages
  const [errors, setErrors] = useState({});

  const { logout } = useAuth();

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle checkbox change
  const handleCheckboxChange = () => {
    setIsSameAddress(!isSameAddress);

    if (!isSameAddress) {
      // Autofill fiscal address fields
      setFormData((prevData) => ({
        ...prevData,
        fiscalStreet: prevData.street,
        fiscalExtNum: prevData.extNum,
        fiscalIntNum: prevData.intNum,
        fiscalColony: prevData.colony,
        fiscalMunicipality: prevData.municipality,
        fiscalState: prevData.state,
        fiscalCountry: prevData.country,
        fiscalPostalCode: prevData.postalCode,
      }));
    } else {
      // Clear fiscal address fields if checkbox is unchecked
      setFormData((prevData) => ({
        ...prevData,
        fiscalStreet: "",
        fiscalExtNum: "",
        fiscalIntNum: "",
        fiscalColony: "",
        fiscalMunicipality: "",
        fiscalState: "",
        fiscalCountry: "",
        fiscalPostalCode: "",
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    const newErrors = {};

    // Check for required fields
    const requiredFields = [
      "street",
      "extNum",
      "colony",
      "state",
      "country",
      "postalCode",
      "intlCode",
      "phone",
      "website",
      "municipality",
    ];

    requiredFields.forEach((field) => {
      if (!formDataNew[field]) {
        newErrors[field] =
          "Favor de completar el campo obligatorio para poder proceder.";
      }
    });

    // Postal code validation (4-9 characters, letters, numbers, "-")
    if (!/^[A-Za-z0-9-]{4,9}$/.test(formDataNew.postalCode)) {
      newErrors.postalCode =
        "Código Postal incompleto o incorrecto favor de corregir.";
    }

    // International Code validation (1-3 digits)
    if (!/^\d{1,3}$/.test(formDataNew.intlCode)) {
      newErrors.intlCode =
        "El código internacional debe tener entre 1 y 3 dígitos.";
    }

    // Phone number validation (7-12 digits)
    if (!/^\d{7,12}$/.test(formDataNew.phone)) {
      newErrors.phone =
        "El número de teléfono debe tener entre 7 y 12 dígitos.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Display errors
      return;
    }

    const formDataObj = {
      physicalCalle: formDataNew.street,
      physicalNumExt: formDataNew.extNum,
      physicalNumInternational: formDataNew.intNum,
      physicalColonia: formDataNew.colony,
      physicalMunicipality: formDataNew.municipality,
      physicalState: formDataNew.state,
      physicalCountry: formDataNew.country,
      physicalPostalCode: formDataNew.postalCode,
      taxCalle: formDataNew.fiscalStreet,
      taxNumExt: formDataNew.fiscalExtNum,
      taxNumInternational: formDataNew.fiscalIntNum,
      taxColonia: formDataNew.fiscalColony,
      taxMunicipality: formDataNew.fiscalMunicipality,
      taxState: formDataNew.fiscalState,
      taxCountry: formDataNew.fiscalCountry,
      taxPostalCode: formDataNew.fiscalPostalCode,
      website: formDataNew.website,
      intCode: formDataNew.intlCode,
      phone: formDataNew.phone,
    };

    try {
      const response = await axiosInstance.patch(
        apiRoutes.auth.signupStep4,
        formDataObj,
      );

      setOpenDialog(true);
    } catch (error) {
      showToast(toastTypes.error, signupError);
      console.error(error);
    }
  };

  const onOkayClick = () => {
    setOpenDialog(false);
    logout();
    navigate("/system/login");
  }

  return (
    <div className={styles.conInfoMain}>
      {openDialog && <InfoDialog
        setOpenDialog={onOkayClick}
        heading={'¡Gracias!'}
        message={'¡Hemos recibido la información para tu registro! La estaremos procesando y te mantendremos informado.'}
      />}
      <div className={styles.conInfoConatiner}>
        <div className={styles.conInfoConatiner2}>
          <div className={styles.registerMainHeading}>
            <img src={RegIcn} alt="" className={styles.regIcn} />
            Datos de Contacto
          </div>
          <div className={styles.supRegStepMain}>
            <img src={ContactInfoStep} alt="" className={styles.contactInfoStep} />
          </div>
          <div className={styles.radioText}>Dirección Física:*</div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Calle:*
              <input type="text" name="street" value={formDataNew.street} onChange={handleChange} className={styles.inputRegister} />
              {errors.street && <div className={styles.errorText}>{errors.street}</div>}
            </div>
            <div className={styles.inputRegisterRight}>
              Núm. Ext.:*
              <input type="text" name="extNum" value={formDataNew.extNum} onChange={handleChange} className={styles.inputRegister} />
              {errors.extNum && <div className={styles.errorText}>{errors.extNum}</div>}
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Núm. Int.:
              <input type="text" name="intNum" value={formDataNew.intNum} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Colonia:*
              <input type="text" name="colony" value={formDataNew.colony} onChange={handleChange} className={styles.inputRegister} />
              {errors.colony && <div className={styles.errorText}>{errors.colony}</div>}
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Municipio:*
              <input type="text" name="municipality" value={formDataNew.municipality} onChange={handleChange} className={styles.inputRegister} />
              {errors.municipality && <div className={styles.errorText}>{errors.municipality}</div>}
            </div>
            <div className={styles.inputRegisterRight}>
              Estado:*
              <input type="text" name="state" value={formDataNew.state} onChange={handleChange} className={styles.inputRegister} />
              {errors.state && <div className={styles.errorText}>{errors.state}</div>}
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              País:*
              <input type="text" name="country" value={formDataNew.country} onChange={handleChange} className={styles.inputRegister} />
              {errors.country && <div className={styles.errorText}>{errors.country}</div>}
            </div>
            <div className={styles.inputRegisterRight}>
              Código Postal:*
              <input
                type="text"
                name="postalCode"
                value={formDataNew.postalCode}
                onChange={handleChange}
                className={styles.inputRegister}
              />
              {errors.postalCode && (
                <div className={styles.errorText}>{errors.postalCode}</div>
              )}
            </div>
          </div>
          <div className={styles.registerCheckbox}>
            <label className="custom-checkbox">
              <input type="checkbox"
                checked={isSameAddress}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>
            <div className={styles.registerCheckboxText}>
              La dirección física es igual que el domicilio fiscal.
            </div>
          </div>
          <div className={styles.radioText}>Domicilio Fiscal:</div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Calle:
              <input type="text" name="fiscalStreet" value={formDataNew.fiscalStreet} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Núm. Ext.:
              <input type="text" name="fiscalExtNum" value={formDataNew.fiscalExtNum} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Núm. Int.:
              <input type="text" name="fiscalIntNum" value={formDataNew.fiscalIntNum} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Colonia:
              <input type="text" name="fiscalColony" value={formDataNew.fiscalColony} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Municipio:
              <input type="text" name="fiscalMunicipality" value={formDataNew.fiscalMunicipality} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Estado:
              <input type="text" name="fiscalState" value={formDataNew.fiscalState} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              País:
              <input type="text" name="fiscalCountry" value={formDataNew.fiscalCountry} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Código Postal:
              <input type="text" name="fiscalPostalCode" value={formDataNew.fiscalPostalCode} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain2}>
            <div className={styles.inputRegisterLeft2}>
              Lada Int.:*
              <input
                type="text"
                name="intlCode"
                value={formDataNew.intlCode}
                onChange={handleChange}
                className={styles.inputRegister2}
                pattern="^\d{1,3}$"  // Restricts input to 1-3 digits
                title="El código internacional debe tener entre 1 y 3 dígitos."
              />
              {errors.intlCode && <div className={styles.errorText}>{errors.intlCode}</div>}
            </div>

            <div className={styles.inputRegisterRight}>
              Teléfono:*
              <input
                type="text"
                name="phone"
                value={formDataNew.phone}
                onChange={handleChange}
                className={styles.inputRegister3}
                pattern="^\d{7,12}$"  // Restricts input to 7-12 digits
                title="El número de teléfono debe tener entre 7 y 12 dígitos."
              />
              {errors.phone && <div className={styles.errorText}>{errors.phone}</div>}
            </div>

          </div>
          {/* <div className={styles.registerInputMain3}>
            Correo Electrónico:*
            <input type="text" name="email" value={formDataNew.email} onChange={handleChange} className={styles.inputRegister4} />
          </div> */}
          <div className={styles.registerInputMain3}>
            Sitio web:*
            <input type="text" name="website" value={formDataNew.website} onChange={handleChange} className={styles.inputRegister4} />
            {errors.website && <div className={styles.errorText}>{errors.website}</div>}
          </div>
          <div className={styles.RegBtnIcnMain}>
            <button className={styles.RegBtnIcn2} onClick={handleSubmit}>
              <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
              <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
              <span>ENVIAR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
