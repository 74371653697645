import axios from 'axios';
import { apiBaseUrl } from '../app.constants';


const axiosInstance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        // 'Authorization': `Bearer ${localStorage.getItem('token')}`
        "x-auth-token": localStorage.getItem("token"),
    }
});

// Function to set the token in the axios instance headers
export const setAuthToken = (token) => {
    if (token) {
        axiosInstance.defaults.headers['x-auth-token'] = token;
    } else {
        delete axiosInstance.defaults.headers['x-auth-token'];
    }
};

// Response interceptor to check for 401 unauthorized error and redirect to login page
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const { config, response } = error;

        // Check if the error is a 401 and not from the login API
        if (response.status === 401 && !config.url.includes("/login")) {
            alert('La sesión ha expirado, serás redirigido a la página de inicio de sesión.')
            localStorage.clear();
            window.location.href = "/system/login"; // Redirect to login page
        }
        return Promise.reject(error);
    }
);

// axiosInstance.defaults.headers['x-auth-token'] = localStorage.getItem("token");

export default axiosInstance;
