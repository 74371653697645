import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Spinner from '../Components/Shared/Spinner';
import { userRoles } from '../app.constants';

const PrivateRoute = ({ children, role }) => {
    const { isAuthenticated, user, loading } = useAuth();

    if (loading) {
        return <Spinner />
    }

    const hasRequiredRole = role && role.length ? role.includes(user?.role) : true;

    // Check if user is a provider and handle step navigation
    if (isAuthenticated && user?.role === userRoles.provider && user.completedStep !== 4) {
        const stepRoutes = {
            1: '/system/register-doc',
            2: '/system/sup-register',
            3: '/system/contactinfo',
            // 4: '/system/login',
        };

        const currentStep = user.completedStep || 0;
        const nextStepRoute = stepRoutes[currentStep];
        if (window.location.pathname !== nextStepRoute) {
            return <Navigate to={nextStepRoute} />;
        }
    }

    console.log('hasRequiredRole: ', hasRequiredRole)

    return !isAuthenticated || !hasRequiredRole
        ? <Navigate to="/system/login" />
        : children;
};

export default PrivateRoute;
