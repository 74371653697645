import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../services/axios';
import { apiRoutes, formatDateTo3CharMonth, generalError, getFormatedTime, toastTypes } from '../../../app.constants';
import { showToast } from '../../Shared/CustomToast';
import Spinner from '../../Shared/Spinner';
import { Assets } from '../../../Assets';
import { Pagination } from '@mui/material';
import EmailDetailsDialog from './EmailDetailsDialog';

const EmailsTable = () => {
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState({});

    useEffect(() => {
        fetchEmails();
    }, []);

    const fetchEmails = async (params = {}) => {
        try {
            const { data } = await axiosInstance.get(apiRoutes.email.getAll, { params: { limit: 50, ...params } });
            const { emails, totalPages, totalRecords } = data;
            const parsedEmails = emails.map(email => {
                email.date = formatDateTo3CharMonth(email.createdAt);
                email.time = getFormatedTime(email.createdAt);
                return email;
            });
            setEmails(parsedEmails);
            setTotalPages(totalPages);
            setTotalRecords(totalRecords);
        } catch (error) {
            showToast(toastTypes.error, generalError);
            console.error('Error fetching emails:', error);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (e, newPage) => {
        setPage(newPage)
        fetchEmails({ page: newPage });
    }

    const handleRowClick = (email) => {
        setSelectedEmail(email);
        setIsDetailsDialogOpen(true);
    };

    return (loading ? <Spinner /> :
        <>
            {isDetailsDialogOpen && <EmailDetailsDialog selectedEmail={selectedEmail} setOpenDialog={setIsDetailsDialogOpen} />}
            <div className="mx-auto w-full max-w-[90%]">
                <div className="w-full bg-white rounded-lg shadow-md">
                    {/* Table wrapper with fixed height */}
                    <div className="flex flex-col h-[450px] mt-5">
                        {/* Table container */}
                        <div className="flex-1 overflow-hidden">
                            <div className="h-full flex flex-col text-center">
                                {/* Header section */}
                                <table className="w-full border border-gray-300">
                                    <thead className="bg-gray-200">
                                        <tr>
                                            {/* <th className="w-[4%] border border-gray-300 px-1 py-3 font-semibold text-gray-900" ></th> */}
                                            <th className="w-[10%] text-left pl-5 border border-gray-300 py-3 font-semibold text-gray-900" >#</th>
                                            <th className="w-[20%] border border-gray-300 px-1 py-3 font-semibold text-gray-900" >De</th>
                                            <th className="w-[50%] border border-gray-300 px-1 py-3 font-semibold text-gray-900" >Asunto</th>
                                            <th className="w-[20%] border border-gray-300 px-1 py-3 font-semibold text-gray-900" >Fecha</th>
                                        </tr>
                                    </thead>
                                </table>

                                {/* Scrollable body section */}
                                <div className="overflow-auto">
                                    <table className="w-full">
                                        <tbody>
                                            {emails.length ? emails.map((email, index) => (
                                                <tr key={email._id}
                                                    className='border-b border-gray-300 h-16 hover:bg-gray-50 hover:cursor-pointer'
                                                    onClick={() => handleRowClick(email)}
                                                >
                                                    <td className="w-[10%] py-1 pl-5">
                                                        <div className='flex gap-4 items-center justify-start'>
                                                            <span>{index + 1}</span>
                                                            <img src={Assets.EmailIcon} alt='' />
                                                        </div>
                                                    </td>
                                                    <td className="w-[20%] py-1 px-1">{email.to}</td>
                                                    <td className="w-[50%] py-1 px-1">{email.subject}</td>
                                                    <td className="w-[20%] py-1 px-1">{`(${email.date}) ${email.time}`}</td>
                                                </tr>
                                            ))
                                                : <div className='h-full w-full flex items-center justify-center'>
                                                    <span>No se encontraron correos electrónicos</span>
                                                </div>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Pagination controls */}
                        <div className="flex items-center justify-between px-6 py-3 bg-gray-50 border-t">
                            <div className="flex items-center">
                                <span className="text-sm text-gray-700">
                                    Mostrando {emails.length ? ((page - 1) * emails.length) + 1 : 0} - {Math.min(page * 50, totalRecords)} de {totalRecords} correos
                                </span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={onPageChange}
                                    defaultPage={1}
                                    siblingCount={1}
                                    boundaryCount={1}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailsTable