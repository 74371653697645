import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../services/axios";
import { apiRoutes, generalError, requirementTypes, toastTypes, userRoles } from "../../app.constants";
import Spinner from "../Shared/Spinner";
import { showToast } from "../Shared/CustomToast";
import { useAuth } from "../../Hoc/AuthContext";

const Providers = () => {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeFilter, setTypeFilters] = useState('');
  const [searchQuery, setSearchQuery] = useState("");

  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get(apiRoutes.allUsers + `?role=provider`);
        const usersData = data?.users.map(user => {
          const { companyName, logo, headerImage, supplierOf } = user?.step3 || {};
          const { physicalAddress } = user?.step4 || {};
          return {
            _id: user._id,
            companyName: companyName || '',
            logo: logo || '',
            bg: headerImage || '',
            supplierOf: supplierOf || '',
            address: physicalAddress?.municipality && physicalAddress?.state ? `${physicalAddress?.municipality}, ${physicalAddress?.state}` : 'dirección'
          }
        });

        setProviders(usersData);
        // setFilteredData(usersData);
      } catch (error) {
        showToast(toastTypes.error, generalError);
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
  };

  // Filter the data based on the search query
  const filteredData = providers.filter((item) =>
    item.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.address.toLowerCase().includes(searchQuery.toLowerCase())
  ).filter(user => typeFilter === 'Todos los proveedores' ? true : typeFilter ? typeFilter === user?.supplierOf : true);

  return (loading ? <Spinner /> :
    <>
      <div className="flex relative flex-col w-full bg-[url('./Images/workBg.png')] bg-cover bg-no-repeat">
        <div className="mx-auto mt-32 flex items-center justify-center gap-4 sm:gap-10">
          <img
            src={require("../../Assets/triangle.png")}
            className="h-9 w-9 sm:h-16 sm:w-16"
            alt=""
          />
          <div className="text-md font-bold leading-[30px] text-[#71A850] sm:text-2xl sm:leading-[47px] md:text-4xl">
            Proveedores
          </div>
        </div>
        <div className="w-full min-h-[1000px] lg:min-h-[1144px] pt-[5%] flex flex-col  relative  mb-[70px]">
          <div className="max-w-[1250px] z-10 mx-auto flex flex-col gap-[20px] w-full  md:flex-row justify-between items-end ">
            <div className="w-[287px] h-[57px]  flex justify-center items-center px-[20px] bg-[#D9D9D9] rounded-[12px]">
              <input
                type="text"
                name=""
                id=""
                placeholder="Buscar Proveedor..."
                className="outline-none border-none  bg-transparent"
                value={searchQuery} // Bind the input to the state
                onChange={(e) => setSearchQuery(e.target.value)} // Update the state when input changes
              />
              <img src={require("../../Images/Intra/search.png")} alt="" />
            </div>

            <div className="h-[57px] flex justify-center gap-4 items-center px-[20px] bg-[#D9D9D9] rounded-[12px]">
              <label for="supplierOf" className="text-[22px] text-[#828282] font-bold">
                Proveedor de:
              </label>
              <select
                id="supplierOf"
                name="supplierOf"
                className="bg-[#D9D9D9]"
                onChange={(e) => setTypeFilters(e.target.value)}
              >
                <option value="">Todos los proveedores</option>
                {requirementTypes.map(type => (<option key={type} value={type}>{type}</option>))}
              </select>
            </div>
          </div>

          <div className="max-w-[1250px] z-20 mx-auto mt-[40px] gap-[40px] px-[20px] xl:px-[0px] w-full flex flex-col lg:flex-row lg:justify-between items-center flex-wrap ">
            {filteredData.length ?
              filteredData.map((provider, index) => {
                return (
                  <div
                    className={`flex flex-col flex-start max-w-[380px]  ${index > 2 ? "mt-[20px]  sm:mt-[100px]" : "mt-[20px] sm:mt-[0px]"
                      } w-full `}
                    key={provider?._id}
                  >
                    <div className="flex flex-col w-full rounded-xl relative" >
                      <div className="relative w-full">
                        <img src={`/${provider?.bg}`} className="w-full rounded-xl h-[296px] object-cover" alt="" />
                        <div style={{
                          position: 'absolute',
                          inset: 0,
                          backgroundColor: 'black',
                          opacity: 0.3,
                          borderRadius: '0.75rem'
                        }}></div>
                      </div>

                      <div className="absolute w-full h-full flex justify-between items-start pt-[50px] pl-[20px] pr-[30px] max-w-[380px]">
                        <div>
                          <div className="font-bold text-[20px] leading-[26px] fontClass text-white">
                            {provider?.companyName || 'NA'}
                          </div>
                          <div className="text-[15px] leading-[17px] fontClass text-white">
                            {provider?.address || 'NA'}
                          </div>
                        </div>
                        <img src={`/${provider?.logo}`} alt="logo" className="w-20" />
                        {isAuthenticated && [userRoles.admin, userRoles.acquisitions, userRoles.contralor].includes(user.role) &&
                          <div className="absolute left-[20px] bottom-[30px]">
                            <Link to={"/system/validation"} onClick={handleLinkClick}>
                              <img src={require("../../Assets/Default.png")} alt="" />
                            </Link>
                          </div>
                        }
                      </div>
                      <div className="absolute right-[100px] bottom-[-30px] box">
                        <Link to={`/system/financepage?id=${provider._id}`} onClick={handleLinkClick}>
                          <img src={require("../../Images/Intra/circle.png")} alt="" />
                        </Link>

                      </div>
                    </div>
                  </div>
                )
              })
              : <p>No se encontró ningún proveedor de este tipo.</p>
            }
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Providers;
