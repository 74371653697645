import AttachmentIcon from './attachment-icon.svg';
import ApprovedIcon from './approve-icon.svg';
import ApprovedBtn from './approved-btn.png';
import RejectBtn from './reject-btn.png';
import PendingBtn from './pending-btn.png';
import WinnerBadgeLogo from './final-winner.png';
import LogoProjectIT from './logo-IT-proj.svg';
import LogoProjectGeneral from './logo-general-proj.svg';
import LogoProjectConstruction from './logo-construction-proj.svg';
import CalnderStartIcon from './calander-star.svg';
import CalnderClockIcon from './calander-clock.svg';
import EliminateIcon from './eliminate-icon.svg';
import LogEliminateIcon from './log-eliminate.svg';
import DownloadIcon from './download-icon.svg';
import ButtonIcon from './buttonIcon.png';
import EmailIcon from './email-icon.svg';
import EmailInboxIcon from './inbox-icon.svg';
import EmailSentIcon from './sent-icon.svg';
import EmailDraftsIcon from './drafts-icon.svg';
import EmailSendIcon from './email-send.svg';
import UnblockIcon from './unblock.svg';
import PanelIcon from './admin-panel.svg';
import TriangleLogo from './triangle.png';
import HighSeverityIcon from './high-severity.svg';
import MediumSeverityIcon from './medium-severity.svg';
import LowSeverityIcon from './low-severity.svg';
import LogoutIcon from './log-off.svg';
import UserProfileIcon from './user-profile.svg';
import LockedIcon from './locked.svg';
import UnlockedIcon from './unlocked.svg';

export const Assets = {
    AttachmentIcon,
    ApprovedIcon,
    ApprovedBtn,
    RejectBtn,
    WinnerBadgeLogo,
    LogoProjectIT,
    LogoProjectGeneral,
    LogoProjectConstruction,
    CalnderStartIcon,
    CalnderClockIcon,
    EliminateIcon,
    LogEliminateIcon,
    DownloadIcon,
    ButtonIcon,
    EmailIcon,
    EmailInboxIcon,
    EmailSendIcon,
    EmailSentIcon,
    EmailDraftsIcon,
    UnblockIcon,
    PanelIcon,
    TriangleLogo,
    PendingBtn,
    HighSeverityIcon,
    MediumSeverityIcon,
    LowSeverityIcon,
    LogoutIcon,
    UserProfileIcon,
    LockedIcon,
    UnlockedIcon
}