import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axiosInstance, { setAuthToken } from '../services/axios';
import { apiRoutes } from '../app.constants';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchUser = async () => {
        try {
            const { data } = await axiosInstance.get(apiRoutes.currentUser);
            if (data.user) {
                setUser(data.user);
            }
        } catch (error) {
            console.log(error);
            logout();
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                // Decode the token and check expiry
                const decoded = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds
                if (decoded.exp && decoded.exp > currentTime) {
                    setIsAuthenticated(true);
                    // Set the token in axios headers
                    setAuthToken(token);
                    fetchUser();
                } else {
                    // Token expired
                    logout();
                    setLoading(false);
                }
            } catch (error) {
                console.error('Invalid token:', error);
                logout();
            }
        } else {
            setLoading(false);
            logout();
        }
    }, []);

    const login = async (data) => {
        localStorage.setItem('token', data?.token);
        localStorage.setItem('user', JSON.stringify(data?.user));
        setIsAuthenticated(true);
        // Set the token in axios headers
        setAuthToken(data?.token);
        setUser({ ...data?.user });
        // await fetchUser();
    };

    const signup = async (data) => {
        localStorage.setItem('token', data?.token);
        localStorage.setItem('user', JSON.stringify(data?.user));
        setIsAuthenticated(true);
        // Set the token in axios headers
        setAuthToken(data?.token);
        setUser({ ...data?.user });
        // await fetchUser();
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setIsAuthenticated(false);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, signup, logout, user, setUser, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
