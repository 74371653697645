import React, { useState } from 'react'
import styles from "../RegisterDoc/RegisterDoc.module.css";
import FileUpIcn from "../../Images/fileupIcn.png";
import { Assets } from '../../Assets';

const UploadDialog = ({ placeholder, handleFileUpload, setOpenDialog, data }) => {

    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState(placeholder || '');
    const [error, setError] = useState('');
    // Control Interno Investigación Proveedor.*

    const onSave = () => {
        if (!fileData) {
            setError('Está pendiente por anexar la documentación respectiva que pertenece a esta casilla.');
            return;
        }
        handleFileUpload(fileData);
    }

    const onCancel = () => {
        setOpenDialog(false);
    }

    const handleFileChange = (event, fileKey) => {
        const file = event.target.files[0];
        if (file) {
            setFileData(file);
            setFileName(file.name);
            setError('');
            //   setErrors(prevState => ({
            //     ...prevState,
            //     [fileKey]: "", // Reset error message if file is selected
            //   }));
        } else {
            setFileName("No file chosen");
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="min-w-[600px] flex flex-col items-center justify-center bg-white p-3 rounded-lg shadow-lg z-10">
                <div className='flex items-center gap-2 mb-4'>
                    <img
                        src={Assets.TriangleLogo}
                        className="h-9 w-9"
                        alt=""
                    />
                    <h2 className="text-xl text-[#4A4F54] font-roboto font-bold mt-2">
                        Remplazar documento
                    </h2>
                </div>

                <div className={styles.fileUploadContainer}>
                    <input
                        type="file"
                        id="fileInput"
                        accept="application/pdf" // Restrict to PDF files only
                        className={styles.regdocFileUp}
                        onChange={(e) => handleFileChange(e, "file01")}
                        style={{ display: "none" }}
                    />
                    <span className={`${styles.fileName} ${styles.fileUploadDialog}`}>{fileName}</span>
                    <label htmlFor="fileInput" className={styles.customFileUpload}>
                        <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                        ANEXAR
                    </label>

                </div>
                {error && <div className={styles.errorMessage}>{error}</div>}
                {/* <p className="py-2 text-[#025624] text-lg font-roboto">
                    ¿Estás seguro de que quieres anunciar un ganador?
                </p> */}
                <div className="pt-3 pb-4 flex gap-2 justify-around">
                    <button
                        className="btnBorder font-russo p-2 w-28 items-center justify-center gap-2 rounded-lg bg-[#D9D9D9] hover:bg-[#71A850] hover:text-white text-[10px] leading-[12px] text-[#4A4F54] lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]"
                        onClick={() => onCancel()}
                    >
                        CANCELAR
                    </button>
                    <button
                        className="btnBorder font-russo p-2 w-28 items-center justify-center gap-2 rounded-lg bg-[#025624] hover:bg-[#71A850] text-[10px] leading-[12px] text-white lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]"
                        onClick={() => onSave()}
                    >
                        ANEXAR
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UploadDialog