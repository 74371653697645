import React, { useState } from "react";
import TimezoneSelect from "react-timezone-select"

const SupportSchedule = ({ setFormData, setFormErrors, formErrors }) => {
    const [schedule, setSchedule] = useState({
        lunes: { active: false, start: "", startMeridian: "AM", end: "", endMeridian: "AM" },
        martes: { active: false, start: "", startMeridian: "AM", end: "", endMeridian: "AM" },
        miercoles: { active: false, start: "", startMeridian: "AM", end: "", endMeridian: "AM" },
        jueves: { active: false, start: "", startMeridian: "AM", end: "", endMeridian: "AM" },
        viernes: { active: false, start: "", startMeridian: "AM", end: "", endMeridian: "AM" },
    });
    const [selectedTimezone, setSelectedTimezone] = useState();
    const [errors, setErrors] = useState({});

    const handleTimezoneChange = (timezone) => {
        if (!timezone) {
            setFormErrors(prev => ({ ...prev, timezone: 'Como se especificó que se ofrece el soporte se tiene que especificar la zona horaria con días y horario de soporte.' }));
        } else {
            setFormErrors(prev => ({ ...prev, timezone: '' }));
        }
        setSelectedTimezone(timezone);
        setFormData(prev => ({ ...prev, timezone }));
    }

    const handleInputChange = (day, field, value) => {
        if (field === "start" || field === "end") {
            const valid = validateTime(value);
            setErrors((prev) => ({
                ...prev,
                [`${day}-${field}`]: valid ? "" : `Favor de corregir la hora por el formato válido integrando los dos puntos (:) entre la hora (hh) y los minutos (mm) donde "h" representa la hora y "m" representan los minutos`,
            }));
        }

        setSchedule((prev) => ({
            ...prev,
            [day]: { ...prev[day], [field]: value },
        }));

        setFormData(prev => ({ ...prev, supportSchedule: schedule, timezone: selectedTimezone }));
    };

    const toggleDay = (day) => {
        setSchedule((prev) => {
            const newScheduel = {
                ...prev,
                [day]: { ...prev[day], active: !prev[day].active },
            }

            const arr = Object.values(newScheduel);
            if (arr.some(sch => sch.active)) {
                setFormErrors(prev => ({ ...prev, supportSchedule: '' }));
            } else {
                setFormErrors(prev => ({ ...prev, supportSchedule: 'Como se especificó que se ofrece el soporte se tiene que especificar la zona horaria con días y horario de soporte.' }));
            }

            return newScheduel;
        });

        setErrors((prev) => ({
            ...prev,
            [`${day}-start`]: "",
            [`${day}-end`]: "",
        }));
    };

    const validateTime = (time) => {
        const timeRegex = /^(0[0-9]|1[0-2]):[0-5][0-9]$/; // Matches hh:mm in 12-hour format
        return timeRegex.test(time);
    };

    return (
        <div className="py-6 max-w-full">
            <div className="flex gap-3 py-3 items-center">
                <span className="text-[15px] text-[#4a4f54] font-medium">Zona Horaria: </span>
                <TimezoneSelect value={selectedTimezone} onChange={(tz) => handleTimezoneChange(tz)} />
            </div>
            {formErrors.timezone && <div className='text-red-500 mt-1'>{formErrors.timezone}</div>}
            <h2 className="text-[15px] mt-4 text-[#4a4f54] font-medium mb-2">Días y Horario de Soporte</h2>
            <div className="grid grid-cols-12 gap-4 items-center mb-2">
                <div className="col-span-2">
                    {/* <span className="font-bold">Day</span> */}
                </div>
                <div className="col-span-3">
                    <span className="text-[15px] text-[#4a4f54] font-medium">Desde</span>
                </div>
                <div className="col-span-3">
                    <span className="text-[15px] text-[#4a4f54] font-medium">Hasta</span>
                </div>
            </div>
            {Object.keys(schedule).map((day) => (
                <div key={day} className="grid grid-cols-12 gap-4 items-center mb-2">
                    {/* Checkbox */}
                    <div className="col-span-2">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                className="w-4 h-4"
                                checked={schedule[day].active}
                                onChange={() => toggleDay(day)}
                            />
                            <span className="ml-2 capitalize">{day}</span>
                        </label>
                    </div>

                    {/* Start Time */}
                    <div className="col-span-3">
                        <div className="flex items-center">
                            <input
                                type="text"
                                className={`w-20 px-2 py-1 border rounded ${errors[`${day}-start`] ? "border-red-500" : "border-gray-300"}`}
                                placeholder="hh:mm"
                                value={schedule[day].start}
                                onChange={(e) => handleInputChange(day, "start", e.target.value)}
                                disabled={!schedule[day].active}
                            />
                            <div className="ml-4 flex items-center">
                                <label className="flex items-center mr-2">
                                    <input
                                        type="radio"
                                        name={`${day}-startMeridian`}
                                        value="AM"
                                        checked={schedule[day].startMeridian === "AM"}
                                        onChange={(e) => handleInputChange(day, "startMeridian", e.target.value)}
                                        disabled={!schedule[day].active}
                                    />
                                    <span className="ml-2">AM</span>
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name={`${day}-startMeridian`}
                                        value="PM"
                                        checked={schedule[day].startMeridian === "PM"}
                                        onChange={(e) => handleInputChange(day, "startMeridian", e.target.value)}
                                        disabled={!schedule[day].active}
                                    />
                                    <span className="ml-2">PM</span>
                                </label>
                            </div>
                        </div>
                        {errors[`${day}-start`] && (
                            <span className="text-red-500 text-xs mt-1">{errors[`${day}-start`]}</span>
                        )}
                    </div>

                    {/* End Time */}
                    <div className="col-span-3">
                        <div className="flex items-center">
                            <input
                                type="text"
                                className={`w-20 px-2 py-1 border rounded ${errors[`${day}-end`] ? "border-red-500" : "border-gray-300"
                                    }`}
                                placeholder="hh:mm"
                                value={schedule[day].end}
                                onChange={(e) => handleInputChange(day, "end", e.target.value)}
                                disabled={!schedule[day].active}
                            />
                            <div className="ml-4 flex items-center">
                                <label className="flex items-center mr-2">
                                    <input
                                        type="radio"
                                        name={`${day}-endMeridian`}
                                        value="AM"
                                        checked={schedule[day].endMeridian === "AM"}
                                        onChange={(e) => handleInputChange(day, "endMeridian", e.target.value)}
                                        disabled={!schedule[day].active}
                                    />
                                    <span className="ml-2">AM</span>
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name={`${day}-endMeridian`}
                                        value="PM"
                                        checked={schedule[day].endMeridian === "PM"}
                                        onChange={(e) => handleInputChange(day, "endMeridian", e.target.value)}
                                        disabled={!schedule[day].active}
                                    />
                                    <span className="ml-2">PM</span>
                                </label>
                            </div>
                        </div>
                        {errors[`${day}-end`] && (
                            <span className="text-red-500 text-xs mt-1">{errors[`${day}-end`]}</span>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SupportSchedule;
