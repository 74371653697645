import React from "react";
import axiosInstance from "../../services/axios";
import { apiRoutes, toastTypes } from "../../app.constants";
import { showToast } from "../Shared/CustomToast";
import UserProfile from "../AdminDashboard/UserProfile";

const UserProfilePage = () => {

    return (
        <div>
            {/* <Header /> */}
            <div className="relative -z-50 hidden px-[20px] lg:block">
                <div className="absolute left-[20px] top-[50px]">
                    <img src={require("../../Images/contact/c1.png")} alt="" />
                </div>
                <div className="absolute left-[20%] top-[50px]">
                    <img src={require("../../Images/contact/c2.png")} alt="" />
                </div>
                <div className="absolute right-[20px] top-[50px]">
                    <img src={require("../../Images/contact/c4.png")} alt="" />
                </div>
            </div>
            <div className="z-10 mt-20 flex flex-col items-center text-[#71A850] sm:mt-[150px]">
                <div className="flex items-center gap-4 sm:gap-8">
                    <img src={require("../../Images/contact/c3.png")} alt="" />
                    <h2 className="fontClass text-[36px] font-bold leading-[47.48px]">
                        Perfil del Usuario
                    </h2>
                </div>
            </div>

            <div className="w-full flex justify-center pt-12">
                <UserProfile />
            </div>

            <div className="-z-50 mt-[-5%] flex items-start justify-between px-[20px]">
                <div>
                    <img src={require("../../Images/contact/c5.png")} alt="" />
                </div>
                <div>
                    <img src={require("../../Images/contact/c6.png")} alt="" />
                </div>
            </div>

            <div className="mt-[50px]">{/* <Footer /> */}</div>
        </div>
    );
};

export default UserProfilePage;
