import { useState, useEffect } from "react";
import Card from "./Card";
import { apiRoutes, isProjectActive, projectTypes, projectTypesMap, projectTypeToIconMap, requirementTypes } from "../../app.constants";
import axiosInstance from "../../services/axios";
import Spinner from "../Shared/Spinner";

const Proyectos = () => {
  const [view, setView] = useState("grid");
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [byProjectType, setByProjectType] = useState(null);
  const [additionalFilter, setAdditionalFilter] = useState("");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get(apiRoutes.project.getAll);
        setProjects(response?.data?.data?.projects || []);
        // setProjects([...response?.data?.data?.projects, ...response?.data?.data?.projects] || []);
      } catch (error) {
        console.error("Error fetching data:", error.response ? error?.response?.data : error?.message);
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  // Filter projects based on the search term AND description(type of project)
  const filteredProjects = projects
    .filter(project => project?.title?.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter(project => {
      return sortBy && sortBy !== 'all' ? sortBy === project?.typeOfRequirement : true;
    })
    .filter(project => (byProjectType && byProjectType !== 'all' ? project?.projectType?.toLowerCase() === byProjectType.toLowerCase() : true))
    .filter((project) => {
      if (additionalFilter === "active") {
        return isProjectActive(project?.closingDate);
      }
      if (additionalFilter === "closed") {
        return !isProjectActive(project?.closingDate);
      }
      return true;
    })
    .sort((a, b) => {
      if (additionalFilter === "recent") {
        return new Date(b?.createdAt) - new Date(a?.createdAt);
      }
      if (additionalFilter === "oldest") {
        return new Date(a?.createdAt) - new Date(b?.createdAt);
      }
      return 0; // No sorting for active or closed
    });


  return (loading ? <Spinner /> :
    <div className="min-h-screen bg-[url('./Images/workBg.png')] bg-no-repeat bg-cover bg-center">
      <div className="flex w-full flex-col px-2 pb-32 pt-12 sm:py-32">
        <div className="mx-auto mb-16 flex items-center justify-center gap-4 sm:gap-10">
          <img
            src={require("../../Assets/triangle.png")}
            className="h-9 w-9 sm:h-16 sm:w-16"
            alt=""
          />
          <div className="text-md font-bold font-roboto leading-[30px] text-[#71A850] sm:text-2xl sm:leading-[47px] md:text-4xl">
            Proyectos de Licitaciones
          </div>
        </div>

        <div className="mx-auto flex w-full max-w-[1440px] flex-col items-center justify-between gap-12 pl-2 pr-4 xl:flex-row xl:items-start xl:gap-2">
          <div className="flex flex-col items-stretch gap-4">
            <div className="flex h-14 w-72 items-center justify-center rounded-lg bg-[#D9D9D9] px-3">
              <input
                type="text"
                placeholder="Buscar Proyectos..."
                className="w-full border-none bg-transparent outline-none"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img src={require("../../Images/Intra/search.png")} alt="search" />
            </div>
            <div className="rounded-lg bg-[#D9D9D9] p-4">
              <label className="text-[22px] mb-2 font-bold leading-[26px] text-[#828282]">
                Proveedores de:
              </label>
              <ul className="list-disc pl-7 flex items-start flex-col gap-3">
                <button onClick={() => setSortBy(sortBy => {
                  setAdditionalFilter("");
                  return sortBy === "all" ? null : "all";
                })}><li
                  className={`fontClass text-[16px] font-bold leading-[18px]  ${sortBy === 'all' ? "text-zinc-900" : "text-zinc-500"}`}
                >
                    Todos los proveedores
                  </li>
                </button>
                {requirementTypes.map((item, index) => (
                  <button onClick={() => setSortBy(sortBy => {
                    setAdditionalFilter("");
                    return sortBy === item ? null : item
                  })}><li
                    key={index}
                    className={`fontClass text-[16px] font-bold leading-[18px]  ${sortBy === item ? "text-zinc-900" : "text-zinc-500"}`}
                  >
                      {item}
                    </li></button>
                ))}
              </ul>
            </div>
            <div className="rounded-lg bg-[#D9D9D9] p-4">
              <label className="text-[22px] mb-2 font-bold leading-[26px] text-[#828282]">
                Tipos de Proyectos:
              </label>
              <ul className="list-disc pl-7 flex items-start flex-col gap-3">
                <button onClick={() => setByProjectType(byProjectType => {
                  setAdditionalFilter("");
                  return byProjectType === 'all' ? null : 'all'
                })}>
                  <li
                    className={`fontClass text-[16px] font-bold leading-[18px]  ${byProjectType === 'all' ? "text-zinc-900" : "text-zinc-500"}`}
                  >
                    Todos los Proyectos
                  </li>
                </button>
                {Object.keys(projectTypes).map((item, index) => (
                  <button onClick={() => setByProjectType(byProjectType => {
                    setAdditionalFilter("");
                    return byProjectType === item ? null : item
                  })}>
                    <li
                      key={index}
                      className={`fontClass text-[16px] font-bold leading-[18px]  ${byProjectType === item ? "text-zinc-900" : "text-zinc-500"}`}
                    >
                      {projectTypesMap[item]}
                    </li></button>
                ))}
              </ul>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between px-2 pb-2">
              <p className="text-xs font-medium text-zinc-500">
                Mostrando  {`${filteredProjects.length} resultado${filteredProjects.length === 1 ? "" : "s"}`}
              </p>
              <div className="flex gap-4">
                <select
                  name="sortBy"
                  className="rounded-lg bg-slate-100 px-2 py-1"
                  value={additionalFilter}
                  onChange={(e) => setAdditionalFilter(e.target.value)}
                >
                  <option value="" disabled>Filtros adicionales:</option>
                  <option value="all">Todos los proyectos</option>
                  <option value="recent">De más reciente a más antiguo</option>
                  <option value="oldest">De más antiguo a más reciente</option>
                  <option value="active">Licitaciones activas</option>
                  <option value="closed">Proyectos cerrados</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-3 gap-y-8 sm:grid-cols-2 sm:gap-x-4 lg:grid-cols-3 xl:gap-8 pl-4">
              {filteredProjects.length > 0 ? (
                filteredProjects.map((project) => (
                  <Card
                    key={project?._id}
                    project={{
                      _id: project?._id,
                      img: project?.files[0],
                      name: project?.title,
                      text: project?.typeOfRequirement,
                      deadline: project?.deadline,
                      openingDate: project?.openingDate,
                      closingDate: project?.closingDate,
                      icon: projectTypeToIconMap[project?.projectType]
                    }}
                  />
                ))
              ) : (
                <p>No se encontró ningún proyecto con esas características.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proyectos;
