import React from 'react'
import { useState } from "react"
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { userRoles, apiRoutes, toastTypes, userRolesMap } from '../../app.constants';
import axiosInstance from '../../services/axios';
import { showToast } from '../Shared/CustomToast';


const CreateUserForm = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const [formData, setFormData] = useState({
        role: '',
        fullname: '',
        surname: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        try {
            await axiosInstance.post(`${apiRoutes.user}`, formData);
            console.log('User created successfully');
            showToast(toastTypes.success, `El usuario ${formData.fullname} ${formData.surname} se creó exitosamente con el rol ${userRolesMap[formData.role]}.`)
            // Delay clearing the form data to ensure UI updates
            setTimeout(() => {
                setFormData({
                    role: '',
                    fullname: '',
                    surname: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                });
            }, 100);
        } catch (error) {
            console.log(error);
            showToast(toastTypes.error, 'No se pudo crear un nuevo usuario.');
        }
    };

    return (
        <form className="flex flex-col gap-3 min-w-[40%]" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-3">
                <label for="tipo-de-usuario" className="text-[#025624] font-semibold text-sm">Tipo de usuario:*</label>
                <select required name="role" value={formData.role} onChange={handleChange} id="tipo-de-usuario" className="bg-[#e5e7e6] p-3 font-bold text-sm">
                    <option value="">--Seleccione el tipo de usuario--</option>
                    <option value={userRoles.admin}>{userRolesMap[userRoles.admin]}</option>
                    <option value={userRoles.voter}>{userRolesMap[userRoles.voter]}</option>
                    <option value={userRoles.auditor}>{userRolesMap[userRoles.auditor]}</option>
                    <option value={userRoles.acquisitions}>{userRolesMap[userRoles.acquisitions]}</option>
                    <option value={userRoles.contralor}>{userRolesMap[userRoles.contralor]}</option>
                    <option value={userRoles.projectCreator}>{userRolesMap[userRoles.projectCreator]}</option>
                </select>
            </div>

            <div className="flex flex-col gap-3">
                <label for="fullname" className="text-[#025624] font-semibold text-sm">Nombre(s):*</label>
                <input required type="text" value={formData.fullname} onChange={handleChange} name="fullname" id="fullname" className="bg-[#e5e7e6] p-3 font-bold text-sm" />
            </div>

            <div className="flex flex-col gap-3">
                <label for="surname" className="text-[#025624] font-semibold text-sm">Apellidos:*</label>
                <input required type="text" value={formData.surname} onChange={handleChange} name="surname" id="surname" className="bg-[#e5e7e6] p-3 font-bold text-sm" />
            </div>

            <div className="flex flex-col gap-3">
                <label for="electronico" className="text-[#025624] font-semibold text-sm">Correo electrónico:*</label>
                <input required type="email" value={formData.email} onChange={handleChange} name="email" id="electronico" className="bg-[#e5e7e6] p-3 font-bold text-sm" />
            </div>

            <div className="flex flex-col gap-3">
                <label for="password" className="text-[#025624] font-semibold text-sm">Contraseña:*</label>
                <div className="bg-[#e5e7e6] p-3 font-bold text-sm flex items-center justify-between">
                    <input required className="w-full bg-[#e5e7e6]" type={showPassword ? "text" : "password"} value={formData.password} onChange={handleChange} name="password" id="password" />
                    <span className="cursor-pointer" onClick={() => setShowPassword((open) => !open)}>
                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                </div>
            </div>

            <div className="flex flex-col gap-3">
                <label for="confirmar-contrasena" className="text-[#025624] font-semibold text-sm">Confirmar Contraseña:*</label>
                <div className="bg-[#e5e7e6] p-3 font-bold text-sm flex items-center justify-between">
                    <input required className="w-full bg-[#e5e7e6]" type={showConfirmPassword ? "text" : "password"} value={formData.confirmPassword} onChange={handleChange} name="confirmPassword" id="confirmar-contrasena" />
                    <span className="cursor-pointer" onClick={() => setShowConfirmPassword((open) => !open)}>
                        {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                </div>
            </div>

            <button
                type="submit"
                className="mb-16 flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-green-700"
            >
                <img src={require("../../Assets/buttonIcon.png")} alt="" />
                <span className="font-russo text-lg">GUARDAR</span>
            </button>

        </form>
    )
}

export default CreateUserForm