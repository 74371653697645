import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Tooltip,
} from "@mui/material";
import axiosInstance from "../../services/axios";
import { apiRoutes, userRoles, generalWeightMatrix, itWeightMatrix, constructionWeightMatrix, projectTypes, projectTypesMap, toastTypes, isProjectActive, formatDateTo3CharMonth } from "../../app.constants";
import { useAuth } from "../../Hoc/AuthContext";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import Spinner from "../Shared/Spinner";
import { showToast } from "../Shared/CustomToast";
import { Assets } from "../../Assets";

const MatrizComparative = () => {
  const navigate = useNavigate()
  const [view, setView] = useState(2);
  const [loading, setLoading] = useState(false);
  const [biddings, setBiddings] = useState([]);
  const [error, setError] = useState(null);
  const [selectedBidding, setSelectedBidding] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [evaluationScore, setEvaluationScore] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [project, setProject] = useState(null);
  const { isAuthenticated, user } = useAuth();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('projectId');

  useEffect(() => {
    fetchBiddings();
  }, []);

  const fetchBiddings = async () => {
    try {
      setLoading(true)
      const { data } = await axiosInstance.get(`${apiRoutes.bidding.getAll}/${projectId}`);
      setBiddings(data?.biddings)

      const response = await axiosInstance.get(`${apiRoutes.project.getById}/${projectId}`);
      const projectData = response.data?.data?.project;
      setProject(projectData);
    } catch (error) {
      console.error("Error fetching biddings:", error.response ? error?.response?.data : error?.message);
      showToast(toastTypes.error, "Error fetching biddings.");
    } finally {
      setLoading(false);
    }
  };

  function formatDate(date) {
    const dateObject = new Date(date); // Convert string to Date object
    return dateObject.toLocaleDateString();
  }

  const handleEvaluate = async () => {
    const evalData = {
      bidding: selectedBidding._id,
      projectId,
      evaluationRating: evaluationScore,
    }

    try {
      const { data } = await axiosInstance.post(apiRoutes.bidding.evaluate, evalData);
      setOpenDialog(false);
      await fetchBiddings();
    } catch (error) {
      console.error("Error evaluating provider biddings:", error.response ? error?.response?.data : error?.message);
    }
  }

  const handleVoting = async () => {
    try {
      const { data } = await axiosInstance.patch(apiRoutes.declareWinner + `/${selectedBidding.user._id}`, null);
      setOpenConfirmDialog(false);
      await fetchBiddings();
    } catch (error) {
      console.error("Error evaluating provider biddings:", error.response ? error?.response?.data : error?.message);
    }
  }

  const criteriaTable = (title, rows, icon) => {
    return (
      <div className="mb-5">
        <div className="flex items-center justify-center gap-3 mb-4">
          <img
            src={icon}
            className="w-10"
            alt=""
          />
          <p className="text-2xl font-semibold text-[#585857]">{title}</p>
        </div>
        <table className="min-w-full rounded-md border border-gray-300 shadow-md">
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-700 sm:px-4">
                #
              </th>
              <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-700 sm:px-4">
                Criterios
              </th>
              <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-700 sm:px-4">
                Definición de Criterios
              </th>
              <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-700 sm:px-4">
                Ponderación
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                <td className="border border-gray-300 py-2 sm:px-4">
                  {row.id}
                </td>
                <td className="border border-gray-300 py-2 sm:px-4">
                  {row.criterio}
                </td>
                <td className="border border-gray-300 py-2 sm:px-4">
                  {row.definicion}
                </td>
                <td className="border border-gray-300 py-2 sm:px-4">
                  {row.ponderacion}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                className="border border-gray-300 px-1 py-2 sm:px-4"
                colSpan={2}
              ></td>
              <td className="border border-gray-300 px-1 py-2 font-semibold sm:px-4">
                TOTAL
              </td>
              <td className="border border-gray-300 px-1 py-2 font-semibold sm:px-4">
                100
              </td>
            </tr>
          </tfoot>

        </table>
      </div>

    )
  }

  const getSliderValues = () => {
    if (selectedBidding?.project?.projectType === projectTypes.general) {
      return { step: 20, max: 40 }
    }
    if (selectedBidding?.project?.projectType === projectTypes.IT) {
      return { step: 5, max: 25 }
    }
    return { step: 5, max: 10 }
  }
  const sliderValues = getSliderValues();

  const handleSliderChange = (value) => {
    setSliderValue(value);
    const numValue = Number(value);
    if (selectedBidding?.project?.projectType === projectTypes.general) {
      setEvaluationScore(numValue === 0 ? "0" : numValue === 50 ? '20' : '40');
    }
    if (selectedBidding?.project?.projectType === projectTypes.IT) {
      setEvaluationScore(numValue === 0 ? "0" : numValue === 50 ? '15' : '25');
    }
    if (selectedBidding?.project?.projectType === projectTypes.construction) {
      setEvaluationScore(numValue === 0 ? "0" : numValue === 50 ? '5' : '10');
    }
  };

  const mapEvaluationScoreToSliderValue = (value, projectType) => {
    setEvaluationScore(value);
    const numValue = Number(value);
    if (numValue) {
      if (projectType === projectTypes.general) {
        setSliderValue(numValue === 0 ? "0" : numValue === 20 ? '50' : '100');
      }
      if (projectType === projectTypes.IT) {
        setSliderValue(numValue === 0 ? "0" : numValue === 15 ? '50' : '100');
      }
      if (projectType === projectTypes.construction) {
        setSliderValue(numValue === 0 ? "0" : numValue === 5 ? '50' : '100');
      }
    }
  };

  return (loading ? <Spinner /> :
    <>
      <div className="flex flex-col items-center bg-[url('./Assets/lowerBg.png')] bg-top bg-no-repeat py-36 lg:min-h-[1200px]">

        {openConfirmDialog && <ConfirmationDialog setOpenConfirmDialog={setOpenConfirmDialog} onConfirmClick={handleVoting} />}
        {/* <!-- Evaluation Dialog --> */}
        {openDialog && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="min-w-[600px] flex flex-col items-center justify-center bg-white p-6 rounded-lg shadow-lg z-10">
              <img
                className="rounded-[10px] h-[100px] w-[100px]"
                src={`/${selectedBidding?.user?.step3?.logo}`}
                alt="Provider Image"
              />
              <h1 className="text-3xl text-[#4A4F54] font-roboto font-bold">{selectedBidding?.user?.step3?.companyName}</h1>
              <div className="flex gap-3 items-center justify-center">
                <img
                  src={require("../../Assets/project-grey.png")}
                  alt="Provider Image"
                />
                <h2 className="text-2xl text-[#4A4F54] font-roboto font-bold mt-2">{projectTypesMap[selectedBidding?.project?.projectType]}</h2>
              </div>
              <p className="my-4 text-[#025624] font-bold text-xl font-roboto">Evaluación</p>

              {/* <!-- Slider --> */}
              <div class="w-full mt-4 flex flex-col items-center justify-center">
                <input
                  type="range"
                  min={0}
                  step={50}
                  max={100}
                  value={sliderValue}
                  onChange={(e) => handleSliderChange(e.target.value)}
                  className={`w-[75%] appearance-none h-3 rounded-lg focus:outline-none focus:ring focus:ring-[#71A850]`}
                  style={{
                    background: `linear-gradient(to right, #71A850 ${sliderValue}%, #D9D9D9 ${sliderValue}%)`,
                    accentColor: '#025624'
                  }}
                />
                <div class="w-full px-3 flex items-baseline justify-between mt-2 text-gray-600 text-xs">
                  <span className="text-center w-32 break-words"><strong>0</strong><br />No Cumple con las especificaciones</span>
                  <span className="text-center w-32 break-words"><strong>{sliderValues.step}</strong><br />Cumple parcialmente con las especificaciones</span>
                  <span className="text-center w-32 break-words"><strong>{sliderValues.max}</strong><br />Cumple al 100% con las especificaciones</span>
                </div>
              </div>

              <div className="pt-5 pb-4 flex gap-2 justify-around">
                <button
                  className="btnBorder font-russo p-2 w-28 items-center justify-center gap-2 rounded-lg bg-[#D9D9D9] hover:bg-[#71A850] hover:text-white text-[10px] leading-[12px] text-[#4A4F54] lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]"
                  onClick={() => setOpenDialog(false)}
                >
                  CANCELAR
                </button>
                <button
                  className="btnBorder font-russo p-2 w-28 items-center justify-center gap-2 rounded-lg bg-[#025624] hover:bg-[#71A850] text-[10px] leading-[12px] text-white lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]"
                  onClick={() => handleEvaluate()}
                >
                  EVALUAR
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex max-w-[1328px] flex-col px-4 lg:w-[90%]">
          <div className="mb-5 px-28 flex w-full flex-wrap items-center justify-between gap-2 sm:flex-nowrap">
            <button
              onClick={() => setView(1)}
              className={`${view === 1 ? "bg-[#71A850]" : "bg-[#585857]"} btnBorder flex items-center justify-center gap-px rounded-lg px-1 py-3 text-xs leading-[18px] text-white sm:gap-2 sm:text-base md:px-4 lg:text-xl lg:leading-[24px]`}
            >
              <img
                src={require("../../Assets/btn1Image.png")}
                className="w-6 lg:w-10"
                alt=""
              />
              <span className="font-russo">TABLA DE RESULTADOS</span>
            </button>

            <button
              onClick={() => setView(2)}
              className={`${view === 2 ? "bg-[#71A850]" : "bg-[#585857]"} btnBorder flex items-center justify-center gap-px rounded-lg px-1 py-3 text-xs leading-[18px] text-white sm:gap-2 sm:text-base md:px-4 lg:text-xl lg:leading-[24px]`}
            >
              <img
                src={require("../../Assets/btn2Image.png")}
                className="w-6 lg:w-10"
                alt=""
              />
              <div className="font-russo">MATRIZ COMPARATIVA</div>
            </button>
            <button
              onClick={() => setView(3)}
              className={`${view === 3 ? "bg-[#71A850]" : "bg-[#585857]"} btnBorder flex items-center justify-center gap-px rounded-lg px-1 py-3 text-xs leading-[18px] text-white sm:gap-2 sm:text-base md:px-4 lg:text-xl lg:leading-[24px]`}
            >
              <img
                src={require("../../Assets/btn3Image.png")}
                className="w-4 lg:w-10"
                alt=""
              />
              <div className="font-russo">MATRIZ DE PONDERACIONES</div>
            </button>
          </div>
          <div className="flex w-full px-28 flex-wrap items-center justify-between gap-2">
            <div className="flex items-center gap-3">
              <button
                onClick={() => navigate(-1)}
                className={`btnBorder mb-4 ml-auto flex items-center justify-center gap-2 self-end rounded-lg bg-[#025624] hover:bg-[#70a950] roboto-slab text-[10px] leading-[12px] text-white lg:ml-0 lg:h-12 px-4 lg:text-[14px] lg:leading-[17px]`}>
                <FaArrowLeft />
                <span className="font-russo">ATRÁS</span>
              </button>
              {isAuthenticated && [userRoles.voter, userRoles.admin].includes(user.role) && user?._id === project?.voter && view === 1 &&
                <button disabled={!selectedBidding} className="btnBorder mb-4 ml-auto flex p-2 w-28 items-center justify-center gap-2 self-end rounded-lg bg-[#025624] hover:bg-[#70a950] roboto-slab text-[10px] leading-[12px] text-white lg:ml-0 lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]"
                  onClick={() => setOpenDialog(true)}>
                  <img
                    src={require("../../Assets/evaluate.png")}
                    alt=""
                  />
                  <span className="font-russo">EVALUAR</span>
                </button>
              }
            </div>

            {isAuthenticated && [userRoles.voter, userRoles.admin].includes(user.role) && user?._id === project?.voter && view === 1 &&
              <>
                {isProjectActive(selectedBidding?.project?.closingDate)
                  ?
                  <Tooltip placement="top" title={` La fecha para poder seleccionar un ganador de cierre de este proyecto es el: ${formatDateTo3CharMonth(selectedBidding?.project?.closingDate)}. Favor de volver en esta fecha para realizar la selección del ganador. `}>
                    <span>
                      <button
                        disabled={isProjectActive(selectedBidding?.project?.closingDate)}
                        onClick={() => setOpenConfirmDialog(true)}
                        className="btnBorder mb-4 ml-auto flex p-2 w-28 items-center justify-center gap-2 self-end rounded-lg bg-[#025624] hover:bg-[#70a950] roboto-slab text-[10px] leading-[12px] text-white lg:ml-0 lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]">
                        <img
                          src={require("../../Assets/votar.png")}
                          alt=""
                        />
                        <span className="font-russo">VOTAR</span>
                      </button>
                    </span>
                  </Tooltip>
                  :
                  <button
                    disabled={!selectedBidding}
                    onClick={() => setOpenConfirmDialog(true)}
                    className="btnBorder mb-4 ml-auto flex p-2 w-28 items-center justify-center gap-2 self-end rounded-lg bg-[#025624] hover:bg-[#70a950] roboto-slab text-[10px] leading-[12px] text-white lg:ml-0 lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]">
                    <img
                      src={require("../../Assets/votar.png")}
                      alt=""
                    />
                    <span className="font-russo">VOTAR</span>
                  </button>
                }
              </>
            }
          </div>
          <div className="flex flex-col items-center">
            <div className="flex items-center gap-[8px] lg:gap-[30px]">
              <img
                src={require("../../Assets/triangle.png")}
                // className="h-10 w-10 lg:h-16 lg:w-16"
                alt=""
              />
              <div className="text-[25px] font-bold font-roboto leading-[30px] text-[#71A850] lg:text-[32px] lg:leading-[47px]">
                {view === 1 && "Tabla de Resultados"}
                {view === 2 && "Matriz Comparativa"}
                {view === 3 && "Matriz De Ponderaciones"}
              </div>
            </div>
          </div>

          {view === 1 && (
            <div className="mx-auto mt-10 w-full max-w-[1000px] overflow-x-auto bg-white text-center text-xs sm:text-base">
              <table className="min-w-full rounded-lg border border-gray-300 shadow-lg">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                      #
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                      Avatar
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                      Nombre de Proveedor
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                      Cumplimiento de Especificaciones
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                      Calificación Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {biddings.map((bidding, index) => (
                    <tr key={bidding._id}>
                      <td className="border border-gray-600 py-1 px-1">
                        <Checkbox
                          sx={{
                            '&.Mui-checked': {
                              color: '#025624',
                            },
                          }}
                          checked={selectedBidding?._id === bidding._id}
                          onChange={() => {
                            setSelectedBidding(selectedBidding?._id === bidding._id ? null : bidding)
                            mapEvaluationScoreToSliderValue(bidding?.evaluationRating, bidding?.project?.projectType)
                          }}
                        />
                      </td>
                      <td className="border border-gray-600 py-1 px-2">
                        {index + 1}
                      </td>
                      <td className="border border-gray-600 py-1 sm:px-2">
                        <img
                          src={`/${bidding?.user?.step3?.logo}`}
                          alt={bidding?.user?.step3?.companyName}
                          className="mx-auto h-12 w-12 rounded-full"
                        />
                      </td>
                      <td className="border-black-600 border py-1 px-2">
                        <div className="flex gap-4 justify-center">
                          <span>{bidding?.user?.step3?.companyName}</span>
                          {bidding?.user?.isWinner && <img
                            className="w-[30px]"
                            src={Assets.WinnerBadgeLogo}
                            alt="win" />}
                        </div>
                      </td>
                      <td className="border-black-600 border py-1 sm:px-2">
                        {bidding?.evaluationRating}
                      </td>
                      <td className="border border-gray-300 py-1 sm:px-2">
                        {bidding?.totalRating}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {view === 3 && (
            <div className="mx-auto mt-10 max-w-[1000px] overflow-x-auto bg-white text-center text-xs sm:text-base">
              {criteriaTable('Proyectos Generales', generalWeightMatrix, Assets.LogoProjectGeneral)}
              {criteriaTable('Proyectos de Construcción', constructionWeightMatrix, Assets.LogoProjectConstruction)}
              {criteriaTable('Proyectos de Tecnologías', itWeightMatrix, Assets.LogoProjectIT)}
            </div>
          )}

          {view === 2 && (
            <div className="tableouter mt-[30px] flex flex-col">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className="bg-gray-200">
                    <TableRow>
                      <TableCell className="text-center">#</TableCell>
                      <TableCell className="text-center">Nombre de Proveedor</TableCell>
                      <TableCell className="text-center">Licitación</TableCell>
                      <TableCell className="text-center">Cantidad</TableCell>
                      <TableCell className="text-center">Precio Unitario</TableCell>
                      <TableCell className="text-center">Precio Total</TableCell>
                      <TableCell className="text-center">Sitio web</TableCell>
                      <TableCell className="text-center">Especificaciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {biddings.map((bidding, index) => (
                      <TableRow key={index}>
                        <TableCell className="border border-gray-600 px-2 text-center">{index + 1}</TableCell> {/* Numbering */}
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding?.user?.step3?.companyName}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding?.project?.title}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding?.amount} {bidding.unit}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">${bidding?.unitPrice}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">${bidding?.totalPrice}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">
                          <a
                            href={bidding?.user?.step4?.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {bidding?.user?.step4?.website || 'NA'}
                          </a>
                        </TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding.charachteristics}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper} className="mt-[40px]">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className="bg-gray-200">
                    <TableRow>
                      <TableCell className="text-center">#</TableCell>
                      <TableCell className="text-center">Nombre de Proveedor</TableCell>
                      <TableCell className="text-center">Especificaciones Especiales </TableCell>
                      <TableCell className="text-center">Garantías</TableCell>
                      <TableCell className="text-center">Soporte</TableCell>
                      <TableCell className="text-center">Fecha de entrega</TableCell>
                      <TableCell className="text-center">Lugar de entrega</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {biddings.map((bidding, index) => (
                      <TableRow key={index}>
                        <TableCell className="border border-gray-600 px-2 text-center">{index + 1}</TableCell> {/* Numbering */}
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding?.user?.step3?.companyName || 'NA'}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding?.specialSpecifications}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding?.isWarranty ? bidding?.warranties : 'No'}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding?.isSupportProvided ? bidding?.supportDescription : 'No'}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding?.dateOfDelivery}</TableCell>
                        <TableCell className="border border-gray-600 px-2 text-center">{bidding?.deliveryLocation}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MatrizComparative;
