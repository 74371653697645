import React, { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import axiosInstance from "../services/axios";
import { apiRoutes, generalError, isProjectActive, projectTypes, projectTypesMap, projectTypeToIconMap, toastTypes } from "../app.constants";
import { showToast } from "./Shared/CustomToast";
import Spinner from "./Shared/Spinner";
import Card from "./Proyectos/Card";
import { Assets } from "../Assets";

const marks = [
  {
    value: 0,
    label: '0',
  },

  {
    value: 100,
    label: '100',
  },
];

function valuetext(value) {
  return `${value}°C`;
}

const FinancePage = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [provider, setProvider] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let userId = queryParams.get('id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get(`${apiRoutes.bidding.allByUser}/${userId}`);
        const { user, projects } = data?.data;

        const { companyName, fullName, surname, tradeName, legal, origin, rfc, serviceProvided, logo, headerImage, supplierOf } = user?.step3;
        const { address, isWinner, email, intCode, phone, website } = user;
        const { calle, numExt, numInternational, colonia, municipality, state, country, postalCode } = address;
        const userData = {
          _id: user._id,
          companyName,
          logo,
          bg: headerImage,
          supplierOf,
          address: `${address?.municipality}, ${address?.state}`,
          isWinner,
          fullName, surname, tradeName,
          legal, rfc,
          serviceProvided,
          email, origin,
          intCode, phone, website,
          calle, numExt, numInternational, colonia, municipality, state, country, postalCode
        }
        setProvider(userData);
        setProjects(projects)
      } catch (error) {
        showToast(toastTypes.error, generalError);
        console.error('Error fetching biddings:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredProjects = projects
    .filter(project => project?.title?.toLowerCase().includes(searchQuery.toLowerCase()))
    .filter(project => (typeFilter ? project?.projectType?.toLowerCase() === typeFilter.toLowerCase() : true))
    .filter(project => statusFilter ? isProjectActive(project?.closingDate) : true);

  return (loading ? <Spinner /> :
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="flex flex-col w-full">
        <div
          className="relative w-full flex flex-col"
        >
          <div className="relative w-full">
            <img src={`/${provider?.bg}`} className="w-full h-[400px] sm:h-[420px] 2xl:h-[485px] bg-cover object-cover" alt="" />
            <div style={{
              position: 'absolute',
              inset: 0,
              backgroundColor: 'black',
              opacity: 0.3,
              borderRadius: '0.75rem'
            }}></div>
          </div>
          <div className="absolute w-full flex flex-col h-[400px] sm:h-[420px] 2xl:h-[485px] pb-[20px] sm:pb-[70px]">
            {provider?.isWinner && <div className="flex justify-end pt-10 pr-[10%]">
              <div className="bg-white shadow-md p-2">
                <img
                  src={Assets.WinnerBadgeLogo}
                  className="w-[70px] sm:w-[90px] 2xl:w-[105px]"
                  alt=""
                />
              </div>
            </div>}
            <div className="flex items-end gap-[15px] h-full sm:gap-[20px] pl-[10%] w-full">
              <img
                src={`/${provider?.logo}`}
                className="w-[70px] sm:w-[90px] 2xl:w-[105px]"
                alt=""
              />
              <div className="flex flex-col ">
                <div className="text-[20px] leading-[25px] sm:text-[27px]  2xl:text-[32px] sm:leading-[42px] font-[700] text-[#FFFFFF]">
                  {provider?.companyName}
                </div>
                <div className="flex gap-[8px] mt-[6px] sm:mt-[8px] items-end">
                  <img
                    src={require("../Assets/location.png")}
                    className="w-[16px] sm:w-[20px] 2xl:w-[24px]"
                    alt=""
                  />
                  <div className="text-[16px] sm:text-[20px] leading-[23px] font-[400] text-[#FFFFFF]">
                    {provider?.address}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center ">
          <div className="flex w-[70%] items-start p-8 border-[#EDEEEE] shadow-md text-[#4A4F54] rounded-b-lg">

            <div className="flex w-full justify-center gap-2 items-start flex-col ">
              <div className="mb-2">
                <h2 className="font-bold text-xl">Información personal</h2>
                <hr />
              </div>
              <div className="flex w-full justify-start">
                <span className="w-[30%] font-bold">Nombre:</span>
                <span>{provider?.fullName} {provider?.surname}</span>
              </div>
              <div className="flex w-full justify-start">
                <span className="w-[50%] font-bold">Correo electrónico:</span>
                <span>{provider?.email}</span>
              </div>
              <div className="flex w-full justify-start">
                <span className="w-[30%] font-bold">Teléfono:</span>
                <span >{provider?.intCode} {provider?.phone}</span>
              </div>
              <div className="flex w-full justify-start">
                <span className="w-[30%] font-bold">Origen:</span>
                <span >{provider?.origin}</span>
              </div>
            </div>

            <div className="flex w-full gap-2 items-start flex-col ">
              <div className="mb-2">
                <h2 className="font-bold text-xl">Información comercial</h2>
                <hr />
              </div>
              <div className="flex w-full justify-start">
                <span className="w-[50%] font-bold">Nombre Comercial:</span>
                <span>{provider?.tradeName}</span>
              </div>
              <div className="flex w-full justify-start">
                <span className="w-[50%] font-bold">Proveedor de:</span>
                <span >{provider?.supplierOf}</span>
              </div>
              <div className="flex flex-col w-full justify-start">
                <span className="font-bold">Artículo(s) o servicio(s) que provee:</span>
                <span>{provider?.serviceProvided}</span>
              </div>
              <div className="flex w-full justify-start">
                <span className="w-[30%] font-bold">RFC:</span>
                <span >{provider?.rfc}</span>
              </div>
              <div className="flex w-full justify-start">
                <span className="w-[30%] font-bold">Sitio web:</span>
                <span >{provider?.website}</span>
              </div>
            </div>

            <div className="flex w-full gap-2 items-start flex-col ">
              <div className="mb-2">
                <h2 className="font-bold text-xl">Dirección</h2>
                <hr />
              </div>

              <div className="flex w-full gap-3 justify-start">
                <div className="w-[50%] flex gap-2 justify-start">
                  <span className="font-bold">Calle:</span>
                  <span>{provider?.calle}</span>
                </div>
                <div className="flex gap-2 justify-start">
                  <span className="font-bold">Núm. Ext:</span>
                  <span>{provider?.numExt}</span>
                </div>
              </div>
              <div className="flex w-full gap-3 justify-start">
                <div className="w-[50%] flex gap-2 justify-start">
                  <span className="font-bold">Núm. Int:</span>
                  <span >{provider?.numInternational}</span>
                </div>
                <div className="flex gap-2 justify-start">
                  <span className="font-bold">Colonia:</span>
                  <span >{provider?.colonia}</span>
                </div>
              </div>
              <div className="flex w-full gap-3 justify-start">
                <div className="w-[50%] flex gap-2 justify-start">
                  <span className="font-bold">Municipio:</span>
                  <span >{provider?.municipality}</span>
                </div>
                <div className="flex gap-2 justify-start">
                  <span className="font-bold">Estado:</span>
                  <span >{provider?.state}</span>
                </div>
              </div>

              <div className="flex w-full gap-3 justify-start">
                <div className="w-[50%] flex gap-2 justify-start">
                  <span className="font-bold">País:</span>
                  <span >{provider?.country}</span>
                </div>
                <div className="flex gap-2 justify-start">
                  <span className="font-bold">Código Postal:</span>
                  <span >{provider?.postalCode}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full items-center justify-center gap-[20px] sm:gap-[45px] mt-[20px] sm:mt-[30px]">
          <img
            src={require("../Assets/triangle.png")}
            className="w-[30px] sm:w-[41px]"
            alt=""
          />
          <div className="text-[18px] sm:text-[24px] leading-[25px] sm:leading-[31px] font-[700] text-[#71A850]">
            Licitaciones en las que participa
          </div>
        </div>
        <div className="w-full min-h-screen flex relative  mb-[70px]">
          <img
            src={require("../Assets/lowerBg.png")}
            className="absolute w-full h-full top-0 z-1"
            alt=""
          />
          <div className="w-full gap-3 relative px-[10%] flex-col flex lg:flex-row lg:justify-between pt-[30px] z-2">
            <div className="flex flex-col items-center sm:items-start">
              <div className="w-full flex justify-between items-start">
                <div>
                  <div className="w-[304px] z-10 h-[32px] inputborder rounded-[8px] pr-[30px] pl-[5px] relative   border-[1px] border-[#C7C7CD]  bg-white">
                    <input
                      type="text"
                      placeholder="Buscar Licitación..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="h-full w-full border-none bg-none pl-[10px] outline-none"
                    />
                    <IoMdSearch className="text-[20px] absolute right-[4px] top-[3px] text-black" />
                  </div>
                  <div className="w-[304px] z-10 h-[32px] mt-3 inputborder rounded-[8px] pr-[10px] pl-[5px] relative   border-[1px] border-[#C7C7CD]  bg-white">
                    <select
                      className="h-full w-full border-none bg-none pl-[5px] outline-none"
                      value={typeFilter}
                      onChange={(e) => setTypeFilter(e.target.value)}
                    >
                      <option selected value="">Tipos de Proyectos:</option>
                      {Object.keys(projectTypes).map((type, index) => (<option key={index} value={type}>{projectTypesMap[type]}</option>))}
                    </select>
                  </div>
                </div>
                <select
                  className="w-[174px] h-[40px] selectBorder bg-[#EDEDEF] font-medium text-[10px] text-[#828282]"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="">Todas</option>
                  <option value="active">Abiertas</option>
                  <option value="closed">Cerradas</option>
                </select>
              </div>

              <div className="mt-11">
                <div className="grid grid-cols-1 gap-x-3 gap-y-8 sm:grid-cols-2 sm:gap-x-4 lg:grid-cols-3">
                  {filteredProjects.length > 0 ? (
                    filteredProjects.map((project) => (
                      <Card
                        key={project?._id}
                        project={{
                          _id: project?._id,
                          img: project?.files[0],
                          name: project?.title,
                          text: project?.typeOfRequirement,
                          deadline: project?.deadline,
                          openingDate: project?.openingDate,
                          closingDate: project?.closingDate,
                          icon: projectTypeToIconMap[project?.projectType]
                        }}
                      />
                    ))
                  ) : (
                    <p>El proveedor aún no ha participado en ningún proyecto.</p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center sm:items-start sm:flex-row gap-[20px] sm:gap-[40px] mt-[30px] lg:mt-0">
              {/* <Form.Select className="w-[174px] h-[40px] selectBorder bg-[#EDEDEF] font-medium text-[10px] text-[#828282]">
                <option>Todas</option>
                <option>Abiertas</option>
                <option>Cerradas</option>
              </Form.Select> */}
              <div className="w-[240px] h-fit bg-[#EDEEEE] boxShadowClass flex items-center flex-col rounded-[12px] pt-[20px]">
                <div className="text-[20px] sm:text-[24px] leading-[25px] sm:leading-[31px] font-bold text-center text-[#4A4F54]">
                  Contactar a Proveedor
                </div>
                <input
                  type="text"
                  className="w-[182px] mx-auto h-[29px] pl-[10px] mt-[20px] bg-[white] outline-none text-[12px] leading-[14px] text-[#BBBABA] inputborder"
                  placeholder="Nombre:"
                />
                <input
                  type="email"
                  className="w-[182px] mx-auto h-[29px] pl-[10px] mt-[20px] bg-[white] outline-none text-[12px] leading-[14px] text-[#BBBABA] inputborder"
                  placeholder="Email:"
                />
                <select
                  type="text"
                  className="w-[182px] mx-auto h-[29px] pl-[10px] mt-[20px] bg-[white] outline-none text-[12px] leading-[14px] text-[#BBBABA] inputborder"
                  placeholder="Proyecto:"
                >
                  <option value="">Proyecto:</option>
                  <option value="">Option2</option>
                  <option value="">Option3</option>
                </select>
                <textarea
                  placeholder="Mensaje:"
                  className="w-[182px] mx-auto h-[130px] pt-[10px] pl-[10px] mt-[20px] bg-[white] outline-none text-[12px] leading-[14px] text-[#BBBABA] inputborder"
                ></textarea>
                <button className="w-[130px] mt-[10px] mb-[10px] h-[40px] flex items-center justify-center gap-[10px] text-[14px] leading-[16px] text-white btnBorder bg-[#025624] rounded-[9px] mx-auto">
                  <div className="flex items-center">
                    <img src={require("../Assets/btnArrow.png")} alt="" />
                    <img src={require("../Assets/btnTriangle.png")} alt="" />
                  </div>
                  ENVIAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default FinancePage;

function MyVerticallyCenteredModal(props) {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-none">
        <Modal.Title id="contained-modal-title-vcenter" className="w-full">
          {/* <div className="text-[25px] sm:text-[36px] text-center leading-[47px] font-bold text-[#71A850]">
            {" "}
            ¡FELICIDADES!
          </div> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col items-center">
          <img
            src={require("../Assets/chief2.png")}
            className="w-[80px] sm:w-[109px] h-[80px] rounded-xl sm:h-[109px]"
            alt=""
          />
          <div className="text-[25px] sm:text-[32px] leading-[30px] sm:leading-[42px] font-bold text-[#4A4F54] mt-[10px]">
            Bocadillos y Canapes
          </div>
          <div className="text-[25px] sm:text-[32px] leading-[30px] sm:leading-[42px] font-bold text-[#71A850] mt-[10px] flex justify-start items-start ">
            Calificación
          </div>
          <br />
          <Box sx={{
            width: {
              xs: '90%', // Full width on extra-small screens
              sm: '80%',    // 300px width on small screens
              md: '70%',    // 400px width on medium screens
              lg: '75%',    // 500px width on large screens
            },
          }}
          >
            <Slider
              aria-label="Always visible"
              defaultValue={78}
              getAriaValueText={valuetext}
              step={1}
              marks={marks}
              valueLabelDisplay="on"
              sx={{
                color: '#71A850', // Change the slider color
                '& .MuiSlider-thumb': {
                  backgroundColor: '#71A850', // Change the thumb color
                },
                '& .MuiSlider-track': {
                  backgroundColor: '#71A850', // Change the track color
                },
                '& .MuiSlider-rail': {
                  backgroundColor: '#D3D3D3', // Change the rail color (optional)
                },
              }}
            />
          </Box>
          <button
            className="w-[130px] mt-[10px] mb-[10px] h-[40px] flex items-center justify-center gap-[10px] text-[14px] leading-[16px] text-white font-russo btnBorder bg-[#025624] rounded-[9px] mx-auto"
          >
            <img src={require("../Assets/votorIcon.png")} alt="" />
            VOTAR
          </button>
          {/* <div className="mt-[30px] text-[20px] sm:text-[24px] leading-[24px] sm:leading-[28px] mb-[70px]">
            ¡Haz resultado ganador de la licitación de:{" "}
            <span className="text-[#71A850]">Canapés Gourmet!</span>{" "}
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
