import React from 'react'

const EmailDetailsDialog = ({ selectedEmail, setOpenDialog }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-xl p-6 max-w-2xl w-full mx-4">
                <div className="flex justify-between items-center mb-4 border-b pb-3">
                    <h3 className="text-xl font-roboto font-bold text-green-800">Detalles del Correo</h3>
                    <button
                        onClick={() => setOpenDialog(false)}
                        className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="space-y-4 mb-6">
                    <div className="flex border-b pb-2">
                        <span className="font-medium w-24">De:</span>
                        <span>{selectedEmail.from || '-'}</span>
                    </div>
                    <div className="flex border-b pb-2">
                        <span className="font-medium w-24">Para:</span>
                        <span>{selectedEmail.to || '-'}</span>
                    </div>
                    {selectedEmail.cc && (
                        <div className="flex border-b pb-2">
                            <span className="font-medium w-24">CC:</span>
                            <span>{selectedEmail.cc}</span>
                        </div>
                    )}
                    {selectedEmail.cco && (
                        <div className="flex border-b pb-2">
                            <span className="font-medium w-24">CCO:</span>
                            <span>{selectedEmail.cco}</span>
                        </div>
                    )}
                    <div className="flex border-b pb-2">
                        <span className="font-medium w-24">Asunto:</span>
                        <span>{selectedEmail.subject || '-'}</span>
                    </div>
                    {selectedEmail.date && selectedEmail.time &&
                        <div className="flex border-b pb-2">
                            <span className="font-medium w-24">Fecha:</span>
                            <span>{`(${selectedEmail.date}) ${selectedEmail.time}`}</span>
                        </div>}
                    <div className="flex flex-col">
                        {/* <span className="font-medium mb-2">Cuerpo:</span> */}
                        <div className="bg-gray-50 p-4 rounded whitespace-pre-line">{selectedEmail.body}</div>
                    </div>
                </div>

                <div className="flex justify-end">
                    <button
                        onClick={() => setOpenDialog(false)}
                        className="px-4 py-2 font-russo hover:bg-[#71A850] hover:text-white bg-gray-200 text-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition-colors"
                    >
                        CERRAR
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EmailDetailsDialog