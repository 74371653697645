import React from 'react'

const InfoDialog = ({ setOpenDialog, heading, message }) => {

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="min-w-[600px] max-w-[600px] flex flex-col items-center justify-center bg-white p-3 rounded-lg shadow-lg z-10">
                <h2 className="text-xl text-[#4A4F54] font-roboto font-bold mt-2">
                    {heading}
                </h2>
                <p className="py-2 text-[#025624] text-lg text-center font-roboto">
                    {message}
                </p>
                <div className="pt-3 pb-4 flex gap-2 justify-around">
                    <button
                        className="btnBorder p-2 w-28 items-center justify-center gap-2 rounded-lg bg-[#025624] hover:bg-[#71A850] roboto-slab text-[10px] leading-[12px] text-white lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]"
                        onClick={() => setOpenDialog(false)}
                    >
                        ACEPTAR
                    </button>
                </div>
            </div>
        </div>
    )
}

export default InfoDialog