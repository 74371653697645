import React, { useEffect, useState } from 'react';
import { apiRoutes, toastTypes, regex, generalError } from '../../../app.constants';
import axiosInstance from '../../../services/axios';
import { showToast } from '../../Shared/CustomToast';
import { Assets } from '../../../Assets';
import FileUpIcn from "../../../Images/fileupIcn.png";
import styles from "../../RegisterDoc/RegisterDoc.module.css";

const EmailFormDialog = ({ selectedEmail, setOpenDialog }) => {

    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState('');
    const [errors, setErrors] = useState({
        to: '',
    });

    const [formData, setFormData] = useState({
        to: '',
        cc: '',
        cco: '',
        subject: '',
        body: '',
        signature: null,
    });

    useEffect(() => {
        const cleaned = selectedEmail.body.replace(/\t+/g, "");
        setFormData(prev => ({
            ...prev,
            subject: selectedEmail.subject,
            body: cleaned

        }));
    }, [selectedEmail]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        const formErrors = {
            ...errors,
            [name]: !value && (name === 'to' || name === 'body') ? 'Por favor, rellene este campo.' : ''
        };
        if (value && ['to', 'cc', 'cco'].includes(name) && !regex.email.test(value)) {
            formErrors[name] = 'Favor de ingresar un correo electrónico válido.'
        }

        setErrors(formErrors);
    };

    const clearForm = () => {
        setFormData({
            to: '',
            cc: '',
            cco: '',
            subject: '',
            body: '',
            signature: null,
        });
        setFileName('');
        setFileData(null);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formDataNew = new FormData();

        Object.entries(formData).forEach(([key, val]) => {
            formDataNew.append(key, val);
        })
        if (fileData) {
            formDataNew.append("signature", fileData);
        }

        try {
            await axiosInstance.post(`${apiRoutes.email.create}`, formDataNew);
            showToast(toastTypes.success, `El correo fue enviado con éxito.`);
            setOpenDialog(false);
            // Delay clearing the form data to ensure UI updates
            setTimeout(() => {
                clearForm();
            }, 100);
        } catch (error) {
            console.log(error);
            showToast(toastTypes.error, generalError);
        }
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileData(file);
            setFileName(file.name);
        } else {
            setFileName("No se ha elegido ningún archivo");
        }
    };

    const validateForm = () => {
        const formErrors = {};
        if (!formData.to) {
            formErrors.to = 'Por favor, rellene este campo.'
        } else if (!regex.email.test(formData.to)) {
            formErrors.to = 'Favor de ingresar un correo electrónico válido.'
        }

        if (formData.cc && !regex.email.test(formData.cc)) {
            formErrors.cc = 'Favor de ingresar un correo electrónico válido.'
        }
        if (formData.cco && !regex.email.test(formData.cco)) {
            formErrors.cco = 'Favor de ingresar un correo electrónico válido.'
        }

        if (!formData.body) {
            formErrors.body = 'Por favor, rellene este campo.'
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-xl p-6 max-w-2xl w-full mx-4">
                <div className="flex justify-between items-center mb-4 border-b pb-3">
                    <h3 className="text-xl font-roboto font-bold text-green-800">Envío de correos</h3>
                    <button
                        onClick={() => setOpenDialog(false)}
                        className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <form className="flex flex-col gap-3 min-w-[40%]">
                    <div className="flex flex-col gap-2">
                        <label for="to" className="text-[#025624] font-semibold text-sm">Para:*</label>
                        <input required type="email" value={formData.to} onChange={handleChange} name="to" id="to" className="bg-[#e5e7e6] p-2 font-bold text-sm" />
                        {errors.to && <span className="text-red-600 text-sm">{errors.to}</span>}
                    </div>

                    <div className="flex flex-col gap-2">
                        <label for="cc" className="text-[#025624] font-semibold text-sm">CC:</label>
                        <input type="email" value={formData.cc} onChange={handleChange} name="cc" id="cc" className="bg-[#e5e7e6] p-2 font-bold text-sm" />
                        {errors.cc && <span className="text-red-600 text-sm">{errors.cc}</span>}
                    </div>

                    <div className="flex flex-col gap-2">
                        <label for="cco" className="text-[#025624] font-semibold text-sm">CCO:</label>
                        <input type="email" value={formData.cco} onChange={handleChange} name="cco" id="cco" className="bg-[#e5e7e6] p-2 font-bold text-sm" />
                        {errors.cco && <span className="text-red-600 text-sm">{errors.cco}</span>}
                    </div>

                    <div className="flex flex-col gap-2">
                        <label for="subject" className="text-[#025624] font-semibold text-sm">Asunto:</label>
                        <input type="text" value={formData.subject} onChange={handleChange} name="subject" id="subject" className="bg-[#e5e7e6] p-2 font-bold text-sm" />
                    </div>

                    <div className="flex flex-col gap-2">
                        <label for="body" className="text-[#025624] font-semibold text-sm">Mensaje:*</label>
                        <textarea type="text" value={formData.body} onChange={handleChange} name="body" id="body" className="bg-[#e5e7e6] min-h-44 p-3 font-bold text-sm whitespace-pre-line" />
                        {errors.body && <span className="text-red-600">{errors.body}</span>}
                    </div>

                    <div className="flex flex-col gap-3">
                        <label for="signature" className="text-[#025624] font-semibold text-sm">Firma de Correo Electrónica:</label>
                        <div className="flex w-full">
                            <input
                                type="file"
                                id="signature"
                                name='signature'
                                accept=".jpeg,.jpg,.png"
                                onChange={(e) => handleFileChange(e)}
                                style={{ display: "none" }}
                            />
                            <span className={`p-2 flex-1 font-roboto text-sm text-left text-[#4a4f54] h-9 bg-[#e5e7e6]`}>{fileName}</span>
                            <label htmlFor="signature" className={styles.customFileUpload}>
                                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                                ANEXAR
                            </label>
                        </div>
                    </div>


                    <div className="flex w-full gap-2 justify-center">
                        <button
                            onClick={() => setOpenDialog(false)}
                            className="px-3 py-2 font-russo flex self-center items-center justify-center hover:bg-[#71A850] hover:text-white bg-gray-200 text-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition-colors"
                        >
                            <span className="font-russo text-lg">CERRAR</span>
                        </button>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-[#71A850]"
                        >
                            <img src={Assets.ButtonIcon} alt="" />
                            <span className="font-russo text-lg">ENVIAR</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EmailFormDialog