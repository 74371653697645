import React, { useEffect, useState } from "react";
import styles from "./RegisterDoc.module.css";
import RegIcn from "../../Images/RegIcn.png";
import RegDocStep from "../../Images/regDocStepper.png";
import RegDocBtnIcn from "../../Images/regdocbtnIcn.png";
import FileUpIcn from "../../Images/fileupIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import { useNavigate } from "react-router-dom";
import { apiRoutes, generalError, providerDocuments, providerDocumentTypes, signupError, toastTypes } from "../../app.constants";
import axiosInstance from "../../services/axios";
import { showToast } from "../Shared/CustomToast";
import { useAuth } from "../../Hoc/AuthContext";
const RegisterDoc = () => {
  const navigate = useNavigate();

  const { setUser } = useAuth();

  const [fileNames, setFileNames] = useState({
    file01: "FO-ADQ-007 Evaluación de proveedor.*",
    file22: "Comprobante de domicilio.*",
    file02: "Carta de confidencialidad firmada.*",
    file03: "Acta constitutiva.",
    file04: "Poder Legal del Representante.",
    file05: "Aviso de privacidad firmado.*",
    file06: "Firma de aceptación de política anti soborno.*",
    file07: "Copia de identificación vigente de persona física.*",
    file08: "Opinión de cumplimiento positiva por el SAT.*",
    file09: "Constancia de situación fiscal emitida por el SAT.*",
    file10: "Identificación oficial del proveedor o representante.*",
    file11: "Permiso vigente de Seguridad Pública del Estado.",
  });

  const [errors, setErrors] = useState({
    file01: "",
    file22: "",
    file02: "",
    file03: "",
    file04: "",
    file05: "",
    file06: "",
    file07: "",
    file08: "",
    file09: "",
    file10: "",
    file11: "",
  });

  const fileKeysMap = {};
  Object.entries(providerDocumentTypes).map(([key, val]) => {
    fileKeysMap[val] = key;
  });

  useEffect(() => {
    // Fetch all user files
    const getCurrentUser = async () => {
      try {
        const { data } = await axiosInstance.get(apiRoutes.currentUser);
        const { filesStep2 } = data.user;
        if (filesStep2) {
          filesStep2.forEach(file => {
            setFileNames(prevState => ({
              ...prevState,
              [fileKeysMap[file.documentType]]: file.originalname,
            }));
          })
        }
      } catch (error) {
        console.error('Error fetching current user: ', error);
      }
    }

    getCurrentUser();
  }, []);

  const uploadFile = async (file, fileKey) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", providerDocumentTypes[fileKey]);
    try {
      const response = await axiosInstance.post(
        apiRoutes.auth.uploadDoc,
        formData
      );
      showToast(toastTypes.info, `El documento '${providerDocuments[fileKey]}' se cargó correctamente.`)
    } catch (error) {
      showToast(toastTypes.error, 'Error al cargar el documento, por favor intente nuevamente.');
      console.error(error);
    }
  }

  const handleFileChange = (event, fileKey) => {
    const file = event.target.files[0];
    if (file) {
      setFileNames(prevState => ({
        ...prevState,
        [fileKey]: file.name,
      }));
      setErrors(prevState => ({
        ...prevState,
        [fileKey]: "", // Reset error message if file is selected
      }));

      // Make API call to upload file
      uploadFile(file, fileKey);
    };
  }

  const handleSubmit = async () => {
    let validationErrors = {};
    // Check for required files (those with an asterisk) and add errors if not selected
    Object.keys(fileNames).forEach(key => {
      if (fileNames[key].includes("*") && fileNames[key] === providerDocuments[key]) {
        validationErrors[key] = "Está pendiente por anexar la documentación respectiva que pertenece a esta casilla.";
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set errors state to display below the file inputs
      return;
    }

    try {
      const response = await axiosInstance.patch(
        apiRoutes.auth.signupStep2,
        { completedStep: 2 }
      );
      setUser(response.data.user);
      navigate("/system/sup-register");
    } catch (error) {
      showToast(toastTypes.error, generalError);
      console.error(error);
    }

  };

  return (
    <div>
      {/* <Header /> */}
      <div className={styles.regDocMain}>
        <div className={styles.regDocContainer}>
          <div className={styles.regDocContainer2}>
            <div className={styles.registerMainHeading}>
              <img src={RegIcn} alt="" className={styles.regIcn} />
              Documentación para Alta de Proveedor
            </div>

            <div className={styles.regStepMain}>
              <img src={RegDocStep} alt="" className={styles.regDocStep} />
            </div>

            {/* <div className={styles.regDocmainText}>
              "En este botón podrás descargar toda la documentación necesaria
              para firmar. Esta documentación la deberás de llenar con tinta
              azul a mano y subirla en esta página".La Carta de Confidencialidad y el Aviso de Privacidad, requieren firma al calce de ambas hojas.
            </div> */}

            <div className={styles.RegBtnIcnMain}>
              <a href="/Test.zip" download>
                <button className={styles.RegBtnIcn}>
                  <img
                    src={RegDocBtnIcn}
                    alt=""
                    className={styles.RegDocBtnIcn}
                  />
                  DESCARGAR
                </button>
              </a>
            </div>

            <div className={styles.regDocmainText2}>
              En este botón podrás descargar toda la documentación necesaria para firmar. Esta documentación la deberás de llenar con tinta azul a mano y subirla en esta página. Favor de ingresar cada pdf con máximo 1 MB en caso de mayor dimensión comprimirlo.*
            </div>

            {/* File Uploads */}
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload1"
                accept="application/pdf" // Restrict to PDF files only
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file01")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file01}</span>
              <label htmlFor="fileUpload1" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>

            </div>
            {errors.file01 && <div className={styles.errorMessage}>{errors.file01}</div>}

            <div className={styles.regDocmainText2}>
              Favor de ingresar aquí el comprobante de domicilio con vigencia no mayor a 3 meses:*
            </div>
            {/* File Uploads 22*/}
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload22"
                accept="application/pdf" // Restrict to PDF files only
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file22")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file22}</span>
              <label htmlFor="fileUpload22" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>

            </div>
            {errors.file22 && <div className={styles.errorMessage}>{errors.file22}</div>}

            <div className={styles.regDocmainText2}>
              Para el caso de personas morales deberá ser firmada por el
              representante legal y deberá anexar la documentación que acredite
              la identidad y copia del poder notarial para actuar en
              representación.
            </div>
            <div className={styles.regDocmainText2}>
              La Carta de Confidencialidad y el Aviso de Privacidad, requieren firma al calce de ambas páginas.
            </div>
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload2"
                accept="application/pdf"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file02")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file02}</span>
              <label htmlFor="fileUpload2" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.file02 && <div className={styles.errorMessage}>{errors.file02}</div>}

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload3"
                accept="application/pdf"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file03")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file03}</span>
              <label htmlFor="fileUpload3" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.file03 && <div className={styles.errorMessage}>{errors.file03}</div>}

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload4"
                accept="application/pdf"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file04")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file04}</span>
              <label htmlFor="fileUpload4" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.file04 && <div className={styles.errorMessage}>{errors.file04}</div>}

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload5"
                accept="application/pdf"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file05")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file05}</span>
              <label htmlFor="fileUpload5" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.file05 && <div className={styles.errorMessage}>{errors.file05}</div>}

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload6"
                accept="application/pdf"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file06")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file06}</span>
              <label htmlFor="fileUpload6" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.file06 && <div className={styles.errorMessage}>{errors.file06}</div>}

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload7"
                accept="application/pdf"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file07")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file07}</span>
              <label htmlFor="fileUpload7" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.file07 && <div className={styles.errorMessage}>{errors.file07}</div>}
            <div className={styles.regDocmainText2}>
              Tanto la constancia de situación fiscal como la opinión de cumplimiento positiva del SAT deben de ser emitidas en el mes en curso.
            </div>
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload8"
                accept="application/pdf"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file08")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file08}</span>
              <label htmlFor="fileUpload8" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.file08 && <div className={styles.errorMessage}>{errors.file08}</div>}

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload9"
                accept="application/pdf"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file09")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file09}</span>
              <label htmlFor="fileUpload9" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.file09 && <div className={styles.errorMessage}>{errors.file09}</div>}

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload10"
                accept="application/pdf"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file10")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file10}</span>
              <label htmlFor="fileUpload10" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.file10 && <div className={styles.errorMessage}>{errors.file10}</div>}

            {/* File Uploads 11*/}
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload11"
                accept="application/pdf" // Restrict to PDF files only
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, "file11")}
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileNames.file11}</span>
              <label htmlFor="fileUpload11" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>

            </div>
            {errors.file11 && <div className={styles.errorMessage}>{errors.file11}</div>}

            <div className={styles.RegBtnIcnMain}>
              <button className={styles.RegBtnIcn2} onClick={() => handleSubmit()}>
                <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
                <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
                <span>ENVIAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default RegisterDoc;
