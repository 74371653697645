const { Assets } = require("./Assets");

module.exports = {
    requirementTypes: ["Ambos", "Bienes", "Servicios"],
    projectTypes: {
        general: "general",
        IT: "IT",
        construction: "construction"
    },
    projectTypesMap: {
        general: "Proyectos Generales",
        IT: "Proyectos de T.I.",
        construction: "Proyectos de Construcción"
    },

    apiBaseUrl: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:5000/api',

    apiRoutes: {
        project: {
            addProject: '/project/add-project',
            getById: '/project',
            getAll: '/project/all',
            questions: '/project/{projectId}/questions',
            postAnswer: '/project/{projectId}/questions/answer'
        },
        auth: {
            login: '/auth/login',
            signupStep1: '/auth/signup-step-1',
            signupStep2: '/auth/signup-step-2',
            signupStep3: '/auth/signup-step-3',
            signupStep4: '/auth/signup-step-4',
            uploadDoc: '/auth/upload-signup-doc',
        },
        user: '/user',
        updateUser: '/user/update',
        deleteUsers: '/user/delete',
        allUsers: '/user/all',
        declareWinner: '/user/declare-winner',
        updatePaperwork: '/user/update-paperwork',
        validatePaperwork: '/user/validate-paperwork',
        updateUserStatus: '/user/update-status',
        currentUser: '/user/current-user',
        bidding: {
            create: '/bidding/create',
            getAll: '/bidding/all',
            evaluate: '/bidding/evaluate',
            allByUser: '/bidding/all-by-user',
        },
        logs: {
            getAll: '/logs/all',
            delete: '/logs/delete'
        },
        email: {
            getAll: '/email/all',
            create: '/email/create',
            contact: '/email/contact'
        },
        reports: {
            userReports: '/reports/user-reports'
        },
        resetPassword: {
            forgot: '/forgot-password',
            verifyToken: '/verify-token',
            updatePassword: '/reset-password'
        }
    },

    userRoles: {
        admin: 'admin',
        voter: 'voter',
        auditor: 'auditor',
        provider: 'provider',
        acquisitions: 'acquisitions',
        contralor: 'contralor',
        projectCreator: 'project_creator'
    },

    userRolesMap: {
        admin: 'Administrador',
        voter: 'Votante',
        auditor: 'Auditor',
        provider: 'Proveedor',
        acquisitions: 'Adquisiciones',
        contralor: 'Contraloría',
        project_creator: 'Creador de Licitaciones'
    },

    userStatus: {
        active: 'active',
        blocked: 'blocked',
        approved: 'approved',
        pending: 'pending',
        rejected: 'rejected'
    },

    userStatusMap: {
        active: 'Activo',
        blocked: 'Bloqueado',
        approved: 'Aprobado',
        pending: 'Pendiente',
        rejected: 'Rechazado'
    },

    logStatusMap: {
        success: 'Éxito',
        failure: 'Fallido',
    },

    severity: {
        high: 'Alta',
        medium: 'Media',
        low: 'Baja'
    },

    auditLogFilters: {
        currentWeek: 'Semana actual',
        currentMonth: 'Mes Actual',
        previousMonth: 'Mes Anterior',
    },

    projectTypeToIconMap: {
        general: Assets.LogoProjectGeneral,
        IT: Assets.LogoProjectIT,
        construction: Assets.LogoProjectConstruction
    },

    days: Array.from({ length: 31 }, (_, i) => i + 1),
    // months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    months: [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ],
    startYear: new Date().getFullYear(),
    endYear: 2034,
    years: Array.from({ length: 2034 - new Date().getFullYear() + 1 }, (_, i) => new Date().getFullYear() + i),

    generalWeightMatrix: [
        {
            id: 1,
            criterio: "Cumplimiento de Especificaciones",
            definicion:
                "Porcentaje de cumplimiento de lo solicitado por CSN (lo asigna el proveedor).",
            ponderacion: 40,
        },
        {
            id: 2,
            criterio: "Adaptación al presupuesto",
            definicion:
                "Que el costo sea menor que el presupuesto, asimismo, mide el que tan bajo es el costo con relación al presupuesto.",
            ponderacion: 30,
        },
        {
            id: 3,
            criterio: "Soporte",
            definicion: "Adaptación a los horarios de servicio de CSN.",
            ponderacion: 10,
        },
        {
            id: 4,
            criterio: "Fecha o plazos de entrega",
            definicion: "Plazos en los que entregará el servicio.",
            ponderacion: 20,
        },
    ],

    constructionWeightMatrix: [
        {
            id: 1,
            criterio: "Cumplimiento de especificaciones",
            definicion: "Mide el apego de lo presupuestado por el proveedor contra el catálogo de conceptos",
            ponderacion: 10,
        },
        {
            id: 2,
            criterio: "Adaptación al presupuesto",
            definicion: "Que el costo sea menor que el presupuesto; asimismo, mide qué tan bajo es el costo con relación al presupuesto.",
            ponderacion: 40,
        },
        {
            id: 3,
            criterio: "Fianza",
            definicion: "Porcentaje de fianza brindado sobre el monto total de la inversión.",
            ponderacion: 10,
        },
        {
            id: 4,
            criterio: "Garantías",
            definicion: "Mide si el proveedor presenta garantías.",
            ponderacion: 20,
        },
        {
            id: 5,
            criterio: "Tiempo de entrega",
            definicion: "Mide los tiempos de entrega del servicio por parte del proveedor.",
            ponderacion: 10,
        },
        {
            id: 6,
            criterio: "Referencia",
            definicion: "Contar con al menos cinco referencias verídicas por parte del proveedor.",
            ponderacion: 10,
        }
    ],

    itWeightMatrix: [
        {
            id: 1,
            criterio: "Cumplimiento de especificaciones",
            definicion: "Mide el apego de las especificaciones solicitadas contra lo propuesto por el proveedor.",
            ponderacion: 25,
        },
        {
            id: 2,
            criterio: "Adaptación al presupuesto",
            definicion: "Que el costo sea menor que el presupuesto; asimismo, mide qué tan bajo es el costo con relación al presupuesto.",
            ponderacion: 20,
        },
        {
            id: 3,
            criterio: "Soporte",
            definicion: "Mide si el proveedor brinda soporte.",
            ponderacion: 15,
        },
        {
            id: 4,
            criterio: "Mantenimiento",
            definicion: "Mide si el proveedor brinda mantenimiento.",
            ponderacion: 15,
        },
        {
            id: 5,
            criterio: "Garantías",
            definicion: "Mide si el proveedor brinda garantías.",
            ponderacion: 15,
        },
        {
            id: 6,
            criterio: "Fechas o plazo de entregas",
            definicion: "Mide el cumplimiento de los plazos de entrega.",
            ponderacion: 10,
        }
    ],

    toastTypes: {
        success: 'success',
        error: 'error',
        info: 'info',
        warning: 'warning'
    },

    regex: {
        email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    },

    providerDocuments: {
        file01: "FO-ADQ-007 Evaluación de proveedor.*",
        file22: "Comprobante de domicilio.*",
        file02: "Carta de confidencialidad firmada.*",
        file03: "Acta constitutiva.",
        file04: "Poder Legal del Representante.",
        file05: "Aviso de privacidad firmado.*",
        file06: "Firma de aceptación de política anti soborno.*",
        file07: "Copia de identificación vigente de persona física.*",
        file08: "Opinión de cumplimiento positiva por el SAT.*",
        file09: "Constancia de situación fiscal emitida por el SAT.*",
        file10: "Identificación oficial del proveedor o representante.*",
        file11: "Permiso vigente de Seguridad Pública del Estado.",
        file12: "Control Interno Investigación Proveedor.*"
    },

    providerDocumentTypes: {
        file01: "FO-ADQ-007",
        file22: "address-proof",
        file02: "confidentiality-letter",
        file03: "incorporation-article",
        file04: "legal-power",
        file05: "privacy-notice",
        file06: "anti-bribery-policy",
        file07: "valid-id",
        file08: "compliance-opinion",
        file09: "tax-status",
        file10: "supplier-id",
        file11: "public-safty-permit",
        file12: "internal-investigation"
    },

    generalError: 'Algo salió mal. Inténtalo de nuevo y ponte en contacto con el equipo de soporte.',
    signupError: 'Error en el registro. Inténtalo de nuevo o ponte en contacto con el equipo de soporte.',

    environment: process.env.NODE_ENV === 'production' ?
        {
            apiBaseUrl: '',
            fileServerUrl: ''
        }
        :
        {
            apiBaseUrl: 'http://localhost:5000/api',
            fileServerUrl: 'http://localhost:5000'
        },

    formatDateTo3CharMonth: (dateStr) => {
        const date = new Date(dateStr);
        const day = date.getDate();
        return `${module.exports.months[date.getMonth()].substring(0, 3)}.${day < 10 ? '0' + day : day}.${date.getFullYear()}`
    },

    getFormatedTime: (dateStr) => {
        const date = new Date(dateStr);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours < 10 ? '0' + formattedHours : formattedHours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
    },

    isProjectActive: (deadline) => {
        return new Date(deadline) > new Date();
    }
}