import React, { useState } from "react";
import styles from "./ProjectRegister.module.css";
import RegIcn from "../../Images/RegIcn.png";
import ProRegStep from "../../Images/proRegStep.png";
import HideIcn from "../../Images/hide2.png";
import FileUpIcn from "../../Images/fileupIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import { useNavigate } from "react-router-dom";
import { apiRoutes } from "../../app.constants";
import axiosInstance from "../../services/axios";

const ProjectRegister = () => {
  const navigate = useNavigate();
  const [fileErrors, setFileErrors] = useState([]);

  const [formDataNew, setFormData] = useState({
    projectTitle: "",
    requestType: "",
    description: "",
    street: "",
    numExt: "",
    characteristics: "",
    specialSpecifications: "",
    deliveryDate: { day: "", month: "", year: "" },
    openingDate: { day: "", month: "", year: "" },
    proposalDeadline: { day: "", month: "", year: "" },
    decisionDate: { day: "", month: "", year: "" },
    budget: "",
    password: "",
    files: [
      "Imagen formato jpeg formato web 1350 px X1350",
      "Imagen formato jpeg formato web 1350 px X1350",
      "Imagen formato jpeg formato web 1350 px X1350",
    ],
  });

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDateChange = (dateType, part) => (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [dateType]: {
        ...prevData[dateType],
        [part]: value,
      },
    }));
  };

  const handleFileChange = (index) => (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSizeInKB = 120;
      if (file.size > maxSizeInKB * 1024) {
        setFileErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          newErrors[index] = `El archivo supera el tamaño máximo permitido de ${maxSizeInKB} KB.`;
          return newErrors;
        });
        return;
      }

      // Clear the error message for this index if the file is valid
      setFileErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = ""; // No error
        return newErrors;
      });

      setFormData((prevData) => {
        const newFiles = [...prevData.files];
        newFiles[index] = file.name;
        return { ...prevData, files: newFiles };
      });
    }
  };



  const monthToNumber = (month) => {
    const months = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };
    return months[month] || '01'; // Default to January if the month name is not valid
  };

  const formatDate = (dateObj) => {
    const { year, month, day } = dateObj;
    if (!year || !month || !day) {
      console.error(`Incomplete date: year=${year}, month=${month}, day=${day}`);
      return "Invalid Date"; // Return placeholder if date parts are missing
    }

    const formattedMonth = monthToNumber(month);
    const formattedDay = String(day).padStart(2, '0');
    const dateStr = `${year}-${formattedMonth}-${formattedDay}`;
    const date = new Date(dateStr);

    if (isNaN(date.getTime())) {
      console.error(`Invalid date string: ${dateStr}`);
      return "Invalid Date";
    }

    return date;
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", formDataNew.projectTitle);
    formData.append("typeOfRequirement", formDataNew.requestType);
    formData.append("description", formDataNew.description);
    formData.append("calle", formDataNew.street);
    formData.append("numExt", formDataNew.numExt);
    formData.append("features", formDataNew.characteristics);
    formData.append("specialSpecifications", formDataNew.specialSpecifications);

    formData.append("dateOfDelivery", formatDate(formDataNew.deliveryDate) || "");
    formData.append("openingDate", formatDate(formDataNew.openingDate) || "");
    formData.append("deadline", formatDate(formDataNew.proposalDeadline) || "");
    formData.append("closingDate", formatDate(formDataNew.decisionDate) || "");

    formData.append("password", formDataNew.password);

    formDataNew.files.forEach((fileName, index) => {
      const fileInput = document.getElementById(`fileUpload${index + 1}`);
      if (fileInput && fileInput.files.length > 0) {
        formData.append("files", fileInput.files[0]);
      }
    });

    try {
      const response = await axiosInstance.post(
        apiRoutes.project.addProject,
        formData,
      );

      alert("Project Added Successfully!");
      console.log(response);
      navigate("/system/proyectos");
    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
        alert(`Signup failed: ${error.response.data.message || error.message}`);
      } else {
        console.error("Error:", error);
        alert("Signup failed!");
      }
    }
  };


  return (
    <div className={styles.projectRegisterMain}>
      <div className={styles.projectRegisterContainer}>
        <div className={styles.projectRegisterContainer2}>
          <div className={styles.registerMainHeading}>
            <img src={RegIcn} alt="" className={styles.regIcn} />
            Alta de Proyectos
          </div>
          <div className={styles.supRegStepMain}>
            <img src={ProRegStep} alt="" className={styles.proRegStep} />
          </div>
          <div className={styles.registerInputMain}>
            Título del Proyecto:*
            <input type="text" name="projectTitle" className={styles.inputRegister} onChange={handleInputChange} />
          </div>
          <div className={styles.radioMaindiv}>
            <div className={styles.radioText}>Tipo de requerimiento:*</div>
            {["Bienes", "Servicios", "Ambos"].map((type) => (
              <div key={type} className={styles.radioTextBtn}>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="requestType"
                    value={type}
                    onChange={handleInputChange}
                  />
                  <span className="checkmark"></span>
                </label>
                <div className={styles.radioText}>{type}</div>
              </div>
            ))}
          </div>
          <div className={styles.regDocmainText}>Descripción*</div>
          <textarea name="description" className={styles.registerTextarea} onChange={handleInputChange}></textarea>
          <div className={styles.registerInputMain2}>
            <div className={styles.inputRegisterLeft}>
              Cantidad:*
              <input type="text" name="street" className={styles.inputRegister2} onChange={handleInputChange} />
            </div>
            <div className={styles.inputRegisterRight}>
              Unidad:*
              <input type="text" name="numExt" className={styles.inputRegister2} onChange={handleInputChange} />
            </div>
          </div>
          <div className={styles.regDocmainText}>Características:*</div>
          <textarea name="characteristics" className={styles.registerTextarea} onChange={handleInputChange}></textarea>
          <div className={styles.regDocmainText}>Especificaciones Especiales:*</div>
          <textarea name="specialSpecifications" className={styles.registerTextarea2} onChange={handleInputChange}></textarea>

          {[
            { label: "Fecha de entrega del bien o servicio:*", dateType: "deliveryDate" },
            { label: "Fecha de apertura:*", dateType: "openingDate" },
            { label: "Fecha límite para entregar propuestas:*", dateType: "proposalDeadline" },
            { label: "Fecha de cierre (Toma de decisión):*", dateType: "decisionDate" },
          ].map(({ label, dateType }) => (
            <div key={dateType}>
              <div className={styles.regDocmainText}>{label}</div>
              <div className={styles.selecterMain}>
                <label>
                  <select value={formDataNew[dateType].day} onChange={handleDateChange(dateType, 'day')} className={styles.selecterInput}>
                    <option value="" disabled>Día:</option>
                    {days.map((d) => (
                      <option key={d} value={d}>{d}</option>
                    ))}
                  </select>
                </label>
                <label>
                  <select value={formDataNew[dateType].month} onChange={handleDateChange(dateType, 'month')} className={styles.selecterInput2}>
                    <option value="" disabled>Mes:</option>
                    {months.map((m, index) => (
                      <option key={index} value={m}>{m}</option>
                    ))}
                  </select>
                </label>
                <label>
                  <select value={formDataNew[dateType].year} onChange={handleDateChange(dateType, 'year')} className={styles.selecterInput2}>
                    <option value="" disabled className={styles.selecterOptionText}>Año:</option>
                    {years.map((y) => (
                      <option key={y} value={y}>{y}</option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
          ))}

          {/* <div className={styles.regDocmainText}>Presupuesto autorizado para el proyecto:*</div> */}
          <div className={styles.registerInputMain}>
            Presupuesto autorizado para el proyecto:*
            <div className={styles.showPasswordInput}>
              <input
                type="text"
                name="password"
                className={styles.passwordinput}
                onChange={handleInputChange}
              />
              <img
                src={HideIcn}
                alt=""
                className={styles.hideIcn}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          {formDataNew.files.map((fileName, index) => (
            <div key={index}>
              <div className={styles.regDocmainText}>Fotografía representativa {index + 1}. Máximo 120 kb.:*</div>
              <div className={styles.fileUploadContainer}>
                <input
                  type="file"
                  id={`fileUpload${index + 1}`}
                  className={styles.regdocFileUp}
                  onChange={handleFileChange(index)}
                  accept=".jpeg"
                  style={{ display: "none" }}
                />
                <span className={styles.fileName}>{fileName}</span>
                <label htmlFor={`fileUpload${index + 1}`} className={styles.customFileUpload}>
                  <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                  ANEXAR
                </label>
              </div>
              {/* Error message */}
              {fileErrors[index] && (
                <div className={styles.errorMessage}>{fileErrors[index]}</div>
              )}
            </div>
          ))}

          <div className={styles.RegBtnIcnMain}>
            <button className={styles.RegBtnIcn2} onClick={handleSubmit}>
              <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
              <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
              <span>GUARDAR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectRegister;
