import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import styles from "./UserReport.module.css";
import axiosInstance from '../../../services/axios';
import { apiRoutes, generalError, toastTypes } from '../../../app.constants';
import { showToast } from '../../Shared/CustomToast';
import Spinner from '../../Shared/Spinner';

const UserReport = () => {
  const trendRef = useRef(null);
  const providerTypesRef = useRef(null);
  const bidsPerMonthRef = useRef(null);
  const winnersPerMonthRef = useRef(null);
  const openClosedRef = useRef(null);
  const popularBidsRef = useRef(null);
  const projectTypesRef = useRef(null);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchReportsData = async () => {
      try {
        const { data } = await axiosInstance.get(apiRoutes.reports.userReports);
        setData(data);
      } catch (error) {
        showToast(toastTypes.error, generalError);
        console.error('Error fetching user reports data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchReportsData();
  }, []);


  useEffect(() => {
    // Trend Line Chart
    const trendCtx = trendRef.current.getContext('2d');
    const trendChart = new Chart(trendCtx, {
      type: 'line',
      data: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Tendencia en Suscripción',
          data: data.providersTrend ? [...data?.providersTrend] : [],
          borderColor: 'rgb(51,102,204)',
          backgroundColor: 'rgb(51,102,204)',
          fill: false,
          tension: 0.1
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    // Provider Types Pie Chart
    const providerTypesCtx = providerTypesRef.current.getContext('2d');
    const providerTypesChart = new Chart(providerTypesCtx, {
      type: 'pie',
      data: {
        datasets: [{
          data: data.providerTypesPercent ? [...data.providerTypesPercent] : [],
          backgroundColor: ['#50B432', '#ED561B', '#DDDF00'],
        }],
        labels: ['Ambos', 'Bienes', 'Servicios'],
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                // let label = context.label || '';
                // if (label) {
                //   label += `: ${context.parsed}%`;
                // }
                return ` ${context?.parsed}%`;
              },
            },
          },
        },
      }
    });

    // Bids Per Month Bar Chart
    const bidsPerMonthCtx = bidsPerMonthRef.current.getContext('2d');
    const bidsPerMonthChart = new Chart(bidsPerMonthCtx, {
      type: 'bar',
      data: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Cantidad de Licitaciones',
          data: data.projectsTrend ? [...data.projectsTrend] : [], // Replace with actual data
          backgroundColor: '#6096BA'
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    // Open vs Closed Doughnut Chart
    const openClosedCtx = openClosedRef.current.getContext('2d');
    const openClosedChart = new Chart(openClosedCtx, {
      type: 'pie',
      data: {
        datasets: [{
          data: data.openAndClosedPercentage ? [...data.openAndClosedPercentage] : [],
          backgroundColor: ['#0071AF', '#A1ACBA'],
        }],
        labels: ['Cerradas', 'Abiertas'],
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return ` ${context?.parsed}%`;
              },
            },
          },
        },
      }
    });

    // Popular projects Pie Chart
    const popularBidsCtx = popularBidsRef.current.getContext('2d');
    const popularBidsChart = new Chart(popularBidsCtx, {
      type: 'pie',
      data: {
        labels: data.popularProjects ? [...data.popularProjects.map(pro => pro.projectName)] : [],
        datasets: [{
          data: data.popularProjects ? [...data.popularProjects.map(pro => pro.totalBids)] : [],
          backgroundColor: ['#00A9D7', '#FFA400', '#3A8DDE', '#80BC00', '#6E7C7C']
        }]
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return ` Proveedores: ${context?.parsed}`;
              },
            },
          },
        },
      }
    });

    // Winners Per Month Bar Chart
    const winnersPerMonthCtx = winnersPerMonthRef.current.getContext('2d');
    const winnersPerMonthChart = new Chart(winnersPerMonthCtx, {
      type: 'bar',
      data: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Cantidad de Ganadores',
          data: data.winnersTrend ? [...data.winnersTrend] : [],
          backgroundColor: '#6096BA'
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    const projectTypeCtx = projectTypesRef.current.getContext('2d');
    const projectTypesChart = new Chart(projectTypeCtx, {
      type: 'pie',
      data: {
        labels: ['Proyectos de T.I.', 'Proyectos de Construcción', 'Proyectos Generales'],
        datasets: [{
          data: data.projectTypes ? [...data.projectTypes] : [],
          backgroundColor: ['#00A9D7', '#FFA400', '#3A8DDE']
        }]
      },
      options: {
        responsive: true
      }
    });

    // Cleanup function to destroy charts when the component unmounts
    return () => {
      trendChart.destroy();
      providerTypesChart.destroy();
      bidsPerMonthChart.destroy();
      openClosedChart.destroy();
      popularBidsChart.destroy();
      winnersPerMonthChart.destroy();
      projectTypesChart.destroy();
    };
  }, [data]);

  return (
    <div className={styles.userReportMain}>
      <div className={styles.userReportContainer}>
        <div className={styles.reportSection}>
          <div className={styles.reportsection1}>
            <div className={styles.reportHeading}>Tendencia en Suscripción de Proveedores</div>
            <canvas ref={trendRef}></canvas>
          </div>
          <div className={styles.reportsection2}>
            <div className={styles.reportHeading}>Tipos de Proveedores</div>
            <canvas ref={providerTypesRef}></canvas>
          </div>
        </div>

        <div className={styles.reportSection}>
          <div className={styles.reportsection1}>
            <div className={styles.reportHeading}>Cantidad de Licitaciones por Mes</div>
            <canvas ref={bidsPerMonthRef}></canvas>
          </div>
          <div className={styles.reportsection2}>
            <div className={styles.reportHeading}>Licitaciones Abiertas vs. Cerradas</div>
            <canvas ref={openClosedRef}></canvas>
          </div>
        </div>

        <div className={styles.reportSection}>
          <div className={styles.reportsection1}>
            <div className={styles.reportHeading}>Ganadores por Mes</div>
            <canvas ref={winnersPerMonthRef}></canvas>
          </div>
          <div className={styles.reportsection2}>
            <div className={styles.reportHeading}>Licitaciones más populares</div>
            <canvas ref={popularBidsRef}></canvas>
          </div>
        </div>

        <div className={styles.reportSection}>
          <div className={styles.reportsection2}>
            <div className={styles.reportHeading}>Tipos de Proyectos</div>
            <canvas ref={projectTypesRef}></canvas>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserReport;