import React, { useState } from 'react'
import { Assets } from '../../../Assets';
import EmailDetailsDialog from './EmailDetailsDialog';
import EmailFormDialog from './EmailFormDialog';

const DraftsTable = () => {
    const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState({});

    const emailTemplates = [
        {
            _id: 1,
            subject: 'Asignación de Licitación: [Nombre del Proyecto/Licitación]',
            body: `Estimado/a [Nombre del Contratista o Empresa],

Nos complace informarle que su propuesta ha sido seleccionada para la adjudicación del proyecto [descripción breve del proyecto/licitación].
Después de revisar cuidadosamente todas las propuestas recibidas, encontramos que la suya presenta la mejor oferta para nuestros requerimientos.

Agradecemos su dedicación y el tiempo invertido en la preparación de la propuesta. Esperamos con entusiasmo trabajar con usted para llevar a cabo este proyecto importante y exitoso.

A continuación, nos pondremos en contacto con usted para discutir los siguientes pasos del proceso.

Una vez más, felicitaciones por esta adjudicación y gracias por su interés en colaborar con nosotros.

Atentamente,
CSN Cooperativa Financiera, S.C. de A.P. de R.L. de C.V.
            `,
        },
        {
            _id: 2,
            subject: 'Información sobre Propuesta para  [Nombre del Proyecto/Licitación]',
            body: `Estimado/a [Nombre del Contratista o Empresa],

Agradecemos sinceramente el interés demostrado y el tiempo dedicado a presentar su propuesta para el proyecto [descripción del proyecto/licitación].
Después de un proceso de evaluación exhaustivo y consideración cuidadosa de todas las propuestas recibidas, lamentamos informarle que su propuesta no ha sido seleccionada para la adjudicación en esta ocasión.

Queremos destacar que su propuesta fue considerada seriamente y valoramos los esfuerzos realizados para cumplir con los requisitos establecidos.

Apreciamos su comprensión en esta situación y esperamos contar con su participación en futuras oportunidades de colaboración.
Valoramos la posibilidad de trabajar juntos en proyectos futuros y estamos disponibles para proporcionar retroalimentación detallada sobre su propuesta si así lo desea.

Gracias nuevamente por su interés en nuestro proyecto y le deseamos éxito en sus futuros esfuerzos comerciales.

Atentamente,
CSN Cooperativa Financiera, S.C. de A.P. de R.L. de C.V.
            `,
        },
    ]

    const handleRowClick = (email) => {
        setSelectedEmail(email);
        setIsDetailsDialogOpen(true);
    };

    const handleUseTemplate = (email) => {
        setSelectedEmail(email);
        setOpenFormDialog(true);
    }

    return (
        <>
            {openFormDialog && <EmailFormDialog selectedEmail={selectedEmail} setOpenDialog={setOpenFormDialog} />}
            {isDetailsDialogOpen && <EmailDetailsDialog selectedEmail={selectedEmail} setOpenDialog={setIsDetailsDialogOpen} />}
            <div className="mx-auto w-full max-w-[90%]">
                <div className="w-full bg-white rounded-lg shadow-md">
                    {/* Table wrapper with fixed height */}
                    <div className="flex flex-col h-[450px] mt-5">
                        {/* Table container */}
                        <div className="flex-1 overflow-hidden">
                            <div className="h-full flex flex-col text-center">
                                {/* Header section */}
                                <table className="w-full border border-gray-300">
                                    <thead className="bg-gray-200">
                                        <tr>
                                            {/* <th className="w-[4%] border border-gray-300 px-1 py-3 font-semibold text-gray-900" ></th> */}
                                            <th className="w-[10%] text-left pl-5 border border-gray-300 py-3 font-semibold text-gray-900" >#</th>
                                            <th className="w-[20%] border border-gray-300 px-1 py-3 font-semibold text-gray-900" >Para</th>
                                            <th className="w-[50%] border border-gray-300 px-1 py-3 font-semibold text-gray-900" >Asunto</th>
                                            <th className="w-[20%] border border-gray-300 px-1 py-3 font-semibold text-gray-900" ></th>
                                        </tr>
                                    </thead>
                                </table>

                                {/* Scrollable body section */}
                                <div className="overflow-auto">
                                    <table className="w-full">
                                        <tbody>
                                            {emailTemplates.map((email, index) => (
                                                <tr key={email._id}
                                                    className='border-b border-gray-300 h-16 hover:bg-gray-50'
                                                >
                                                    <td className="w-[10%] py-1 pl-5">
                                                        <div className='flex gap-4 items-center justify-start'>
                                                            <span>{index + 1}</span>
                                                            <img src={Assets.EmailIcon} alt='' />
                                                        </div>
                                                    </td>
                                                    <td className="w-[20%] py-1 px-1">-</td>
                                                    <td className="w-[50%] py-1 px-1">{email.subject}</td>
                                                    <td className="w-[50%] py-1 px-1">
                                                        <div className='flex gap-2'>
                                                            <button
                                                                onClick={() => handleRowClick(email)}
                                                                className="flex text-sm font-russo self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-2 py-2 text-white hover:bg-[#71A850]"
                                                            >
                                                                Vista
                                                            </button>
                                                            <button
                                                                onClick={() => handleUseTemplate(email)}
                                                                className="flex text-sm font-russo self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-2 py-2 text-white hover:bg-[#71A850]"
                                                            >
                                                                Usar Plantilla
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DraftsTable