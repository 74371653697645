import { useEffect, useState } from "react"
import styles from "./AddProjects.module.css"
import HideIcn from "../../../Images/hide2.png";
import FileUpIcn from "../../../Images/fileupIcn.png";
import RegInpIcn1 from "../../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../../Images/reginpIcn2.png";
import { useNavigate } from "react-router-dom";
import { apiRoutes, days, generalError, months, projectTypes, projectTypesMap, toastTypes, years } from "../../../app.constants";
import axiosInstance from "../../../services/axios";
import { showToast } from "../../Shared/CustomToast";
import { parseISO, startOfDay, isBefore } from "date-fns";

function AddProjects() {
  const navigate = useNavigate();
  const [fileErrors, setFileErrors] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [formDataNew, setFormData] = useState({
    projectType: "",
    voter: "",
    projectTitle: "",
    requestType: "",
    description: "",
    street: "",
    numExt: "",
    characteristics: "",
    specialSpecifications: "",
    deliveryDate: { day: "", month: "", year: "" },
    openingDate: { day: "", month: "", year: "" },
    proposalDeadline: { day: "", month: "", year: "" },
    decisionDate: { day: "", month: "", year: "" },
    budget: "",
    files: [
      "Imagen formato jpeg formato web 1350 px X1350",
      "Imagen formato jpeg formato web 1350 px X1350",
      "Imagen formato jpeg formato web 1350 px X1350",
    ],
  });
  const [votingUsers, setVotingUsers] = useState([]);
  const requiredFields = [
    "projectType",
    "voter",
    "projectTitle",
    "requestType",
    "description",
    "street",
    "numExt",
    "characteristics",
    "specialSpecifications",
    "budget",
  ];

  useEffect(() => {
    const fetchVotingUsers = async () => {
      try {
        const { data } = await axiosInstance.get(apiRoutes.user + `?role=voter`);
        setVotingUsers(data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    fetchVotingUsers();
  }, []);

  const monthToNumber = (month) => {
    const months = {
      Enero: '01', Febrero: '02', Marzo: '03', Abril: '04', Mayo: '05', Junio: '06',
      Julio: '07', Agosto: '08', Septiembre: '09', Octubre: '10', Noviembre: '11', Diciembre: '12',
    };
    return months[month] || '01';
  };

  const formatDate = (dateObj) => {
    const { year, month, day } = dateObj;
    if (!year || !month || !day) {
      // console.error(`Incomplete date: year=${year}, month=${month}, day=${day}`);
      return null; // Return null if date parts are missing
    }
    const formattedMonth = monthToNumber(month);
    const formattedDay = String(day).padStart(2, '0');
    const dateStr = `${year}-${formattedMonth}-${formattedDay}`;
    const date = parseISO(dateStr); // Parses YYYY-MM-DD correctly
    return date;
    // const date = new Date(dateStr);
    // date.setHours(0, 0, 0, 0);
    // return date;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    // Clear error when field has value
    setErrorMessages(prev => ({
      ...prev,
      [name]: value ? "" : prev[name]
    }));

    // Validate required fields
    if (requiredFields.includes(name) && !value) {
      setErrorMessages(prev => ({
        ...prev,
        [name]: "El campo superior es obligatorio y debe ser llenado para poder proceder."
      }));
    }
  };

  const handleDateChange = (dateType, part) => (e) => {
    const value = e.target.value;
    setFormData((prevData) => {
      const updatedFormData = {
        ...prevData,
        [dateType]: {
          ...prevData[dateType],
          [part]: value,
        },
      };

      // Perform date validation
      const errors = { ...errorMessages };
      const date = updatedFormData[dateType];

      if (date.month === 'Febrero' && parseInt(date.day) > 28) {
        errors[dateType] = "Esta fecha no existe, favor de corregirla";
        setErrorMessages(errors);
        return updatedFormData;
      } else {
        errors[dateType] = "";
        setErrorMessages(errors);
      }

      if (!date.month || !date.day || !date.year) {
        setErrorMessages(errors);
        return updatedFormData;
      }

      // Check if date is in the past
      // const today = new Date();
      // today.setHours(0, 0, 0, 0); // Reset time portion for date comparison

      const today = startOfDay(new Date());

      const fullDate = formatDate(date);
      console.log('selectedDate: ', fullDate);
      console.log('today: ', today);

      if (fullDate && isBefore(fullDate, today)) {
        errors[dateType] = "No puede seleccionar una fecha del pasado, favor de corregir";
        setErrorMessages(errors);
        return updatedFormData;
      }

      // const fullDate = formatDate(date);
      if (!fullDate) {
        let errorMsg = '';
        if (dateType === 'openingDate') {
          errorMsg = "La fecha de apertura es obligatoria.";
        } else if (dateType === 'proposalDeadline') {
          errorMsg = "La fecha límite de entrega es obligatoria.";
        } else if (dateType === 'decisionDate') {
          errorMsg = "La fecha de cierre es obligatoria.";
        } else if (dateType === 'deliveryDate') {
          errorMsg = "La fecha de entrega es obligatoria.";
        }

        errors[dateType] = errorMsg;
      } else {
        const openingDate = formatDate(updatedFormData.openingDate);
        const proposalDeadline = formatDate(updatedFormData.proposalDeadline);
        const closingDate = formatDate(updatedFormData.decisionDate);
        const deliveryDate = formatDate(updatedFormData.deliveryDate);

        if (proposalDeadline && closingDate && proposalDeadline >= closingDate) {
          errors.proposalDeadline = "Favor de corregir la fecha límite de entrega ya que debe de ser anterior a la fecha de cierre.";
        } else {
          errors.proposalDeadline = '';
        }
        if (openingDate) {
          if (proposalDeadline && proposalDeadline <= openingDate) {
            errors.openingDate = "La fecha de apertura debe ser anterior a la fecha límite de presentación de propuestas.";
          } else if (closingDate && closingDate <= openingDate) {
            errors.openingDate = "La fecha de apertura debe ser anterior a la fecha de cierre.";
          } else if (deliveryDate && deliveryDate <= openingDate) {
            errors.openingDate = "La fecha de apertura deberá ser anterior a la fecha límite de prestación de los servicios.";
          } else {
            errors.openingDate = '';
          }
        }
        if (deliveryDate) {
          if (proposalDeadline && proposalDeadline >= deliveryDate) {
            errors.deliveryDate = "La fecha de entrega del bien o servicio debe ser posterior a la fecha límite de presentación de la propuesta.";
          } else if (closingDate && closingDate >= deliveryDate) {
            errors.deliveryDate = "La fecha de entrega del bien o servicio debe ser posterior a la fecha de cierre.";
          } else {
            errors.deliveryDate = '';
          }
        }
      }

      setErrorMessages(errors);
      return updatedFormData;
    });
  };


  const handleFileChange = (index) => (event) => {
    const file = event.target.files[0];

    setFileErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (!file) {
        newErrors[index] = "Debe seleccionar un archivo válido.";
      } else {
        const maxSizeInKB = 120;
        if (file.size > maxSizeInKB * 1024) {
          newErrors[index] = `El archivo supera el tamaño máximo permitido de ${maxSizeInKB} KB.`;
        } else {
          newErrors[index] = ""; // Clear error if file is valid
        }
      }
      return newErrors;
    });

    if (file) {
      setFormData((prevData) => {
        const newFiles = [...prevData.files];
        newFiles[index] = file.name;
        return { ...prevData, files: newFiles };
      });
    }
  };


  const validateInput = () => {
    const newErrors = {};
    const requiredFields = [
      "projectType",
      "voter",
      "projectTitle",
      "requestType",
      "description",
      "street",
      "numExt",
      "characteristics",
      "specialSpecifications",
      "budget",
    ];
    requiredFields.forEach((field) => {
      if (!formDataNew[field]) {
        newErrors[field] = "El campo superior es obligatorio y debe ser llenado para poder proceder.";
      }
    });

    // Check for missing or invalid files
    formDataNew.files.forEach((fileName, index) => {
      if (!fileName || fileErrors[index]) {
        newErrors[`file${index}`] = "Debe seleccionar un archivo válido.";
      }
    });

    // Combine with existing date errors
    const errors = { ...errorMessages, ...newErrors };

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
    }
  }

  const handleSubmit = async () => {
    validateInput();
    if (Object.keys(errorMessages).some(key => errorMessages[key])) {
      return;
    }

    const formData = new FormData();
    formData.append("projectType", formDataNew.projectType);
    formData.append("voter", formDataNew.voter);
    formData.append("title", formDataNew.projectTitle);
    formData.append("typeOfRequirement", formDataNew.requestType);
    formData.append("description", formDataNew.description);
    formData.append("calle", formDataNew.street);
    formData.append("numExt", formDataNew.numExt);
    formData.append("features", formDataNew.characteristics);
    formData.append("specialSpecifications", formDataNew.specialSpecifications);
    formData.append("dateOfDelivery", formatDate(formDataNew.deliveryDate) || "");
    formData.append("openingDate", formatDate(formDataNew.openingDate) || "");
    formData.append("deadline", formatDate(formDataNew.proposalDeadline) || "");
    formData.append("closingDate", formatDate(formDataNew.decisionDate) || "");
    formData.append("budget", formDataNew.budget);

    formDataNew.files.forEach((fileName, index) => {
      const fileInput = document.getElementById(`fileUpload${index + 1}`);
      if (fileInput && fileInput.files.length > 0) {
        formData.append("files", fileInput.files[0]);
      }
    });

    try {
      const response = await axiosInstance.post(
        apiRoutes.project.addProject,
        formData,
      );
      showToast(toastTypes.success, "El proyecto ha sido añadido éxitosamente.");
      navigate("/system/proyectos");
    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
        showToast(toastTypes.error, `${error.response.data.message || error.message}`);
      } else {
        console.error("Error:", error);
        showToast(toastTypes.error, generalError);
      }
    }
  };


  return (
    <div className={styles.mainProjectFields}>
      <div className={styles.projectRegisterMain}>
        <div className={styles.projectRegisterContainer}>
          <div className={styles.projectRegisterContainer2}>
            <div className={styles.registerInputMain}>
              Área del Proyecto:*
              <select name="projectType" className={styles.inputRegister} onChange={handleInputChange} >
                <option value="">--Por favor elige una opción--</option>
                {Object.keys(projectTypes).map((type, index) => <option key={index} value={type}>{projectTypesMap[type]}</option>)}
              </select>
            </div>
            {errorMessages.projectType && <div className={styles.errorText}>{errorMessages.projectType}</div>}

            <div className={styles.registerInputMain}>
              Votante:*
              <select name="voter" className={styles.inputRegister} onChange={handleInputChange} >
                <option value="">--Por favor elige una opción--</option>
                {votingUsers.map(voter => (
                  <option key={voter?._id} value={voter?._id}>{voter?.fullname} {voter?.surname}</option>
                ))}
              </select>

            </div>
            {errorMessages.voter && <div className={styles.errorText}>{errorMessages.voter}</div>}

            <div className={styles.registerInputMain}>
              Título del Proyecto:*
              <input type="text" name="projectTitle" className={styles.inputRegister} onChange={handleInputChange} />

            </div>
            {errorMessages.projectTitle && <div className={styles.errorText}>{errorMessages.projectTitle}</div>}

            <div className={styles.radioMaindiv}>
              <div className={styles.radioText}>Tipo de requerimiento:*</div>
              {["Bienes", "Servicios", "Ambos"].map((type) => (
                <div key={type} className={styles.radioTextBtn}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="requestType"
                      value={type}
                      onChange={handleInputChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className={styles.radioText}>{type}</div>
                </div>
              ))}
            </div>
            {errorMessages.requestType && <div className={styles.errorText}>{errorMessages.requestType}</div>}

            <div className={styles.regDocmainText}>Descripción*</div>
            <textarea name="description" className={styles.registerTextarea} onChange={handleInputChange}></textarea>
            {errorMessages.description && <div className={styles.errorText}>{errorMessages.description}</div>}

            <div className={styles.registerInputMain2}>
              <div className={styles.inputRegisterLeft}>
                Cantidad:*
                <input type="text" name="street" className={styles.inputRegister2} onChange={handleInputChange} />
                {errorMessages.street && <div className={styles.errorText}>{errorMessages.street}</div>}

              </div>
              <div className={styles.inputRegisterRight}>
                Unidad:*
                <input type="text" name="numExt" className={styles.inputRegister2} onChange={handleInputChange} />
                {errorMessages.numExt && <div className={styles.errorText}>{errorMessages.numExt}</div>}

              </div>
            </div>
            <div className={styles.regDocmainText}>Características:*</div>
            <textarea name="characteristics" className={styles.registerTextarea} onChange={handleInputChange}></textarea>
            {errorMessages.characteristics && <div className={styles.errorText}>{errorMessages.characteristics}</div>}

            <div className={styles.regDocmainText}>Especificaciones Especiales:*</div>
            <textarea name="specialSpecifications" className={styles.registerTextarea2} onChange={handleInputChange}></textarea>
            {errorMessages.specialSpecifications && <div className={styles.errorText}>{errorMessages.specialSpecifications}</div>}

            {[{ label: "Fecha de apertura:*", dateType: "openingDate" },
            { label: "Fecha límite para entregar propuestas:*", dateType: "proposalDeadline" },
            { label: "Fecha de cierre (Toma de decisión):*", dateType: "decisionDate" },
            { label: "Fecha de entrega del bien o servicio:*", dateType: "deliveryDate" }]
              .map(({ label, dateType }) => (
                <div key={dateType} className={styles.selecterCont}>
                  <div className={styles.regDocmainText}>{label}</div>
                  <div className={styles.selecterMain}>
                    <label>
                      <select value={formDataNew[dateType].day} onChange={handleDateChange(dateType, 'day')} className={styles.selecterInput}>
                        <option value="" disabled>Día:</option>
                        {days.map((d) => (
                          <option key={d} value={d}>{d}</option>
                        ))}
                      </select>
                    </label>
                    <label>
                      <select value={formDataNew[dateType].month} onChange={handleDateChange(dateType, 'month')} className={styles.selecterInput2}>
                        <option value="" disabled>Mes:</option>
                        {months.map((m, index) => (
                          <option key={index} value={m}>{m}</option>
                        ))}
                      </select>
                    </label>
                    <label>
                      <select value={formDataNew[dateType].year} onChange={handleDateChange(dateType, 'year')} className={styles.selecterInput2}>
                        <option value="" disabled className={styles.selecterOptionText}>Año:</option>
                        {years.map((y) => (
                          <option key={y} value={y}>{y}</option>
                        ))}
                      </select>
                    </label>
                  </div>
                  {errorMessages[dateType] && <span style={{ color: 'red' }}>{errorMessages[dateType]}</span>}
                </div>
              ))}

            {/* <div className={styles.regDocmainText}>Presupuesto autorizado para el proyecto:*</div> */}
            <div className={styles.registerInputMain}>
              Presupuesto autorizado para el proyecto (MXN):*
              <div className={styles.showPasswordInput}>
                <span className="px-1">$</span>
                <input
                  type="number"
                  name="budget"
                  value={formDataNew.budget}
                  className={styles.passwordinput}
                  onChange={handleInputChange}
                />
                <span className="px-1">MXN</span>
              </div>
              {errorMessages.budget && <div className={styles.errorText}>{errorMessages.budget}</div>}

            </div>

            {formDataNew.files.map((fileName, index) => (
              <div key={index}>
                <div className={styles.regDocmainText}>Fotografía representativa {index + 1}. Máximo 120 kb.:*</div>
                <div className={styles.fileUploadContainer}>
                  <input
                    type="file"
                    id={`fileUpload${index + 1}`}
                    className={styles.regdocFileUp}
                    onChange={handleFileChange(index)}
                    accept=".jpeg, .jpg"
                    style={{ display: "none" }}
                  />
                  <span className={styles.fileName}>{fileName}</span>
                  <label htmlFor={`fileUpload${index + 1}`} className={styles.customFileUpload}>
                    <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                    ANEXAR
                  </label>
                </div>
                {/* Error message */}
                {fileErrors[index] && (
                  <div className={styles.errorText}>{fileErrors[index]}</div>
                )}
              </div>
            ))}

            <div className={styles.RegBtnIcnMain}>
              <button className={styles.RegBtnIcn2} onClick={handleSubmit}>
                <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
                <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
                <span>GUARDAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddProjects
