import { useState } from "react";

const { apiRoutes } = require("../../../app.constants");
const { default: axiosInstance } = require("../../../services/axios");

const AnswerForm = ({ questionId, projectId, onAnswerAdded }) => {
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePostAnswer = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(
                apiRoutes.project.postAnswer.replace('{projectId}', projectId),
                { questionId, content: answer },
            );
            const data = response?.data;
            onAnswerAdded();
            setAnswer('');
        } catch (error) {
            console.log('Failed to post answer');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-end space-x-5 pt-1 pb-1">
            <textarea
                className="flex-grow p-2 border rounded"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder="Escribe tu respuesta..."
            />
            <button
                onClick={handlePostAnswer}
                disabled={loading}
                className="px-2 py-1 bg-[#166534] font-russo rounded-[50px] text-[14px] text-white"
            >
                Responder
            </button>
        </div>
    );
};

export default AnswerForm;