import styles from "./ForgotPass.module.css"
import RegIcn from "../../Images/RegIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import HideIcn from "../../Images/hideIcn.png";
import BtnIcn from "../../Images/headBtnIcn1.png";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { apiRoutes, generalError, toastTypes, userRoles } from "../../app.constants";
import axiosInstance from "../../services/axios";
import { showToast } from "../Shared/CustomToast";
import Spinner from "../Shared/Spinner";

const UpdatePassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({});
    const [tokenError, setTokenError] = useState(false);
    const [matchingError, setMatchingError] = useState('');
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const [loading, setLoading] = useState(true);
    const { token } = useParams();


    useEffect(() => {
        // Check if token is valid
        const verifyToken = async () => {
            try {
                await axiosInstance.get(`${apiRoutes.resetPassword.verifyToken}/${token}`);
            } catch (err) {
                setTokenError(true);
            } finally {
                setLoading(false);
            }
        };
        verifyToken();
    }, [token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setMatchingError('')
        setErrors({ ...errors, [name]: !value ? 'Este es un campo obligatorio.' : '' });
    };

    // Handle login
    const handleSubmit = async () => {
        const { password, confirmPassword } = formData;
        if (!password || !confirmPassword) {
            setErrors({
                password: !password ? 'Este es un campo obligatorio.' : '',
                confirmPassword: !confirmPassword ? 'Este es un campo obligatorio.' : ''
            });
            return;
        }
        if (password !== confirmPassword) {
            console.log(password, confirmPassword);
            setMatchingError('Las contraseñas no coinciden, favor de corregirlas.');
            return;
        }

        try {
            await axiosInstance.post(`${apiRoutes.resetPassword.updatePassword}/${token}`, { password });
            setPasswordUpdated(true);
        } catch (error) {
            const { status } = error.response;
            if (status === 400) {
                setTokenError(true);
            } else {
                showToast(toastTypes.error, generalError);
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prev) => !prev);
    };

    if (loading) return (<Spinner />);

    return (
        <div>
            <div className={styles.registerMain}>
                <div className={styles.registerContainer}>
                    <div className={styles.registerContainer2}>
                        {/* Login Section */}
                        <div className={styles.registerLeftMain}>
                            <div className={styles.registerMainHeading}>
                                <img src={RegIcn} alt="" className={styles.regIcn} />
                                Restablecer contraseña
                            </div>
                            {passwordUpdated
                                ? <div className="flex justify-center items-center mt-8 flex-col gap-3">
                                    <span className="text-xl text-white text-center">
                                        Su contraseña se ha actualizado correctamente. Vuelva a iniciar sesión para acceder al sistema.
                                    </span>

                                    <Link to="/system/login" className={`${styles.navBtn}`}>
                                        <img src={BtnIcn} alt="ACCESO Icon" className={styles.navBtnIcn} />
                                        ACCESO
                                    </Link>
                                </div>
                                : tokenError
                                    ? <div className="flex justify-center items-center mt-8 flex-col gap-3">
                                        <span className="text-xl text-red-500 text-center">
                                            El enlace no es válido porque ya se utilizó para restablecer la contraseña o ha expirado.
                                        </span>
                                    </div>
                                    :
                                    <>
                                        <div className={styles.registerInputMain}>
                                            Nueva contraseña:*
                                            <div className={styles.showPasswordInput}>
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    className={styles.passwordinput}
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                />
                                                <img
                                                    src={HideIcn}
                                                    alt={showPassword ? "Hide password" : "Show password"}
                                                    className={styles.hideIcn}
                                                    onClick={togglePasswordVisibility}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div>
                                            {errors.password && <span className={styles.errorMessage}>{errors.password}</span>}
                                        </div>
                                        <div className={styles.registerInputMain}>
                                            Confirmar Nueva contraseña:*
                                            <div className={styles.showPasswordInput}>
                                                <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    name="confirmPassword"
                                                    className={styles.passwordinput}
                                                    value={formData.confirmPassword}
                                                    onChange={handleChange}
                                                />
                                                <img
                                                    src={HideIcn}
                                                    alt={showConfirmPassword ? "Hide password" : "Show password"}
                                                    className={styles.hideIcn}
                                                    onClick={toggleConfirmPasswordVisibility}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div>
                                            {errors.confirmPassword && <span className={styles.errorMessage}>{errors.confirmPassword}</span>}
                                        </div>
                                        {matchingError && <span className={styles.errorMessage}>{matchingError}</span>}
                                        <div className={styles.RegBtnIcnMain}>
                                            <button
                                                onClick={handleSubmit}
                                                className={styles.RegBtnIcn2}
                                            >
                                                <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
                                                <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
                                                <span>ENVIAR</span>
                                            </button>
                                        </div>
                                    </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default UpdatePassword
