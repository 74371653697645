import React, { useEffect, useState } from 'react'
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";

import editIcon from '../../../Assets/edit-icon.svg';
import blockIcon from '../../../Assets/block-icon.svg';
import eliminateIcon from '../../../Assets/eliminate-icon.svg';
import axiosInstance from '../../../services/axios';
import { apiRoutes, generalError, toastTypes, userRolesMap, userStatus, userStatusMap } from '../../../app.constants';
import { showToast } from '../../Shared/CustomToast';
import Spinner from '../../Shared/Spinner';
import { Assets } from '../../../Assets';
import { Checkbox } from '@mui/material';

const UserAdministration = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [originalUsers, setOriginalUsers] = useState([]); // Store original user data
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const { data } = await axiosInstance.get(apiRoutes.user);
            const usersData = data.users.map(user => ({ ...user, isEditing: false, isSelected: false }));
            setUsers(usersData);
            setOriginalUsers(data.users);
        } catch (error) {
            showToast(toastTypes.error, generalError);
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteUser = async (e) => {
        e.preventDefault();
        const selectedUserIds = users.filter(user => user.isSelected).map(user => user._id);
        try {
            await axiosInstance.post(apiRoutes.deleteUsers, { selectedUserIds });
            setUsers(users.filter(user => !selectedUserIds.includes(user._id)));
            showToast(toastTypes.success, 'Los usuarios seleccionados se eliminaron correctamente');
        } catch (error) {
            showToast(toastTypes.error, generalError);
            console.error('Error deleting users:', error);
        }
    };

    const handleUpdateUser = async (e, action) => {
        e.preventDefault();
        let selectedUsers = users.filter(user => user.isSelected)
        if (action === 'block') {
            selectedUsers = selectedUsers.map(user => ({ ...user, status: userStatus.blocked }));
        }
        if (action === 'unblock') {
            selectedUsers = selectedUsers.map(user => ({ ...user, status: userStatus.active }));
        }
        try {
            const { data } = await axiosInstance.patch(apiRoutes.user, { selectedUsers });
            setIsEditing(false);
            await fetchUsers();
            showToast(toastTypes.success, 'Los usuarios seleccionados se actualizaron correctamente');
        } catch (error) {
            showToast(toastTypes.error, generalError);
            console.error('Error updating users:', error);
        }
    };

    const handleChange = (e, userId) => {
        const { name, value } = e.target;
        setUsers(users => users.map(user => user._id === userId ? { ...user, [name]: value } : user))
    };

    const onEditAndCancel = () => {
        if (isEditing) {
            setUsers(users => users.map((user, index) => {
                return user.isSelected ? { ...user, ...originalUsers[index], isEditing: !user.isEditing } : user;
            }));
        } else {
            setUsers(users => users.map(user => {
                return user.isSelected ? { ...user, isEditing: !user.isEditing } : user;
            }));
        }
        setIsEditing(!isEditing);
    }

    const handleSelect = (e, userId) => {
        let updatedUsers = users.map((cUser, index) => cUser?._id === userId ? { ...cUser, ...originalUsers[index], isSelected: !cUser.isSelected } : cUser);
        if (isEditing) {
            updatedUsers = updatedUsers.map(cUser => cUser?._id === userId ? { ...cUser, isEditing: !cUser.isEditing } : cUser);
        }
        if (!updatedUsers.some(user => user.isSelected)) {
            setIsEditing(false);
        }
        setUsers(updatedUsers);
    }

    return (loading ? <Spinner /> :
        <>
            <div className='mb-2  mt-5 flex gap-4 items-center justify-around'>
                <button
                    type="submit"
                    disabled={!users.some(user => user.isSelected) || isEditing}
                    onClick={() => onEditAndCancel()}
                    className={`bg-green-800 flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white px-3 py-2 text-white hover:bg-[#70a950]`}
                >
                    <img src={editIcon} alt="" />
                    <span className="font-russo ">{isEditing ? 'EDICIÓN' : 'EDITAR'}</span>
                </button>
                <button
                    type="submit"
                    disabled={!users.some(user => user.isSelected)}
                    onClick={(e) => handleUpdateUser(e, 'block')}
                    className="flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-[#70a950]"
                >
                    <img src={blockIcon} alt="" />
                    <span className="font-russo ">BLOQUEAR</span>
                </button>
                <button
                    type="submit"
                    disabled={!users.some(user => user.isSelected)}
                    onClick={(e) => handleUpdateUser(e, 'unblock')}
                    className="flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-[#70a950]"
                >
                    <img src={Assets.UnblockIcon} alt="" className='w-7' />
                    <span className="font-russo ">DESBLOQUEAR</span>
                </button>
                <button
                    type="submit"
                    disabled={!users.some(user => user.isSelected) || isEditing}
                    onClick={(e) => handleDeleteUser(e)}
                    className="flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-[#70a950]"
                >
                    <img src={eliminateIcon} alt="" />
                    <span className="font-russo ">ELIMINAR</span>
                </button>
            </div>

            <div className="mx-auto w-full max-w-[90%] overflow-x-auto bg-white text-center text-xs sm:text-base">
                <table className="min-w-full rounded-lg border border-gray-300 shadow-lg">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                            </th>
                            <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                                #
                            </th>
                            <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                                Tipo
                            </th>
                            <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                                Nombre(s)
                            </th>
                            <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                                Apellido(s)
                            </th>
                            <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                                Correo Electrónico
                            </th>
                            <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                                Estado
                            </th>
                            {/* <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                                Contraseña
                            </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={user._id}>
                                <td className="border border-gray-600 py-1 sm:px-2">
                                    <Checkbox
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#025624',
                                            },
                                            p: 0
                                        }}
                                        size='small'
                                        checked={user.isSelected}
                                        onChange={(e) => handleSelect(e, user._id)}
                                    />
                                </td>
                                <td className="border border-gray-600 py-1 sm:px-2">
                                    {index + 1}
                                </td>
                                <td className={`border border-gray-600 ${!user.isEditing ? 'py-1 sm:px-2' : ''}`}>
                                    {user.isEditing
                                        ? <input
                                            autoFocus
                                            className='w-full px-1 bg-gray-100 border'
                                            type='text'
                                            value={user?.role}
                                            name='role'
                                            onChange={(e) => handleChange(e, user._id)} />
                                        : userRolesMap[user?.role]}
                                </td>
                                <td className={`border border-gray-600 ${!user.isEditing ? 'py-1 sm:px-2' : ''}`}>
                                    {user.isEditing
                                        ? <input
                                            autoFocus
                                            className='w-full px-1 bg-gray-100 border'
                                            type='text'
                                            value={user?.fullname}
                                            name='fullname'
                                            onChange={(e) => handleChange(e, user._id)} />
                                        : user?.fullname}
                                </td>
                                <td className={`border border-gray-600 ${!user.isEditing ? 'py-1 sm:px-2' : ''}`}>
                                    {user.isEditing
                                        ? <input
                                            autoFocus
                                            className='w-full px-1 bg-gray-100 border'
                                            type='text'
                                            value={user?.surname}
                                            name='surname'
                                            onChange={(e) => handleChange(e, user._id)} />
                                        : user?.surname}
                                </td>
                                <td className={`border border-gray-600 ${!user.isEditing ? 'py-1 sm:px-2' : ''}`}>
                                    {user.isEditing
                                        ? <input
                                            autoFocus
                                            className='w-full px-1 bg-gray-100 border'
                                            type='email'
                                            value={user?.email}
                                            name='email'
                                            onChange={(e) => handleChange(e, user._id)} />
                                        : user?.email}
                                </td>
                                <td className={`border-black-600 border py-1 sm:px-2`}>
                                    <span className={`px-2 py-1 rounded-full text-xs ${user?.status === userStatus.active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                        }`}>
                                        {userStatusMap[user?.status]}
                                    </span>
                                </td>
                                {/* <td className="border border-gray-300 py-1 sm:px-2">
                                    <div className="flex items-center justify-between">
                                        {showPassword ? user.password : '*'.repeat(user.password.length)}
                                        <span className="cursor-pointer" onClick={() => setShowPassword((open) => !open)}>
                                            {!showPassword ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                                    </div>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isEditing && users.some(user => user.isSelected) &&
                <div className='flex gap-5'>
                    <button
                        type="submit"
                        onClick={(e) => handleUpdateUser(e)}
                        className="mb-16 flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-green-700"
                    >
                        <img src={require("../../../Assets/buttonIcon.png")} alt="" />
                        <span className="font-russo text-lg">GUARDAR</span>
                    </button>
                    <button
                        className="btnBorder p-2 w-28 items-center justify-center gap-2 rounded-lg bg-[#D9D9D9] hover:bg-green-600 hover:text-white roboto-slab text-[10px] leading-[12px] text-black lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]"
                        onClick={() => onEditAndCancel()}
                    >
                        CANCELAR
                    </button>
                </div>}
        </>
    )
}

export default UserAdministration