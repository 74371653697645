import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../Register/Register.module.css";
import RegIcn from "../../Images/RegIcn.png";
import HideIcn from "../../Images/hideIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import RegStep1 from "../../Images/regstep1.png";
import styles2 from "./RegisterMain.module.css";
import axiosInstance from "../../services/axios";
import { apiRoutes, signupError, toastTypes } from "../../app.constants";
import { useAuth } from "../../Hoc/AuthContext";
import { showToast } from "../Shared/CustomToast";

const RegisterMain = () => {
  const [isSignupChecked, setIsSignupChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [formData, setFormData] = useState({
    signupEmail: "",
    password: "",
    confirmPassword: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [emailError, setEmailError] = useState(""); // Email-specific errors
  const [isLegalNoticeChecked, setIsLegalNoticeChecked] = useState(true); // Legal notice checkbox
  const { isAuthenticated, signup } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate('/system/register-doc'); // User already completed step-1
  //   }
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "signupEmail") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsEmailValid(emailPattern.test(value));
    }
  };

  const handleSignupCheckboxChange = () => {
    setIsSignupChecked((prev) => !prev);
    setIsLegalNoticeChecked(true); // Reset error message on change
  };

  const handleSignup = async () => {
    const { signupEmail, password, confirmPassword } = formData;

    // Validate email format
    if (!isEmailValid) {
      setEmailError(
        "El correo electrónico no cumple con el formato correcto. Favor de corregir."
      );
      return;
    }

    // Validate password match
    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    // Check if terms and conditions are approved
    if (!isSignupChecked) {
      setIsLegalNoticeChecked(false); // Show the error message
      return;
    }

    try {
      const response = await axiosInstance.post(
        apiRoutes.auth.signupStep1,
        {
          email: signupEmail,
          password: password,
          role: "provider",
        }
      );

      signup(response?.data);
      navigate("/system/register-doc");
    } catch (error) {
      if (error.response?.data.message === "Email is already in use") {
        setEmailError(
          <>
            Este email ya fue registrado. Favor de intentar:{" "}
            <Link to="/system/login" style={{ textDecoration: 'underline' }}>INICIAR SESIÓN</Link>
          </>
        );
      } else {
        showToast(toastTypes.error, signupError);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  return (
    <div className={styles.registerContainer}>
      <div className={styles.registerContainer2}>
        <div className={styles.registerRightMain}>
          <div className={styles.registerMainHeading}>
            <img src={RegIcn} alt="" className={styles.regIcn} />
            Registro de Usuario
          </div>
          <div className={styles.regStepMain}>
            <img src={RegStep1} alt="" className={styles.regStep1} />
          </div>
          <div className={styles.registerInputMain}>
            Correo electrónico:*
            <input
              type="email"
              name="signupEmail"
              className={`${styles.inputRegister} ${!isEmailValid || emailError ? styles2.invalidInput : ""
                }`}
              value={formData.signupEmail}
              onChange={handleChange}
            />
            {!isEmailValid && (
              <span className={styles2.errorMessage}>
                El correo electrónico no cumple con el formato correcto. Favor
                de corregir.
              </span>
            )}
            {emailError && (
              <span className={styles2.errorMessage}>{emailError}</span>
            )}
          </div>
          <div className={styles.registerInputMain}>
            Contraseña:*
            <div className={styles.showPasswordInput}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className={`${styles.passwordinput} ${!passwordsMatch ? styles2.invalidInput : ""
                  }`}
                value={formData.password}
                onChange={handleChange}
              />
              <img
                src={HideIcn}
                alt={showPassword ? "Hide password" : "Show password"}
                className={styles.hideIcn}
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              />
            </div>
            {!passwordsMatch && (
              <span className={styles2.errorMessage}>
                Las contraseñas no coinciden, favor de corregirlas.
              </span>
            )}
          </div>
          <div className={styles.registerInputMain}>
            Confirmar Contraseña:*
            <div className={styles.showPasswordInput}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                className={`${styles.passwordinput} ${!passwordsMatch ? styles2.invalidInput : ""
                  }`}
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <img
                src={HideIcn}
                alt={showConfirmPassword ? "Hide password" : "Show password"}
                className={styles.hideIcn}
                onClick={toggleConfirmPasswordVisibility}
                style={{ cursor: "pointer" }}
              />
            </div>
            {!passwordsMatch && (
              <span className={styles2.errorMessage}>
                Las contraseñas no coinciden, favor de corregirlas.
              </span>
            )}
          </div>
          <div className={styles.registerCheckbox}>
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={isSignupChecked}
                onChange={handleSignupCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>
            <div className={styles.registerCheckboxText}>
              Consiento que mis datos personales sensibles sean tratados
              conforme a los términos y condiciones del presente{" "}
              <Link to={"/system/terms"}>
                <span>Términos y condiciones.</span>
              </Link>
            </div>
          </div>
          {!isLegalNoticeChecked && (
            <span className={styles2.errorMessage}>
              Favor de leer los términos y condiciones y aprobarlos en la
              casilla para poder proceder.
            </span>
          )}
          <div className={styles.RegBtnIcnMain}>
            <button
              onClick={handleSignup}
              className={styles.RegBtnIcn2}
              disabled={!passwordsMatch}
            >
              <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
              <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
              <span>ENVIAR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterMain;
