// CustomToast.js
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Custom toast styles
const toastOptions = {
    position: "top-right",
    autoClose: 6000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "light",
    style: {
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        fontSize: '14px',
    }
};

const toastStyles = {
    success: {
        background: '#ecfdf5',
        color: '#065f46',
        border: '1px solid #34d399',
    },
    error: {
        background: '#fef2f2',
        color: '#991b1b',
        border: '1px solid #f87171',
    },
    info: {
        background: '#eeee',
        color: '#000',
        border: '1px solid #60a5fa',
    },
    warning: {
        background: '#fffbeb',
        color: '#92400e',
        border: '1px solid #fbbf24',
    }
}

export const showToast = (type, content) => {
    toast(content, {
        ...toastOptions,
        style: {
            ...toastOptions.style,
            ...toastStyles[type]
        },
        progressStyle: {
            background: '#34d399',
        },
    });
}

// ToastContainer component with custom styling
export const CustomToastContainer = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={6000}
            limit={3}
            hideProgressBar={true}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    );
};