import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "./RegisterGou.module.css";
import Work2 from "../../Images/Work2.png";
import RegGoubtn from "../../Images/regGouBtn.png";
import FileUpIcn from "../../Images/fileupIcn.png";
import { apiRoutes, days, generalError, months, projectTypes, projectTypesMap, toastTypes, years } from "../../app.constants";
import axiosInstance from "../../services/axios";
import generalLogo from '../../Assets/logo-general-proj.svg';
import constructionLogo from '../../Assets/logo-construction-proj.svg';
import itLogo from '../../Assets/logo-IT-proj.svg';
import SupportSchedule from "../SupportSchedule";
import Spinner from "../Shared/Spinner";
import { showToast } from "../Shared/CustomToast";

const RegisterGou = () => {
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({ date: '' });
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [references, setReferences] = useState([
    {
      name: '',
      surname: '',
      email: '',
      phone: ''
    },
    {
      name: '',
      surname: '',
      email: '',
      phone: ''
    },
    {
      name: '',
      surname: '',
      email: '',
      phone: ''
    },
    {
      name: '',
      surname: '',
      email: '',
      phone: ''
    },
    {
      name: '',
      surname: '',
      email: '',
      phone: ''
    },
  ]);

  const [formData, setFormData] = useState({
    project: "",
    amount: "",
    unit: "",
    charachteristics: "",
    specialSpecifications: "",
    unitPrice: "",
    totalPrice: "",
    warranties: "",
    isWarranty: false,
    isSupportProvided: false,
    supportDescription: "",
    supportSchedule: null,
    timezone: null,
    day: "",
    month: "",
    year: "",
    deliveryLocation: "",
    dateOfDelivery: "",
    bail: '',
    files: [],
    isMaintenanceProvided: false,
    maintenanceDescription: '',
    isReferenceProvided: false,
    isBailProvided: false
  });

  const typeToLogoMap = {
    [projectTypes.general]: generalLogo,
    [projectTypes.IT]: itLogo,
    [projectTypes.construction]: constructionLogo,
  }

  let queryParams = new URLSearchParams(location.search);
  let projectId = queryParams.get('id');

  useEffect(() => {
    setFormData({ project: projectId })
    const fetchProject = async () => {
      try {
        const { data } = await axiosInstance.get(`${apiRoutes.project.getById}/${projectId}`);
        const project = data?.data?.project;
        setProject(project);
      } catch (error) {
        console.error("Error fetching data:", error.response ? error?.response?.data : error?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchProject();
  }, [])


  const getRequiredFields = () => {
    const requiredFields = [
      "amount",
      "unit",
      "charachteristics",
      "specialSpecifications",
      "unitPrice",
      "totalPrice",
      "isWarranty",
      "isSupportProvided",
      "deliveryLocation",
      "files",
    ];

    if (formData.isWarranty) {
      requiredFields.push("warranties");
    }
    if (formData.isSupportProvided) {
      requiredFields.push("supportDescription", "supportSchedule", "timezone");
    }
    if (project?.projectType === projectTypes.construction) {
      requiredFields.push("isReferenceProvided", "isBailProvided");
      if (formData.isBailProvided) {
        requiredFields.push("bail");
      }
    }
    if (project?.projectType === projectTypes.IT) {
      requiredFields.push("isMaintenanceProvided");
    }
    if (formData.isMaintenanceProvided) {
      requiredFields.push("maintenanceDescription");
    }

    return requiredFields;
  }

  const handleReferenceChange = (e, index) => {
    const { name, value } = e.target;
    setReferences(prevState => {
      const newState = prevState.map((ref, refIndex) => refIndex === index ? { ...ref, [name]: value } : ref);

      if (formData.isReferenceProvided && newState.some(ref => ref.name && ref.surname && ref.email && ref.phone)) {
        setErrors(prev => ({
          ...prev,
          isReferenceProvided: ""
        }));
      }
      return newState;
    });

    // Validate email format
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setErrors(prev => ({
          ...prev,
          [`reference${index}Email`]: "Por favor ingrese un correo electrónico válido"
        }));
        return;
      } else {
        setErrors(prev => ({
          ...prev,
          [`reference${index}Email`]: ""
        }));
      }
    }

    // Validate phone number format
    if (name === 'phone') {
      const phoneRegex = /^\+?[\d\s-]{10,}$/;
      if (!phoneRegex.test(value)) {
        setErrors(prev => ({
          ...prev,
          [`reference${index}Phone`]: "Por favor ingrese un número de teléfono válido"
        }));
        return;
      } else {
        setErrors(prev => ({
          ...prev,
          [`reference${index}Phone`]: ""
        }));
      }
    }
  };


  const monthToNumber = (month) => {
    const months = {
      Enero: '01', Febrero: '02', Marzo: '03', Abril: '04', Mayo: '05', Junio: '06',
      Julio: '07', Agosto: '08', Septiembre: '09', Octubre: '10', Noviembre: '11', Diciembre: '12',
    };
    return months[month] || '01';
  };

  const formatDate = (dateObj) => {
    const { year, month, day } = dateObj;
    if (!year || !month || !day) {
      console.error(`Incomplete date: year=${year}, month=${month}, day=${day}`);
      return null; // Return null if date parts are missing
    }
    const formattedMonth = monthToNumber(month);
    const formattedDay = String(day).padStart(2, '0');
    const dateStr = `${year}-${formattedMonth}-${formattedDay}`;
    const date = new Date(dateStr);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const isBooleanValue = ["isWarranty", "isSupportProvided", "isMaintenanceProvided", "isReferenceProvided", "isBailProvided"].includes(name);
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: isBooleanValue ? value === 'true' : value,
      }

      if (newData.month === 'Febrero' && parseInt(newData.day) > 28) {
        setErrors({
          ...errors,
          date: 'Esta fecha no existe, favor de corregirla'
        });
      } else {
        setErrors({
          ...errors,
          date: ''
        });
      }

      if (newData.day && newData.month && newData.year) {

        // Check if date is in the past
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time portion for date comparison
        const fullDate = formatDate({
          day: newData.day,
          month: newData.month,
          year: newData.year
        });

        if (fullDate && fullDate < today) {
          setErrors({ ...errors, date: "No puede seleccionar una fecha del pasado, favor de corregir" });
          return newData;
        }
        setErrors({ ...errors, date: '' });
      }

      // Clear error when field has value
      if (!isBooleanValue) {

        setErrors(prev => ({
          ...prev,
          [name]: value ? "" : prev[name]
        }));

        // Validate required fields
        if (getRequiredFields().includes(name) && !value) {
          setErrors(prev => ({
            ...prev,
            [name]: "El campo superior es obligatorio y debe ser llenado para poder proceder."
          }));
        }
      } else {
        if (value !== 'true' && value !== 'false') {
          setErrors(prev => ({
            ...prev,
            [name]: "El campo superior es obligatorio y debe ser llenado para poder proceder."
          }));
        } else {
          setErrors(prev => ({
            ...prev,
            [name]: ""
          }));
        }
      }

      return newData;
    });
  };

  const validateInput = () => {
    const newErrors = {};
    getRequiredFields().map((field) => {
      const isBooleanValue = ["isWarranty", "isSupportProvided", "isMaintenanceProvided", "isReferenceProvided", "isBailProvided"].includes(field);
      if (!isBooleanValue) {
        if (!formData[field]) {
          newErrors[field] = "El campo superior es obligatorio y debe ser llenado para poder proceder.";
        }
        if ((field === 'timezone' || field === 'supportSchedule') && !formData[field]) {
          newErrors[field] = "Como se especificó que se ofrece el soporte se tiene que especificar la zona horaria con días y horario de soporte."
        }
      } else {
        if (formData[field] !== true && formData[field] !== false) {
          newErrors[field] = "El campo superior es obligatorio y debe ser llenado para poder proceder.";
        }
      }
    });

    // Check for missing or invalid files
    if (!formData?.files?.length) {
      setErrorMessage("Favor de ingresar su cotización en formato pdf para poder proceder.");
    } else {
      newErrors.files = "";
    }

    return newErrors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateInput();

    const { day, month, year, ...rest } = formData;
    if (!day || !month || !year) {
      newErrors.date = 'Por favor seleccione una fecha de entrega';
    }
    // Convert month name to index (0-based)
    const monthIndex = months.indexOf(month);

    if (formData.isReferenceProvided && !references.some(ref => ref.name && ref.surname && ref.email && ref.phone)) {
      newErrors.isReferenceProvided = 'Proporcione al menos una referencia';
    }


    if (Object.keys(newErrors).some(key => newErrors[key]) || errorMessage) {
      const updated = { ...errors, ...newErrors };
      setErrors(updated);
      return;
    }

    if (Object.values(errors).some(val => val)) {
      return;
    }

    const deliveryDate = new Date(year, monthIndex, day);
    const formDataToSubmit = {
      ...rest,
      dateOfDelivery: deliveryDate.toLocaleDateString(),
      references: rest.isReferenceProvided ? JSON.stringify(references.filter(ref => ref.name && ref.surname && ref.email && ref.phone)) : []
    };

    const formDataToSend = new FormData();
    Object.entries(formDataToSubmit).forEach(([key, value]) => {
      if (key === "files") {
        value.forEach((file) => formDataToSend.append("files", file));
      } else if (key === 'supportSchedule' || key === 'timezone') {
        formDataToSend.append(key, JSON.stringify(value));
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const { data } = await axiosInstance.post(apiRoutes.bidding.create, formDataToSend);
      showToast(toastTypes.success, 'Propuesta enviada exitosamente.');
      navigate(-1);
    } catch (error) {
      console.error("Error submitting form:", error);
      showToast(toastTypes.error, generalError);
    }
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setErrorMessage("Favor de ingresar su cotización en formato pdf para poder proceder.");
      return;
    }

    if (file.type !== "application/pdf") {
      setErrorMessage("Sólo se permiten archivos PDF.");
      return;
    }

    if (file.size > 150 * 1024) {
      setErrorMessage("El tamaño del archivo no debe exceder los 150 KB.");
      return;
    }

    setErrorMessage(""); // Clear any previous error message
    setFileName(file.name);

    setFormData((prevState) => ({
      ...prevState,
      files: [...(prevState.files || []), file],
    }));
  };

  return loading ? <Spinner /> : (
    <div className={styles.registerGouMain}>
      <div className={styles.registerGouContainer}>
        <div className={styles.registerGouContainer2}>
          <div className={styles.homeSection2Head}>
            <div className="flex content-center items-center justify-center gap-4 text-[#025624] font-roboto p-8 text-3xl mt-10 font-bold">
              <img src={typeToLogoMap[project?.projectType]} alt="" className={styles.Work2Img} />
              {projectTypesMap[project?.projectType]?.toUpperCase()}
            </div>
          </div>
          <div className={styles.homeSection2Head}>
            <div className={styles.workMainHeading}>
              <img src={Work2} alt="" />
              Oferta de Licitación para {project?.title}
            </div>
          </div>
          <div className={styles.registerGouSection2Main}>
            <div className={styles.registerInputMain2}>
              <div className={styles.inputRegisterLeft}>
                Cantidad:*
                <input
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
                {errors.amount && <div className={styles.errorMessage}>{errors.amount}</div>}

              </div>
              <div className={styles.inputRegisterRight}>
                Unidad:*
                <input
                  type="text"
                  name="unit"
                  value={formData.unit}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
                {errors.unit && <div className={styles.errorMessage}>{errors.unit}</div>}
              </div>
            </div>
            <div className={styles.regDocmainText}>Características:*</div>
            <textarea
              name="charachteristics"
              value={formData.charachteristics}
              onChange={handleChange}
              className={styles.registerTextarea}
            ></textarea>
            {errors.charachteristics && <div className={styles.errorMessage}>{errors.charachteristics}</div>}
            <div className={styles.regDocmainText}>Especificaciones Especiales:*</div>
            <textarea
              name="specialSpecifications"
              value={formData.specialSpecifications}
              onChange={handleChange}
              className={styles.registerTextarea2}
            ></textarea>
            {errors.specialSpecifications && <div className={styles.errorMessage}>{errors.specialSpecifications}</div>}
            <div className={styles.registerInputMain2}>
              <div className={styles.inputRegisterLeft}>
                Precio unitario: (Tipo de moneda: MXN)*
                <input
                  type="number"
                  name="unitPrice"
                  value={formData.unitPrice}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
                {errors.unitPrice && <div className={styles.errorMessage}>{errors.unitPrice}</div>}
              </div>
              <div className={styles.inputRegisterRight}>
                Precio Total: (Antes de impuestos)*
                <input
                  type="number"
                  name="totalPrice"
                  value={formData.totalPrice}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
                {errors.totalPrice && <div className={styles.errorMessage}>{errors.totalPrice}</div>}
              </div>
            </div>

            {/* Bail - for construction projects*/}
            {project?.projectType === projectTypes.construction &&
              <>
                <div className="flex justify-between">
                  <div className={styles.regDocmainText}>Fianza:*</div>
                  <div className="flex w-[80%] justify-between items-end pr-5">
                    <label className="flex items-center">
                      <input
                        required
                        className="w-5 h-5 rounded-full"
                        type="radio"
                        name="isBailProvided"
                        value={true}
                        checked={formData.isBailProvided === true}
                        onChange={handleChange}
                      />
                      <span className="ml-4">Sí</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        required
                        className="w-5 h-5 rounded-full"
                        type="radio"
                        name="isBailProvided"
                        value={false}
                        checked={formData.isBailProvided === false}
                        onChange={handleChange}
                      />
                      <span className="ml-4">No</span>
                    </label>
                  </div>
                </div>
                {errors.isBailProvided && <div className={styles.errorMessage}>{errors.isBailProvided}</div>}
                {formData.isBailProvided &&
                  <div className="flex gap-10 items-center mt-7 mr-5">
                    <div className={styles.inputRegisterLeft}>
                      Fianza (%):* (Porcentaje del monto total de inversión)
                    </div>
                    <div className="flex gap-2 items-center">
                      <input
                        type="number"
                        name="bail"
                        value={formData.bail}
                        onChange={handleChange}
                        className={styles.bailInput}
                      />
                      <span>%</span>
                    </div>
                  </div>}
                {errors.bail && <div className={styles.errorMessage}>{errors.bail}</div>}
              </>
            }

            {project?.projectType === projectTypes.IT &&
              <>
                <div className="flex justify-between">
                  <div className={styles.regDocmainText}>Mantenimiento:*</div>
                  <div className="flex w-[80%] justify-between items-end pr-5">
                    <label className="flex items-center">
                      <input
                        required
                        className="w-5 h-5 rounded-full"
                        type="radio"
                        name="isMaintenanceProvided"
                        value={true}
                        checked={formData.isMaintenanceProvided === true}
                        onChange={handleChange}
                      />
                      <span className="ml-4">Sí</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        required
                        className="w-5 h-5 rounded-full"
                        type="radio"
                        name="isMaintenanceProvided"
                        value={false}
                        checked={formData.isMaintenanceProvided === false}
                        onChange={handleChange}
                      />
                      <span className="ml-4">No</span>
                    </label>
                  </div>
                </div>
                {errors.isMaintenanceProvided && <div className={styles.errorMessage}>{errors.isMaintenanceProvided}</div>}
                {formData.isMaintenanceProvided && <textarea
                  name="maintenanceDescription"
                  value={formData.maintenanceDescription}
                  onChange={handleChange}
                  className={styles.registerTextarea3}
                ></textarea>
                }
                {errors.maintenanceDescription && <div className={styles.errorMessage}>{errors.maintenanceDescription}</div>}
              </>
            }

            <div className="flex justify-between">
              <div className={styles.regDocmainText}>Garantías:*</div>
              <div className="flex w-[80%] justify-between items-end pr-5">
                <label className="flex items-center">
                  <input
                    required
                    className="w-5 h-5 rounded-full"
                    type="radio"
                    name="isWarranty"
                    value={true}
                    checked={formData.isWarranty === true}
                    // onChange={(e) => setFormData(prev => ({ ...prev, isWarranty: true }))}
                    onChange={handleChange}
                  />
                  <span className="ml-4">Sí</span>
                </label>
                <label className="flex items-center">
                  <input
                    required
                    className="w-5 h-5 rounded-full"
                    type="radio"
                    name="isWarranty"
                    value={false}
                    checked={formData.isWarranty === false}
                    onChange={handleChange}
                  />
                  <span className="ml-4">No</span>
                </label>
              </div>
            </div>
            {errors.isWarranty && <div className={styles.errorMessage}>{errors.isWarranty}</div>}
            {formData.isWarranty &&
              <textarea
                name="warranties"
                value={formData.warranties}
                onChange={handleChange}
                className={styles.registerTextarea3}
              ></textarea>
            }
            {errors.warranties && <div className={styles.errorMessage}>{errors.warranties}</div>}

            <div className="flex justify-between">
              <div className={styles.regDocmainText}>Soporte:*</div>
              <div className="flex w-[80%] justify-between items-end pr-5">
                <label className="flex items-center">
                  <input
                    required
                    className="w-5 h-5 rounded-full"
                    type="radio"
                    name="isSupportProvided"
                    value={true}
                    checked={formData?.isSupportProvided === true}
                    onChange={handleChange}
                  />
                  <span className="ml-4">Sí</span>
                </label>
                <label className="flex items-center">
                  <input
                    required
                    className="w-5 h-5 rounded-full"
                    type="radio"
                    name="isSupportProvided"
                    value={false}
                    checked={formData?.isSupportProvided === false}
                    onChange={handleChange}
                  />
                  <span className="ml-4">No</span>
                </label>
              </div>
            </div>
            {errors.isSupportProvided && <div className={styles.errorMessage}>{errors.isSupportProvided}</div>}
            {formData.isSupportProvided &&
              <textarea
                name="supportDescription"
                value={formData?.supportDescription}
                onChange={handleChange}
                className={styles.registerTextarea3}
              ></textarea>
            }
            {errors.supportDescription && <div className={styles.errorMessage}>{errors.supportDescription}</div>}

            {formData?.isSupportProvided && <SupportSchedule setFormData={setFormData} setFormErrors={setErrors} formErrors={errors} />}
            {errors.supportSchedule && <div className={styles.errorMessage}>{errors.supportSchedule}</div>}

            {/* Refrences - for construction project */}
            {project.projectType === projectTypes.construction &&
              <>
                <div className="flex justify-between">
                  <div className={styles.regDocmainText}>Referencias:</div>
                  <div className="flex w-[80%] justify-between items-end pr-5">
                    <label className="flex items-center">
                      <input
                        required
                        className="w-5 h-5 rounded-full"
                        type="radio"
                        name="isReferenceProvided"
                        value={true}
                        checked={formData?.isReferenceProvided === true}
                        onChange={handleChange}
                      />
                      <span className="ml-4">Sí</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        required
                        className="w-5 h-5 rounded-full"
                        type="radio"
                        name="isReferenceProvided"
                        value={false}
                        checked={formData?.isReferenceProvided === false}
                        onChange={handleChange}
                      />
                      <span className="ml-4">No</span>
                    </label>
                  </div>
                </div>
                {errors.isReferenceProvided && <div className={styles.errorMessage}>{errors.isReferenceProvided}</div>}
              </>
            }

            {project.projectType === projectTypes.construction && formData.isReferenceProvided &&
              references.map((reference, index) => (
                <div key={index} className="flex flex-col mt-4 mr-4">
                  <div className={styles.secondaryLable}>
                    Referencia {index + 1}:
                  </div>
                  <div className="flex gap-10">
                    <div className="flex flex-col gap-2 w-full">
                      <div className={styles.secondaryLable}>
                        Nombre Completo:
                      </div>
                      <input
                        type="text"
                        name="name"
                        value={reference.name}
                        onChange={(e) => handleReferenceChange(e, index)}
                        className={`${styles.bailInput} ${styles.referenceInput}`}
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <div className={styles.secondaryLable}>
                        Apellidos:
                      </div>
                      <input
                        type="text"
                        name="surname"
                        value={reference.surname}
                        onChange={(e) => handleReferenceChange(e, index)}
                        className={`${styles.bailInput} ${styles.referenceInput}`}
                      />
                    </div>
                  </div>
                  <div className="flex gap-10">
                    <div className="flex flex-col gap-2 w-full">
                      <div className={styles.secondaryLable}>
                        Email:
                      </div>
                      <input
                        type="email"
                        name="email"
                        value={reference.email}
                        onChange={(e) => handleReferenceChange(e, index)}
                        className={`${styles.bailInput} ${styles.referenceInput}`}
                      />
                      {errors[`reference${index}Email`] && <div className={styles.errorMessage}>{errors[`reference${index}Email`]}</div>}
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <div className={styles.secondaryLable}>
                        Teléfono:
                      </div>
                      <input
                        type="text"
                        name="phone"
                        value={reference.phone}
                        onChange={(e) => handleReferenceChange(e, index)}
                        className={`${styles.bailInput} ${styles.referenceInput}`}
                      />
                      {errors[`reference${index}Phone`] && <div className={styles.errorMessage}>{errors[`reference${index}Phone`]}</div>}
                    </div>
                  </div>
                </div>
              ))
            }

            <div className={styles.regDocmainText}>Fecha de entrega del bien o servicio:*</div>
            <div className={styles.selecterMain}>
              <select
                name="day"
                value={formData.day}
                onChange={handleChange}
                className={styles.selecterInput}
              >
                <option value="">Día:</option>
                {days.map((d) => (
                  <option key={d} value={d}>{d}</option>
                ))}
              </select>
              <select
                name="month"
                value={formData.month}
                onChange={handleChange}
                className={styles.selecterInput2}
              >
                <option value="">Mes:</option>
                {months.map((m, index) => (
                  <option key={index} value={m}>{m}</option>
                ))}
              </select>
              <select
                name="year"
                value={formData.year}
                onChange={handleChange}
                className={styles.selecterInput2}
              >
                <option value="">Año:</option>
                {years.map((y) => (
                  <option key={y} value={y}>{y}</option>
                ))}
              </select>
            </div>
            {errors?.date && <p className={styles.errorMessage}>{errors.date}</p>}
            <div className={styles.registerInputMain}>
              Lugar de entrega:*
              <input
                type="text"
                name="deliveryLocation"
                value={formData.deliveryLocation}
                onChange={handleChange}
                className={styles.inputRegister}
              />
              {errors.deliveryLocation && <div className={styles.errorMessage}>{errors.deliveryLocation}</div>}
            </div>
            <div className={styles.regDocmainText}>Cotización: Favor de integrar en documento pdf de máximo 150 kb:*</div>
            {/* File Upload 1 */}
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload1"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, 'quote')}
                style={{ display: "none" }}
                accept=".pdf"
              />
              <span className={styles.fileName}>{fileName}</span>
              <label htmlFor="fileUpload1" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

            <div className={styles.RegBtnIcnMain}>
              <button className={styles.RegBtnIcn2} type="submit" onClick={(e) => handleSubmit(e)}>
                <img src={RegGoubtn} alt="" className={styles.RegGoubtn} />
                <span>OFERTAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterGou;
