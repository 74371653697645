import styles from "./ForgotPass.module.css"
import RegIcn from "../../Images/RegIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiRoutes, generalError, toastTypes, userRoles } from "../../app.constants";
import axiosInstance from "../../services/axios";
import { useAuth } from "../../Hoc/AuthContext";
import { showToast } from "../Shared/CustomToast";

const ForgotPass = () => {
  const [email, setEmail] = useState('');
  const [requestSent, setRequestSent] = useState(false);
  const [error, setError] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    setError(!value ? 'Este es un campo obligatorio.' : '');
    if (value) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      console.log(emailPattern.test(value));

      if (!emailPattern.test(value)) {
        setError('Favor de ingresar un correo electrónico válido.');
      }
    }
  };

  // Handle login
  const handleSubmit = async () => {
    if (error) return;

    if (!email) {
      setError(!email ? 'Este es un campo obligatorio.' : '');
      return;
    }

    try {
      const { data } = await axiosInstance.post(apiRoutes.resetPassword.forgot, { email });
      console.log(data);
      setRequestSent(true);
    } catch (error) {
      const { status, data } = error.response;
      setError('');
      if (status === 404) {
        setError('No se encontró ningún usuario con este correo electrónico. Por favor, introduzca la dirección de correo electrónico correcta.');
      } else {
        showToast(toastTypes.error, generalError);
      }
    }
  };

  return (
    <div>
      <div className={styles.registerMain}>
        <div className={styles.registerContainer}>
          <div className={styles.registerContainer2}>
            {/* Login Section */}
            <div className={styles.registerLeftMain}>
              <div className={styles.registerMainHeading}>
                <img src={RegIcn} alt="" className={styles.regIcn} />
                ¿Olvidé Contraseña?
              </div>
              {requestSent
                ? <div className="flex justify-center items-center mt-8 flex-col gap-3">
                  <span className="text-xl text-white text-center">
                    Te enviamos un enlace para restablecer tu contraseña por correo electrónico.
                    Revisa tu bandeja de entrada y haz clic en el enlace para restablecerla.
                  </span>
                </div>
                : <>
                  <div className={styles.registerInputMain}>
                    Correo electrónico:*
                    <input
                      type="text"
                      name="loginEmail"
                      className={styles.inputRegister}
                      value={email}
                      onChange={handleChange}
                    />
                    {error && <span className={styles.errorMessage}>{error}</span>}
                  </div>
                  <div className={styles.RegBtnIcnMain}>
                    <button
                      onClick={handleSubmit}
                      className={styles.RegBtnIcn2}
                    >
                      <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
                      <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
                      <span>ENVIAR</span>
                    </button>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ForgotPass
