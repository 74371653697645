import React, { useState } from "react";
import styles from "./SupRegister.module.css";
import RegIcn from "../../Images/RegIcn.png";
import SupRegStep from "../../Images/supRegStep.png";
import FileUpIcn from "../../Images/fileupIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import { useNavigate } from "react-router-dom";
import { apiRoutes, signupError, toastTypes } from "../../app.constants";
import axiosInstance from "../../services/axios";
import { showToast } from "../Shared/CustomToast";
import { useAuth } from "../../Hoc/AuthContext";
const SupRegister = () => {
  const navigate = useNavigate();
  const {setUser} = useAuth();

  // Form data state
  const [formDataNew, setFormData] = useState({
    businessType: "",
    giro: "",
    origin: "",
    itemsServices: "",
    socialReason: "",
    commercialName: "",
    rfc: "",
    files: { logo: null, headerImage: null },
    emailUser: "",
    emailUserLastName: "",
    legalRepresentative: { firstName: "", lastName: "" },
  });

  const [fileName, setFileName] = useState("Imagen formato jpeg formato web ancho: 96 px x alto: 96 px");
  const [fileName2, setFileName2] = useState("Imagen formato jpeg formato web ancho: 1920 px X alto: 705 px");
  const [errors, setErrors] = useState({});

  // Validate form function
  const validateForm = () => {
    const { businessType, giro, origin, itemsServices, socialReason, rfc, emailUser, emailUserLastName, files } = formDataNew;
    let formErrors = {};

    // Check if any required field is empty and update the errors state
    if (!businessType) formErrors.businessType = "Falta llenar la información pendiente de este campo.";
    if (!giro) formErrors.giro = "Falta llenar la información pendiente de este campo.";
    if (!origin) formErrors.origin = "Falta llenar la información pendiente de este campo.";
    if (!itemsServices) formErrors.itemsServices = "Falta llenar la información pendiente de este campo.";
    if (!socialReason) formErrors.socialReason = "Falta llenar la información pendiente de este campo.";
    if (!rfc) formErrors.rfc = "Falta llenar la información pendiente de este campo.";
    if (!emailUser) formErrors.emailUser = "Falta llenar la información pendiente de este campo.";
    if (!emailUserLastName) formErrors.emailUserLastName = "Falta llenar la información pendiente de este campo.";
    if (!files.logo) formErrors.logo = "Falta llenar la información pendiente de este campo.";
    if (!files.headerImage) formErrors.headerImage = "Falta llenar la información pendiente de este campo.";

    setErrors(formErrors);
    // Return true if no errors, otherwise false
    return Object.keys(formErrors).length === 0;
  };

  // Function to validate each input on blur
  const validateField = (e) => {
    const { name, value } = e.target;
    let formErrors = { ...errors };

    if (value.trim() === "") {
      formErrors[name] = "Falta llenar la información pendiente de este campo.";
    } else {
      delete formErrors[name]; // Remove error if the field is valid
    }
    if (name === "rfc") {
      validateRFC(value); // Custom validation for RFC
    } else {
      setErrors(formErrors);
    }
  };

  // Update form data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedData = { ...prev, [name]: value };
      return updatedData;
    });

    validateField(e)
  };

  const validateRFC = (value) => {
    // Regex to allow only letters and numbers
    const rfcRegex = /^[A-Za-z0-9]+$/;
    let error = '';
    // Check if RFC matches the allowed format and length
    if (!value) {
      error = 'Falta llenar la información pendiente de este campo.';
    } else if (!rfcRegex.test(value)) {
      error = "El RFC no cumple con el formato correcto. Favor de corregir.";
    } else if (value.length !== 12 && value.length !== 13) {
      error = 'El RFC debe tener entre 12 y 13 caracteres.'
    } else {
      error = ""
    }
    setErrors((prev) => ({
      ...prev,
      rfc: error,
    }));
  };

  const handleLegalRepresentativeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedData = {
        ...prev,
        legalRepresentative: { ...prev.legalRepresentative, [name]: value },
      };
      return updatedData;
    });
  };

  const handleFileChange = (event, fileKey) => {
    const file = event.target.files[0];
    const maxSize = fileKey === "logo" ? 15 * 1024 : 80 * 1024;

    if (file) {
      if (file.size > maxSize) {
        setErrors((prev) => ({
          ...prev,
          [fileKey]: `El archivo excede el tamaño máximo permitido (${maxSize / 1024} KB).`,
        }));
        return;
      }

      setErrors((prev) => ({
        ...prev,
        [fileKey]: "",
      }));

      setFormData((prev) => ({
        ...prev,
        files: { ...prev.files, [fileKey]: file },
      }));

      fileKey === "logo" ? setFileName(file.name) : setFileName2(file.name);
    }
  };


  const handleSubmit = async () => {
    if (!validateForm()) return; // Prevent submission if validation fails

    const formData = new FormData();
    const { businessType, giro, origin, itemsServices, socialReason, commercialName, rfc, emailUser, emailUserLastName, legalRepresentative, files } = formDataNew;

    formData.append("supplierOf", businessType);
    formData.append("companyLineOfBusiness", giro);
    formData.append("origin", origin);
    formData.append("serviceProvided", itemsServices);
    formData.append("companyName", socialReason);
    formData.append("tradeName", commercialName);
    formData.append("rfc", rfc);
    formData.append("headerImage", files.headerImage);
    formData.append("logo", files.logo);
    formData.append("fullName", emailUser);
    formData.append("surName", emailUserLastName);
    formData.append("legalFullName", legalRepresentative.firstName);
    formData.append("legalSurname", legalRepresentative.lastName);

    try {
      const response = await axiosInstance.patch(
        apiRoutes.auth.signupStep3,
        formData
      );
      setUser(response.data.user);
      navigate("/system/contactinfo");
    } catch (error) {
      showToast(toastTypes.error, signupError);
      console.log(error);
    }
  };


  return (
    <div>
      <div className={styles.supRegisterMain}>
        <div className={styles.supRegisterContainer}>
          <div className={styles.supRegisterContainer2}>
            <div className={styles.registerMainHeading}>
              <img src={RegIcn} alt="" className={styles.regIcn} />
              Registro de Proveedor
            </div>
            <div className={styles.supRegStepMain}>
              <img src={SupRegStep} alt="" className={styles.supRegstep} />
            </div>

            <div className={styles.radioMaindiv}>
              <div className={styles.radioText}>Proveedor de:*</div>
              <div className={styles.radioTextBtn}>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="businessType"
                    value="Bienes"
                    onChange={handleChange}
                  // onBlur={validateField} // Add onBlur here
                  />
                  <span className="checkmark"></span>
                </label>
                <div className={styles.radioText}>Bienes</div>
              </div>
              <div className={styles.radioTextBtn}>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="businessType"
                    value="Servicios"
                    onChange={handleChange}
                  // onBlur={validateField} // Add onBlur here
                  />
                  <span className="checkmark"></span>
                </label>
                <div className={styles.radioText}>Servicios</div>
              </div>
              <div className={styles.radioTextBtn}>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="businessType"
                    value="Ambos"
                    onChange={handleChange}
                  // onBlur={validateField} // Add onBlur here
                  />
                  <span className="checkmark"></span>
                </label>
                <div className={styles.radioText}>Ambos</div>
              </div>
            </div>
            {errors.businessType && <div className={styles.errorMessage}>{errors.businessType}</div>}

            <div className={styles.registerInputMain}>
              Giro de la empresa:*
              <input
                type="text"
                name="giro"
                className={styles.inputRegister}
                onChange={handleChange}
              // onBlur={validateField} // Add onBlur here
              />
              {errors.giro && <div className={styles.errorMessage}>{errors.giro}</div>}
            </div>
            <div className={styles.radioMaindiv2}>
              <div className={styles.radioText3}>Origen:*</div>
              <div className={styles.radioText2}>Local:</div>
              <div className={styles.radioTextBtnMain}>
                <div className={styles.radioTextBtn}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="origin"
                      value="Nuevo Léon"
                      onChange={handleChange}
                    // onBlur={validateField} // Add onBlur here
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className={styles.radioText}>Nuevo Léon</div>
                </div>
                <div className={styles.radioTextBtn}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="origin"
                      value="Nacional"
                      onChange={handleChange}
                    // onBlur={validateField} // Add onBlur here
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className={styles.radioText}>Nacional</div>
                </div>
              </div>
              <div className={styles.radioTextBtnMain}>
                <div className={styles.radioTextBtn}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="origin"
                      value="Coahuila"
                      onChange={handleChange}
                    // onBlur={validateField} // Add onBlur here
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className={styles.radioText}>Coahuila</div>
                </div>
                <div className={styles.radioTextBtn}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="origin"
                      value="Internacional"
                      onChange={handleChange}
                    // onBlur={validateField} // Add onBlur here
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className={styles.radioText}>Internacional</div>
                </div>
              </div>
            </div>
            {errors.origin && <div className={styles.errorMessage}>{errors.origin}</div>}

            <div className={styles.registerInputMain}>
              Artículo(s) o servicio(s) que provee:*
              <input
                type="text"
                name="itemsServices"
                className={styles.inputRegister}
                onChange={handleChange}
              // onBlur={validateField} // Add onBlur here
              />
              {errors.itemsServices && <div className={styles.errorMessage}>{errors.itemsServices}</div>}

            </div>
            <div className={styles.registerInputMain}>
              Razón social:*
              <input
                type="text"
                name="socialReason"
                className={styles.inputRegister}
                onChange={handleChange}
              // onBlur={validateField} // Add onBlur here
              />
              {errors.socialReason && <div className={styles.errorMessage}>{errors.socialReason}</div>}

            </div>
            <div className={styles.registerInputMain}>
              Nombre Comercial:
              <input
                type="text"
                name="commercialName"
                className={styles.inputRegister}
                onChange={handleChange}
              />
            </div>
            <div className={styles.registerInputMain}>
              RFC:*
              <input
                type="text"
                name="rfc"
                className={`${styles.inputRegister} ${errors.rfc ? styles.inputError : ""}`}
                onChange={handleChange}
              />
              {errors.rfc && <div className={styles.errorMessage}>{errors.rfc}</div>}
            </div>
            <div className={styles.regDocmainText2}>
              Logotipo o Avatar representativo del proveedor.Máximo tamaño jpeg 15 kb.*
            </div>
            {/* File Upload 1 */}
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload1"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, 'logo')}
                style={{ display: "none" }}
                accept=".jpeg,.jpg"
              />
              <span className={styles.fileName}>{fileName}</span>
              <label htmlFor="fileUpload1" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.logo && <div className={styles.errorMessage}>{errors.logo}</div>}

            <div className={styles.regDocmainText2}>
              Encabezado representativo del proveedor.Tamaño máximo de jpg 80 kb.*
            </div>
            {/* File Upload 2 */}
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload2"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, 'headerImage')}
                style={{ display: "none" }}
                accept=".jpeg,.jpg"
              />
              <span className={styles.fileName}>{fileName2}</span>
              <label htmlFor="fileUpload2" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            {errors.headerImage && <div className={styles.errorMessage}>{errors.headerImage}</div>}


            <div className={styles.registerInputMain}>
              Nombre del Contacto Directo:*
              <div className={styles.registerInputboth}>
                <input
                  type="text"
                  name="emailUser"
                  placeholder="Nombre(s) Completo(s):"
                  className={styles.inputRegister}
                  onChange={handleChange}
                // onBlur={validateField} // Add onBlur here
                />

                <input
                  type="text"
                  name="emailUserLastName"
                  placeholder="Apellido(s)"
                  className={styles.inputRegister}
                  onChange={handleChange}
                // onBlur={validateField} // Add onBlur here
                />

              </div>
              {(errors.emailUser || errors.emailUserLastName) && (
                <div className={styles.errorMessage}>
                  {errors.emailUser
                    ? errors.emailUser
                    : errors.emailUserLastName}
                </div>
              )}


            </div>
            <div className={styles.registerInputMain}>
              Representante Legal:
              <div className={styles.registerInputboth}>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Nombre(s) Completo(s):"
                  className={styles.inputRegister}
                  onChange={handleLegalRepresentativeChange}
                // onBlur={validateField} // Add onBlur here
                />

                <input
                  type="text"
                  name="lastName"
                  placeholder="Apellido(s)"
                  className={styles.inputRegister}
                  onChange={handleLegalRepresentativeChange}
                // onBlur={validateField} // Add onBlur here
                />

              </div>


            </div>
            <div className={styles.RegBtnIcnMain}>
              <button className={styles.RegBtnIcn2} onClick={handleSubmit}>
                <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
                <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
                <span>ENVIAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupRegister;
