import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import styles from "../ProjectRegister/ProjectRegister.module.css";
import { useAuth } from "../../Hoc/AuthContext";
import axiosInstance from "../../services/axios";
import { apiRoutes, environment, generalError, providerDocuments, providerDocumentTypes, requirementTypes, toastTypes, userRoles, userStatusMap } from "../../app.constants";
import { showToast } from "../Shared/CustomToast";
import Spinner from "../Shared/Spinner";
import { Assets } from '../../Assets'
import UploadDialog from "../Shared/UploadDialog";
import { userStatus } from "../../app.constants";
import { Checkbox } from "@mui/material";

const InternasContra = () => {
  const [searchTerm, setSearchTerm] = useState(""); // State for search input

  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [typeFilter, setTypeFilters] = useState('');
  const [originalUsers, setOriginalUsers] = useState([]);

  const successToastMessages = {
    [userStatus.pending]: 'Está pendiente que se defina un estatus para el usuario',
    [userStatus.approved]: 'El usuario ha sido autorizado',
    [userStatus.rejected]: 'El usuario ha sido rechazado'
  }

  const { isAuthenticated, user } = useAuth();
  const fileKeysMap = {};
  Object.entries(providerDocumentTypes).map(([key, val]) => {
    fileKeysMap[val] = key;
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await axiosInstance.get(apiRoutes.allUsers + `?role=provider`);
      const usersData = data?.users.map(user => {
        const { companyName, logo, headerImage, supplierOf } = user?.step3;
        const { physicalAddress } = user?.step4;
        return {
          _id: user._id,
          status: user.status,
          companyName,
          logo,
          bg: headerImage,
          supplierOf,
          address: `${physicalAddress?.municipality}, ${physicalAddress?.state}`,
          documents: user.filesStep2,
        }
      });

      setProviders(usersData);
      setOriginalUsers(usersData);
    } catch (error) {
      showToast(toastTypes.error, generalError);
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Filter the data based on the searchTerm
  const filteredData = providers.filter(item =>
    item?.companyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item?.address?.toLowerCase().includes(searchTerm.toLowerCase())
  ).filter(item => typeFilter ? item?.supplierOf === typeFilter : true);

  const handleOpenUploadDialog = (userId, document, documentType) => {
    setOpenUploadDialog(true);
    setSelectedDocument({ userId, document, documentType });
  }

  const handleFileUpload = async (fileData) => {
    const data = {
      file: fileData,
      providerId: selectedDocument.userId,
    }

    if (selectedDocument && selectedDocument.document) {
      data.documentId = selectedDocument.document._id;
    }
    if (selectedDocument && selectedDocument.documentType) {
      data.documentType = selectedDocument.documentType;
    }

    const formData = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      formData.append(key, val);
    })

    try {
      const response = await axiosInstance.post(
        apiRoutes.updatePaperwork,
        formData
      );
      await fetchData();
      setOpenUploadDialog(false);
      showToast(toastTypes.success, 'Documento guardado exitosamente.');
    } catch (error) {
      showToast(toastTypes.success, 'Error al cargar el documento. Inténtelo de nuevo más tarde.');
      console.error(error);
    }

  }

  const toggleButtonType = (userId, status) => {
    let newStatus = status;
    if (status === userStatus.pending) {
      newStatus = userStatus.approved;
    } else if (status === userStatus.approved) {
      newStatus = userStatus.rejected;
    } else if (status === userStatus.rejected) {
      newStatus = userStatus.pending;
    }
    setProviders(providers.map(pro => pro._id === userId ? { ...pro, status: newStatus } : pro));
  }

  const handleSelect = (e, providerId, doc) => {
    const updated = providers.map(provider => {
      if (provider._id === providerId) {
        const docsArr = provider.documents.map(newDoc => newDoc._id === doc._id ? { ...newDoc, isReviewed: e.target.checked } : newDoc);
        return {
          ...provider,
          documents: docsArr
        }
      }
      return provider;
    });
    setProviders(updated);
  }

  const handleSubmit = async (e, providerId) => {
    e.preventDefault();

    const provider = providers.find(pro => pro._id === providerId);
    const data = {
      providerId,
      documents: provider.documents,
      status: provider.status
    }

    try {
      const response = await axiosInstance.post(
        apiRoutes.validatePaperwork,
        data
      );

      let toastMsg = 'Se actualizaron los controles de revisión de documentación.';
      const user = originalUsers.find(user => user._id === providerId);
      if (user.status !== provider.status) {
        toastMsg = successToastMessages[provider.status]
      }

      showToast(toastTypes.success, toastMsg);
    } catch (error) {
      showToast(toastTypes.success, generalError);
      console.error(error);
    }
  }

  return (loading ? <Spinner /> :
    <>
      {openUploadDialog &&
        <UploadDialog
          placeholder={providerDocuments[fileKeysMap[selectedDocument.documentType]]}
          handleFileUpload={handleFileUpload}
          setOpenDialog={setOpenUploadDialog}
        />
      }
      <div className="mx-[200px]">
        <div className="mx-auto mt-32 mb-16 flex items-center justify-center gap-4 sm:gap-10">
          <img
            src={require("../../Assets/triangle.png")}
            className="h-9 w-9 sm:h-16 sm:w-16"
            alt=""
          />
          <div className="text-md font-bold leading-[30px] text-[#71A850] sm:text-2xl sm:leading-[47px] md:text-4xl">
            Validaciones Internas
          </div>
        </div>

        <div className="max-w-[1250px] mx-auto  flex flex-col gap-[20px]   md:flex-row justify-center md:justify-between items-center ">
          <div className="w-[287px] h-[57px]  flex justify-center items-center px-[20px] bg-[#D9D9D9] rounded-[12px]">
            <input
              type="text"
              name=""
              id=""
              placeholder="Buscar Proveedor..."
              className="outline-none border-none bg-transparent"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Update the search term
            />
            <img src={require("../../Images/Intra/search.png")} alt="" />
          </div>

          <div className="h-[57px] flex justify-center gap-4 items-center px-[20px] bg-[#D9D9D9] rounded-[12px]">
            <label for="supplierOf" className="text-[22px] text-[#828282] font-bold">
              Proveedor de:
            </label>
            <select
              id="supplierOf"
              name="supplierOf"
              className="bg-[#D9D9D9]"
              onChange={(e) => setTypeFilters(e.target.value)}
            >
              <option value="">Todos los proveedores</option>
              {requirementTypes.map(type => (<option key={type} value={type}>{type}</option>))}
            </select>
          </div>
        </div>

        <div className="min-h-screen mx-auto my-[40px] gap-[30px] w-full flex items-start flex-wrap  ">
          {!filteredData.length
            ? <p>No se encontró ningún proveedor con estas características.</p>
            : filteredData.map((item, index) => {
              return (
                <div key={item._id} className="flex flex-col flex-start border-[#BBBABA]  border-[1px] rounded-xl">
                  <div className="flex flex-col items-center relative w-[400px] max-w-[400px] rounded-t-xl ">
                    <div className="relative w-full">
                      <img src={`/${item?.bg}`} className="w-full h-[296px] rounded-t-xl object-cover" alt="" />
                      <div style={{
                        position: 'absolute',
                        inset: 0,
                        backgroundColor: 'black',
                        opacity: 0.3,
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px'
                      }}></div>
                    </div>
                    <div className="absolute flex flex-col h-full justify-between items-start pt-[30px] pl-[20px] pr-[30px] w-full max-w-[400px]">
                      <div className="w-full flex justify-between">
                        <div>
                          <div className="font-bold text-[20px] leading-[26px] fontClass text-white">
                            {item.companyName}
                          </div>
                          <div className="text-[15px]  mb-2 leading-[17px] fontClass text-white">
                            {item.address}
                          </div>
                          <span className={`px-2 py-1 rounded-full text-xs ${[userStatus.active, userStatus.approved].includes(item?.status) ? 'bg-green-100 text-green-800'
                            : [userStatus.blocked, userStatus.rejected].includes(item?.status) ? 'bg-red-100 text-red-800' : 'bg-gray-200'}`}>
                            {userStatusMap[item?.status]}
                          </span>
                        </div>
                        <div className="rounded-[12px]">
                          <img className="w-16 h-16" src={`/${item.logo}`} alt="logo" />
                        </div>
                      </div>

                      {/* <button onClick={() => hanldeApproveReject(item._id, userStatus.rejected)}>
                              <img className="w-44" src={Assets.RejectBtn} alt="" />
                            </button> */}

                      {isAuthenticated && user && [userRoles.admin, userRoles.contralor].includes(user.role) &&
                        <div className="absolute left-[20px] flex flex-col gap-2 bottom-[30px]">
                          {item.status === userStatus.rejected &&
                            <button onClick={() => toggleButtonType(item._id, userStatus.rejected)}>
                              <img className="w-44" src={Assets.RejectBtn} alt="" />
                            </button>
                          }
                          {item.status === userStatus.approved &&
                            <button onClick={() => toggleButtonType(item._id, userStatus.approved)}>
                              <img className="w-44" src={Assets.ApprovedBtn} alt="" />
                            </button>
                          }
                          {item.status === userStatus.pending &&
                            <button onClick={() => toggleButtonType(item._id, userStatus.pending)}>
                              <img className="w-44" src={Assets.PendingBtn} alt="" />
                            </button>
                          }
                        </div>
                      }

                      <Link to={`/system/financepage?id=${item._id}`}>
                        <div className="absolute right-[100px] bottom-[-30px] box">
                          <img src={require("../../Images/Intra/circle.png")} alt="" />
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="pt-[30px] pb-[50px] pl-[20px] pr-[10px]  mx-auto w-full max-w-[400px]">
                    {Object.entries(providerDocuments).map(([docKey, docType], index) => {
                      const doc = item?.documents.find(doc => doc.documentType === providerDocumentTypes[docKey]);
                      return doc ? (
                        <div
                          key={docKey}
                          className="flex mt-[10px] gap-[10px] justify-start items-center"
                        >
                          <Checkbox
                            size="small"
                            sx={{
                              '&.Mui-checked': {
                                color: '#025624',
                              },
                              p: 0
                            }}
                            id={`checkbox-${doc._id}`}
                            checked={doc.isReviewed}
                            onChange={(e) => handleSelect(e, item._id, doc)}
                          />
                          {/* <input
                            type="checkbox"
                            id={`checkbox-${doc._id}`}
                            checked={doc.isReviewed}
                            onChange={(e) => handleSelect(e, item._id, doc)}
                          /> */}
                          <label
                            htmlFor={`checkbox-${doc._id}`}
                            className="text-[#4A4F54] text-[13px] leading-[15px] max-w-[300px] fontClass"
                          >
                            {<Link to={`${environment.fileServerUrl}/${doc.path}`} target="_blank">
                              <span className="underline">{docType}</span>
                            </Link>}
                          </label>
                          <button
                            className="bg-[#025624] py-1 w-9 flex justify-center rounded"
                            onClick={() => handleOpenUploadDialog(item._id, doc, doc.documentType)}
                          >
                            <img className="w-2" src={Assets.AttachmentIcon} alt="" />
                          </button>
                        </div>
                      ) : (
                        <div
                          key={docKey}
                          className="flex mt-[10px] gap-[10px] justify-start items-center"
                        >
                          <Checkbox
                            size="small"
                            sx={{
                              '&.Mui-checked': {
                                color: '#025624',
                              },
                              p: 0
                            }}
                            id={`checkbox-${index}`}
                            disabled
                          />
                          <label
                            htmlFor={`checkbox-${index}`}
                            className="text-[#4A4F54] text-[13px] leading-[15px] max-w-[300px] fontClass"
                          >
                            <span>{docType}</span>
                          </label>
                          <button
                            className="bg-[#025624] py-1 w-9 flex justify-center rounded"
                            onClick={() => handleOpenUploadDialog(item._id, null, providerDocumentTypes[docKey])}
                          >
                            <img className="w-2" src={Assets.AttachmentIcon} alt="" />
                          </button>
                        </div>
                      );
                    })}
                  </div>

                  <div className="flex w-full justify-center pb-3">
                    <button className={styles.RegBtnIcn2} onClick={(e) => handleSubmit(e, item._id)}>
                      <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
                      <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
                      <span>GUARDAR</span>
                    </button>
                  </div>

                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default InternasContra;
